<template>
  <div>
    <b-table
      :data="dataList(type) ? dataList(type) : []"
      sticky-header
      striped
      hoverable
      :loading="!loaded"
      mobile-cards
      paginated
      :total="dataList(type) ? dataList(type).length : 0"
      :per-page="pagination.size"
      default-sort="name"
      default-sort-direction="asc"
      ref="table"
      height="auto"
    >
      <b-table-column
        field="name"
        label="Név"
        sortable
        searchable
        v-slot="props"
      >
        <label class="has-font-weight-500">{{ props.row.name }}</label>
      </b-table-column>

      <b-table-column cell-class="do-not-wrap" width="30%">
        <template #header>
          <b-button
            type="is-info"
            icon-left="plus-circle"
            @click="openEdit(null)"
            v-if="canCreate"
          >
            {{ $tf(FORECAST_BASE_DATA_TYPE.LABEL.NEW_BUTTON[type]) }}
          </b-button>
        </template>
        <template #default="props">
          <div class="is-flex has-gap-3 pr-5 is-justify-content-end">
            <clickable-icon
              icon="pencil"
              type="is-info"
              class="mr-2"
              @click="openEdit(props.row)"
              v-if="canEdit && props.row.modifiable"
              tooltip="Szerkesztés"
            ></clickable-icon>
            <clickable-icon
              icon="trash"
              type="is-danger"
              @click="deleteRow(props.row.id)"
              v-if="canDelete && props.row.modifiable"
              tooltip="Törlés"
            ></clickable-icon>
          </div>
        </template>
      </b-table-column>

      <template #empty>
        <section class="section">
          <div class="content has-text-grey has-text-centered">
            <p><b-icon icon="frown" size="is-large"></b-icon></p>
            <p>Nincsenek adatok</p>
          </div>
        </section>
      </template>
      <template #bottom-left>
        <div class="is-flex has-gap-3 is-align-items-center has-text-dark-blue">
          <span>Oldal mérete</span>
          <div id="size-selector">
            <b-select v-model="pagination.size" size="is-small">
              <option :value="10">10</option>
              <option :value="20">20</option>
              <option :value="50">50</option>
              <option :value="100">100</option>
            </b-select>
          </div>
          <span
            >Összesen: <strong>{{ dataList(type)?.length }}</strong></span
          >
        </div>
      </template>
    </b-table>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { openDeleteConfirm } from "@/utils/util";
import ClickableIcon from "../../module/icon/ClickableIcon.vue";
import {
  FORECAST_BASE_DATA_TYPE,
  FORECAST_YEAR_STATUS,
  PRIVILEGES,
} from "@/utils/const";
import ProjectBaseDataEditModal from "@/components/enterprise/base-data/ProjectBaseDataEditModal.vue";

export default {
  name: "ProjectBaseDataTab",
  components: { ClickableIcon },
  props: {
    type: {
      type: String,
      required: true,
    },
  },
  computed: {
    ...mapGetters({
      dataList: "project_base_data/getByType",
    }),
    canCreate() {
      return this.$store.getters["session/hasAnyAuthority"](
        PRIVILEGES.ENTERPRISE.PROJECT_BASE_DATA.CREATE,
        PRIVILEGES.ENTERPRISE.PROJECT_BASE_DATA.ADMIN
      );
    },
    canEdit() {
      return this.$store.getters["session/hasAnyAuthority"](
        PRIVILEGES.ENTERPRISE.PROJECT_BASE_DATA.EDIT,
        PRIVILEGES.ENTERPRISE.PROJECT_BASE_DATA.ADMIN
      );
    },
    canDelete() {
      return this.$store.getters["session/hasAnyAuthority"](
        PRIVILEGES.ENTERPRISE.PROJECT_BASE_DATA.DELETE,
        PRIVILEGES.ENTERPRISE.PROJECT_BASE_DATA.ADMIN
      );
    },
  },
  data() {
    return {
      loaded: false,
      FORECAST_YEAR_STATUS,
      FORECAST_BASE_DATA_TYPE,
      pagination: {
        page: 0,
        size: 10,
      },
      sort: {
        field: "name",
        order: "desc",
      },
      filter: {},
    };
  },
  methods: {
    openEdit(row) {
      this.$buefy.modal.open({
        parent: this,
        component: ProjectBaseDataEditModal,
        props: { baseData: row, type: this.type },
        hasModalCard: true,
        trapFocus: true,
        events: {
          save: () => this.load(true),
        },
      });
    },
    async deleteRow(id) {
      openDeleteConfirm(this.$buefy, async () => {
        await this.$store.dispatch("project_base_data/delete", id);
        await this.load(true);
      });
    },
    async load(force) {
      this.loaded = false;

      await this.$store.dispatch("project_base_data/fetchByType", {
        force,
        type: this.type,
      });

      this.loaded = true;
    },
  },
  async mounted() {
    await this.load();
  },
};
</script>
