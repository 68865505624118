<template>
  <td>
    <div>
      <p class="week-number">{{ week }}. hét</p>
      <p class="week-range">{{ dateRange }}</p>
    </div>
  </td>
</template>

<script>
import { defineComponent } from "vue";
import moment from "moment/moment";
import { localFetch } from "@/utils/util";

export default defineComponent({
  name: "WeekHeader",
  props: {
    week: {
      type: Number,
      required: true,
    },
    year: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      storedLocale: localFetch("stored_locale") ?? "hu",
    };
  },
  computed: {
    dateRange() {
      const formattedWeek = this.week.toLocaleString("en-US", {
        minimumIntegerDigits: 2,
        useGrouping: false,
      });
      const startOfWeek = moment(`${this.year}W${formattedWeek}`)
        .startOf("isoWeek")
        .locale(this.storedLocale);
      const endOfWeek = moment(`${this.year}W${formattedWeek}`)
        .endOf("isoWeek")
        .locale(this.storedLocale);
      return `${startOfWeek.format("MMM D")}. - ${endOfWeek.format("MMM D")}.`;
    },
  },
});
</script>

<style scoped lang="scss">
.week-number {
  font-weight: 700;
  margin-bottom: 0 !important;
}

p {
  margin: 0;
}

.week-range {
  font-size: 12px;
  color: var(--grey);
}
</style>
