<template>
  <div class="is-flex is-flex-direction-column is-justify-content-center">
    <apex-chart
      height="320px"
      :options="chartOptions"
      :series="chartSeries"
    ></apex-chart>
  </div>
</template>

<script>
import { isNullOrUndefined, percentify } from "@/utils/util";
import {
  PROJECT_PROGRESS_TYPE,
  PROJECT_PROGRESS_TYPE_TOKEN,
} from "@/utils/const";

export default {
  name: "ForecastReadinessChart",
  mixins: [],
  props: {
    projectDetails: {
      type: Object,
      required: true,
    },
  },
  computed: {
    chartSeries() {
      let arr = [];
      for (let key in this.projectDetails.projectReadinessMap) {
        if (this.projectDetails.projectReadinessMap.hasOwnProperty(key)) {
          if (
            key !== PROJECT_PROGRESS_TYPE.NONE &&
            key !== PROJECT_PROGRESS_TYPE.TIGS &&
            !isNullOrUndefined(this.projectDetails.projectReadinessMap[key])
          ) {
            arr.push(this.projectDetails.projectReadinessMap[key] * 100);
          }
        }
      }
      arr.push(this.projectDetails.spentPercentage * 100);
      arr.push(this.projectDetails.leadTimePercentage * 100);
      return [
        {
          data: arr,
        },
      ];
    },
    chartOptions() {
      return {
        chart: {
          type: "bar",
          toolbar: {
            show: false,
          },
        },
        tooltip: {
          onDatasetHover: {
            highlightDataSeries: false,
          },
          enabled: false,
        },
        plotOptions: {
          bar: {
            horizontal: true,
            borderRadius: 8,
            distributed: true,
            dataLabels: {
              position: "bottom",
            },
          },
        },
        grid: {
          show: true,
          strokeDashArray: 3,
          yaxis: {
            lines: {
              show: true,
            },
          },
          xaxis: {
            lines: {
              show: true,
            },
          },
        },
        yaxis: {
          labels: {
            show: true,
            trim: false,
            maxWidth: 240,
            style: {
              fontSize: "14px",
              fontWeight: 500,
              fontFamily: "Roboto, Arial, sans-serif",
              colors: "var(--text)",
            },
          },
        },
        xaxis: {
          categories: this.readinessLabels.concat(
            this.$tf(
              "projectPage.readinessChart.type.budgetSpent|Büdzsé költés"
            ),
            this.$tf("projectPage.readinessChart.type.time|Eltelt idő")
          ),
          axisBorder: {
            show: false,
          },
          labels: {
            formatter: (val) => {
              return percentify(val / 100);
            },
            show: true,
            trim: false,
            maxWidth: 240,
            style: {
              fontSize: "14px",
              fontWeight: 500,
              fontFamily: "Roboto, Arial, sans-serif",
              colors: "var(--text)",
            },
          },
          axisTicks: {
            show: true,
          },
        },
        colors: [
          "#ECC5AD",
          "#ECC5AD",
          "#ECC5AD",
          "#ECC5AD",
          "#4E75D9",
          "#4E75D9",
          "#CBC8C1",
        ],
        dataLabels: {
          formatter: (val) => {
            return percentify(val / 100);
          },
          offsetX: 20,
          style: {
            colors: [
              "#2F2E6B",
              "#2F2E6B",
              "#2F2E6B",
              "#2F2E6B",
              "#FFFFFF",
              "#FFFFFF",
              "#2F2E6B",
            ],
          },
        },
        legend: {
          show: false,
        },
      };
    },
    readinessLabels() {
      let arr = [];
      for (let key in this.projectDetails.projectReadinessMap) {
        if (this.projectDetails.projectReadinessMap.hasOwnProperty(key)) {
          if (
            key !== PROJECT_PROGRESS_TYPE.NONE &&
            key !== PROJECT_PROGRESS_TYPE.TIGS &&
            !isNullOrUndefined(this.projectDetails.projectReadinessMap[key])
          ) {
            arr.push(this.$tf(this.progressToken[key]) + ":");
          }
        }
      }
      return arr;
    },
  },
  data() {
    return {
      progressToken: PROJECT_PROGRESS_TYPE_TOKEN,
    };
  },
  methods: {
    randomColors(count) {
      let colors = [];
      for (let i = 2; i < count + 2; i++) {
        const hue = i * 137.508; // use golden angle approximation
        colors.push(`hsl(${hue}, 75%, ${Math.random() * 35 + 31}%)`);
      }
      return colors;
    },
  },
};
</script>

<style scoped lang="scss">
@import "~@/assets/scss/colors.scss";
td.is-danger {
  background-color: $red;
}
td.is-warning {
  background-color: $yellow;
}
td.is-success {
  background-color: $green;
}
.is-vertical-align-middle {
  vertical-align: middle !important;
}
</style>
