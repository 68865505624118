<template>
  <section v-if="loaded && !isPageLoading">
    <b-modal
      v-model="commitmentModalOpen"
      aria-modal
      aria-role="dialog"
      has-modal-card
      v-on:cancel="commitmentEdit = null"
    >
      <div class="card p-4">
        <commitment-modal
          :commitment-details-sum="commitmentDaysSum"
          :edited-commitment="commitmentEdit"
          :employees="employees"
          :project-commitment="projectData.commitment"
          :roles="
            indicators.filter((i) => i.type === USER_INDICATOR_TYPE_ENUM.ROLE)
          "
          :seniorities="
            indicators.filter(
              (i) => i.type === USER_INDICATOR_TYPE_ENUM.SENIORITY
            )
          "
          @add-commitment="(c) => addToCommitmentList(c)"
        />
      </div>
    </b-modal>

    <!--    <div class="mb-6"> TODO: fix this completely-->
    <!--      <div class="uppercase-subtitle mt-5 mb-0">-->
    <!--        {{-->
    <!--          $tf(-->
    <!--            "projectResourceView.commitments.title|Erőforrás költések / Vállalások"-->
    <!--          )-->
    <!--        }}-->
    <!--      </div>-->

    <!--      <div class="is-flex is-flex-wrap-wrap has-gap-4 mt-0 mt-3 mb-4">-->
    <!--        &lt;!&ndash; Vállalások összesítve &ndash;&gt;-->
    <!--        <div class="pt-1 pr-1 pl-1 has-text-centered w-16 commitment-box">-->
    <!--          <div class="has-text-dark-blue has-font-weight-400">-->
    <!--            {{ totalAllocatedMwd }} MWD / {{ totalCommitmentSum }} MWD-->
    <!--          </div>-->
    <!--          <progress-->
    <!--            class="progress is-small commitment-progress"-->
    <!--            :value="totalAllocatedMwd"-->
    <!--            :max="totalCommitmentSum"-->
    <!--          ></progress>-->
    <!--          <div class="has-text-weight-medium has-text-black mt-2">-->
    <!--            {{ $tf("projectResourceView.commitments.total|Összesen") }}-->
    <!--          </div>-->
    <!--        </div>-->

    <!--        &lt;!&ndash; Vállalások munkakörönként &ndash;&gt;-->
    <!--        <div-->
    <!--          class="pt-1 pr-1 pl-1 has-text-centered w-16 commitment-box"-->
    <!--          v-for="(commitment, index) in commitmentDetails"-->
    <!--          :key="index"-->
    <!--        >-->
    <!--          <div class="has-text-dark-blue has-font-weight-400">-->
    <!--            {{ allocatedMWDByRole(commitment.roleId) }} MWD /-->
    <!--            {{ commitment.commitmentSum }} MWD-->
    <!--          </div>-->
    <!--          <progress-->
    <!--            class="progress is-small commitment-progress"-->
    <!--            :value="allocatedMWDByRole(commitment.roleId)"-->
    <!--            :max="commitment.commitmentSum"-->
    <!--          ></progress>-->
    <!--          <div class="has-text-weight-medium has-text-black mt-2">-->
    <!--            {{ commitment.roleName }}-->
    <!--          </div>-->
    <!--        </div>-->
    <!--      </div>-->
    <!--    </div>-->

    <div>
      <div class="is-flex">
        <div class="is-flex has-gap-5">
          <h3 class="heading">
            {{ $tf("projectResourceView.resource.title|Erőforrás allokáció") }}
          </h3>
          <div class="is-flex is-align-items-center mb-5">
            <b-checkbox v-model="groupByRole" class="mr-0">
              <span class="has-text-weight-light"
                >{{
                  $tf(
                    "projectResourceView.resource.checkbox.groupByRole|Csoportosítás munkakör szerint"
                  )
                }}
              </span>
            </b-checkbox>
          </div>
        </div>

        <div class="ml-4">
          <b-icon
            class="cursor-pointer mr-2"
            icon="chevron-left"
            @click="prevWeek"
          />
          <b-icon class="cursor-pointer mr-2" icon="undo" @click="resetWeek" />
          <b-icon
            class="cursor-pointer"
            icon="chevron-right"
            @click="nextWeek"
          />
        </div>
        <div class="ml-auto is-flex is-align-items-center">
          <span
            :class="
              showAllocatedTimeInHours ? 'mr-2' : 'mr-2 has-font-weight-600'
            "
          >
            {{ $tf("projectResourceView.switch.label.workday| Munkanap") }}
          </span>
          <b-switch
            v-model="showAllocatedTimeInHours"
            passive-type="is-info"
            type="is-info"
          />
          <span :class="showAllocatedTimeInHours ? 'has-font-weight-600' : ''">
            {{ $tf("projectResourceView.switch.label.workhours| Munkaóra") }}
          </span>
        </div>
      </div>

      <template v-if="groupByRole">
        <div v-for="(commitment, index) in commitmentDetails" :key="index">
          <b-collapse :model-value="false" animation="slide">
            <template #trigger="props">
              <div class="card-header is-grey mb-2" role="button">
                <div class="is-flex is-align-items-center pl-2">
                  <h3 class="heading mb-2 mt-2 ml-2 has-font-weight-600">
                    {{ commitment.roleName }}
                  </h3>
                </div>
                <div class="ml-auto mt-2 mb-2 mr-2">
                  <a>
                    <b-icon
                      :icon="props.open ? 'chevron-up' : 'chevron-down'"
                    />
                  </a>
                </div>
              </div>
            </template>

            <div class="is-flex is-flex-direction-column">
              <!--       Weeks Header -->
              <div class="row is-flex is-flex-direction-row">
                <div class="cell is-flex-2 is-flex is-align-items-flex-end">
                  <span
                    class="has-text-custom-dark-blue uppercase is-size-7 pt-5 ml-6 mb-3"
                  >
                    {{
                      $tf("projectResourceView.allocationRow.header.name| Név")
                    }}
                  </span>
                </div>
                <div
                  v-for="(weekData, index) of weeks"
                  :key="index"
                  class="cell is-flex-1"
                >
                  <div class="pt-1 pb-1">
                    <p
                      class="has-text-weight-bold is-size-5 has-text-centered mb-0"
                    >
                      {{ weekData.week }}.
                      {{
                        $tf(
                          "projectResourceView.allocationRow.header.week| hét"
                        )
                      }}
                    </p>
                    <p class="has-text-grey has-text-centered">
                      {{ weekData.startDate }} - {{ weekData.endDate }}
                    </p>
                  </div>
                </div>
              </div>

              <!--          Employees-->
              <ResourceAllocationRow
                v-for="association of filterByRole(
                  associations,
                  commitment.roleId
                )"
                :key="association.employeeId"
                :allocations="association.weekData"
                :employee="employee(association.employeeId)"
                :project-id="projectData.id"
                :show-allocated-time-in-hours="showAllocatedTimeInHours"
                :weeks="weeks"
                @cloneAllocation="saveAssociation"
                @modifyAllocation="saveAssociationDebounce"
              />
            </div>
          </b-collapse>
        </div>
      </template>
      <template v-else>
        <div class="is-flex is-flex-direction-column">
          <!--       Weeks Header -->
          <div class="row is-flex is-flex-direction-row">
            <div class="cell is-flex-2 is-flex is-align-items-flex-end">
              <span
                class="has-text-custom-dark-blue uppercase is-size-7 pt-5 ml-6 mb-3"
              >
                {{ $tf("projectResourceView.allocationRow.header.name| Név") }}
              </span>
            </div>
            <div
              v-for="(weekData, index) of weeks"
              :key="index"
              class="cell is-flex-1"
            >
              <div class="pt-1 pb-1">
                <p
                  class="has-text-weight-bold is-size-5 has-text-centered mb-0"
                >
                  {{ weekData.week }}.
                  {{
                    $tf("projectResourceView.allocationRow.header.week| hét")
                  }}
                </p>
                <p class="has-text-grey has-text-centered">
                  {{ weekData.startDate }} - {{ weekData.endDate }}
                </p>
              </div>
            </div>
          </div>

          <!--          Employees-->
          <ResourceAllocationRow
            v-for="association of associations"
            :key="association.employeeId"
            :allocations="association.weekData"
            :employee="employee(association.employeeId)"
            :project-id="projectData.id"
            :show-allocated-time-in-hours="showAllocatedTimeInHours"
            :weeks="weeks"
            @cloneAllocation="cloneAllocation"
            @modifyAllocation="saveAssociationDebounce"
          />
        </div>
      </template>
      <div class="mt-4">
        <b-field grouped>
          <b-button
            icon-left="plus"
            type="is-primary"
            @click="allocationModalOpen = true"
          >
            {{
              $tf(
                "projectResourceView.button.newEmployee|Új projekttag hozzáadása"
              )
            }}
          </b-button>
          <ImportExcel
            :label="$tf('projectResourceView.import|Importálás...')"
          />
        </b-field>
      </div>

      <b-modal v-model="allocationModalOpen" has-modal-card>
        <EmployeeSelect
          :employees="employeesToSelect"
          :lead-teams="leadTeams"
          @addEmployee="handleAddEmployee"
        />
      </b-modal>
    </div>

    <b-collapse
      v-model="breakdownOpen"
      animation="slide"
      class="card mt-5 mb-5"
    >
      <template #trigger="">
        <div :aria-expanded="breakdownOpen" class="card-header" role="button">
          <p class="card-header-title m-0">
            {{ $tf("projectForm.commitment.dropdown|Vállalás lebontása") }}
          </p>
        </div>
      </template>
      <div class="card-content">
        <div class="mb-4 is-flex is-justify-content-end">
          <b-button
            class="my-auto"
            icon-right="plus-circle"
            type="is-info"
            @click="commitmentModalOpen = true"
            >{{ $tf("projectForm.commitment.droprown|Lebontás beállítása") }}
          </b-button>
        </div>
        <div v-if="commitmentList?.length > 0">
          <b-table :data="commitmentList">
            <b-table-column
              v-slot="props"
              :label="$tf('projectForm.commitment.table.role|Szerepkör')"
              field="competence"
            >
              <indicator-tag :indicator-id="props.row.roleId" />
            </b-table-column>
            <b-table-column
              v-slot="props"
              field="competence"
              label="Senioritás"
            >
              <indicator-tag :indicator-id="props.row.seniorityId" />
            </b-table-column>
            <b-table-column
              v-slot="props"
              field="workDays"
              label="Vállalt napok"
            >
              {{ workdayify(props.row.workDays) }}
            </b-table-column>
            <b-table-column
              v-slot="props"
              :label="$tf('projectpage.override.table.actions|Műveletek')"
              centered
              field="actions"
              width="80"
            >
              <clickable-icon
                :tooltip="
                  $tf('projectpage.override.table.actions.edit|Szerkesztés')
                "
                icon="edit"
                type="is-info"
                @click="editCommitment(props.row)"
              />
              <clickable-icon
                :tooltip="
                  $tf('projectpage.tigList.table.actions.delete|Törlés')
                "
                icon="trash"
                type="is-danger"
                @click="deleteCommitment(props.row)"
              />
            </b-table-column>
          </b-table>
          <div class="mt-4">
            <b-button
              class="is-block ml-auto"
              icon-right="save"
              type="is-info"
              @click="updateCommitment"
              >Lebontás mentése
            </b-button>
          </div>
        </div>
        <div v-else class="mb-6">
          <div class="content has-text-grey has-text-centered">
            <p class="mb-0">
              <b-icon icon="frown"></b-icon>
            </p>
            <p>
              {{
                $tf(
                  "projectForm.commitment.table.empty|A projekten még nincs vállalás."
                )
              }}
            </p>
          </div>
        </div>
      </div>
    </b-collapse>
  </section>
  <div v-else>
    <b-loading v-model="isPageLoading" :is-full-page="true" />
  </div>
</template>
<script>
import { defineComponent } from "vue";
import { mapGetters } from "vuex";
import LoadingMixin from "@/mixins/LoadingMixin";
import {
  addDay,
  convertHoursToMWD,
  generateNext6Weeks,
  getWeekNumber,
  getWeeksBetween,
  isOlderThanPreviousWeek,
  subtractDay,
  workdayify,
} from "@/utils/util";
import { useDebounceFn } from "@vueuse/core";
import EmployeeSelect from "@/components/ralloc/EmployeeSelect.vue";
import ResourceAllocationRow from "@/components/ralloc/ResourceAllocationRow.vue";
import {
  PROJECT_TYPES,
  RALLOC_PRIORITY,
  USER_INDICATOR_TYPE_ENUM,
} from "@/utils/const";
import ClickableIcon from "@/components/module/icon/ClickableIcon.vue";
import CommitmentModal from "@/components/enterprise/project/form/CommitmentModal.vue";
import IndicatorTag from "@/components/module/info/IndicatorTag.vue";
import ImportExcel from "@/components/ImportExcel.vue";

export default defineComponent({
  name: "ProjectResourceView",
  components: {
    ImportExcel,
    IndicatorTag,
    CommitmentModal,
    ClickableIcon,
    ResourceAllocationRow,
    EmployeeSelect,
  },
  mixins: [LoadingMixin],
  async mounted() {
    this.doStartLoading();
    this.generateWeeks();
    await this.$store.dispatch(
      "commitment/getCommitments",
      this.projectData.id
    );
    await this.setupCommitmentDetails();
    //await this.$store.dispatch("employee/fetch");
    await this.$store.dispatch("census_team/fetchLeadTeams");
    await this.fetchAssociations();
    this.commitmentList = this.commitments?.commitments ?? [];
    this.doFinishLoading();
    this.loaded = true;
  },
  props: {
    projectIdentifier: {
      type: String,
      required: true,
    },
    projectType: {
      type: String,
      required: true,
    },
  },
  created() {
    this.saveAssociationDebounced = useDebounceFn(
      async (...args) => await this.saveAssociation(...args),
      500
    );
  },
  data() {
    return {
      USER_INDICATOR_TYPE_ENUM,
      loaded: false,
      startWeekDate: new Date(),
      showAllocatedTimeInHours: false,
      weeks: [],
      commitmentDetails: [],
      allocationModalOpen: false,
      groupByRole: false,
      commitmentModalOpen: false,
      commitmentEdit: null,
      commitmentList: [],
      breakdownOpen: false,
    };
  },
  computed: {
    commitmentDaysSum() {
      return (
        this.commitmentList
          ?.map((c) => c.workDays)
          .reduce((a, b) => parseInt(a) + parseInt(b), 0) ?? 0
      );
    },
    ...mapGetters({
      projectDataGetter: "enterprise_projects/projectData",
      supportProjectDataGetter: "support_projects/projectDetails",
      outsourceProjectDataGetter: "outsource_projects/projectDetails",
      indicators: "user_indicator/indicators",
      commitments: "commitment/commitments",
      employees: "employee/loggingEmployees",
      leadTeams: "census_team/leadTeams",
      associations: "association/associations",
    }),
    employeesToSelect() {
      return this.employees.filter(
        (emp) => !this.associations.some((asc) => asc.employeeId === emp.id)
      );
    },
    projectData() {
      if (this.projectType === PROJECT_TYPES.BASIC) {
        const data = this.projectDataGetter?.(this.projectIdentifier);
        return data || {};
      } else if (this.projectType === PROJECT_TYPES.SUPPORT) {
        const data = this.supportProjectDataGetter?.(this.projectIdentifier);
        return data || {};
      } else if (this.projectType === PROJECT_TYPES.OUTSOURCE) {
        const data = this.outsourceProjectDataGetter?.(this.projectIdentifier);
        return data || {};
      }

      return {};
    },
    totalCommitmentSum() {
      return this.commitmentDetails.reduce(
        (sum, role) => sum + role.commitmentSum,
        0
      );
    },
    totalAllocatedMwd() {
      return this.convertHoursToMWD(
        this.associations
          .flatMap((asc) => asc.weekData)
          .reduce((sum, weekData) => sum + (weekData.allocatedHours || 0), 0)
      );
    },
  },
  watch: {
    startWeekDate() {
      this.generateWeeks();
    },
  },
  methods: {
    workdayify,
    getWeekNumber,
    convertHoursToMWD,
    addDay,
    subtractDay,
    isOlderThanPreviousWeek,
    async saveAssociationDebounce(request) {
      await this.saveAssociationDebounced(request);
    },
    async cloneAllocation(args) {
      if (
        args.cloningWeek.year < args.year ||
        (args.cloningWeek.year === args.year &&
          args.cloningWeek.week > args.week)
      ) {
        this.$buefy.toast.open({
          duration: 3000,
          message: this.$tf(
            "projectResourceView.saveAssociation.toast.pastAllocationCloneForbidden|Allokáció másolása a múltba nem lehetséges!"
          ),
          position: "is-bottom",
          type: "is-danger",
        });
        return;
      }
      const weeks = getWeeksBetween(args.cloningWeek, args);
      let requestArray = [];
      for (let weekData of weeks) {
        const request = {
          ...args,
          projectId: this.projectData.id,
          type: this.projectType,
          year: weekData.year,
          week: weekData.week,
        };
        requestArray.push(request);
      }
      await this.$store.dispatch("association/createAssociation", requestArray);
      await this.fetchAssociations();
    },
    async saveAssociation(args) {
      const request = {
        ...args,
        projectId: this.projectData.id,
        type: this.projectType,
      };

      if (this.isOlderThanPreviousWeek(request.year, request.week)) {
        this.$buefy.toast.open({
          duration: 3000,
          message: this.$tf(
            "projectResourceView.saveAssociation.toast.pastAllocationForbidden|Egy hétnél korábbi allokáció nem megengedett!"
          ),
          position: "is-bottom",
          type: "is-danger",
        });
        return;
      }

      await this.$store.dispatch("association/createAssociation", [request]);
      await this.fetchAssociations();
    },
    async fetchAssociations() {
      await this.$store.dispatch("association/getAssociationsForProject", {
        projectId: this.projectData?.id,
      });
    },
    async setupCommitmentDetails() {
      if (!this.commitments || this.commitments.length === 0) {
        this.commitmentDetails = [];
        return;
      }

      const roleMap = new Map();

      this.commitments?.commitments.forEach((commitment) => {
        const getIndicatorById = (id) =>
          this.indicators.find((indicator) => indicator.id === id);

        const roleId = commitment.roleId;
        const roleName = getIndicatorById(roleId)?.name;

        if (!roleMap.has(roleId)) {
          roleMap.set(roleId, {
            roleName,
            roleId,
            commitmentSum: 0,
            commitments: [],
          });
        }

        const role = roleMap.get(roleId);

        role.commitments.push({
          seniorityId: commitment.seniorityId,
          workDays: commitment.workDays,
          seniority: getIndicatorById(commitment.seniorityId),
        });

        role.commitmentSum += commitment.workDays;
      });

      this.commitmentDetails = Array.from(roleMap.values());
    },
    allocatedMWDByRole(roleId) {
      const employeeRoles = new Map(
        this.employees.map((emp) => [emp.id, emp.roleId])
      );

      return this.convertHoursToMWD(
        this.associations
          .filter((asc) => employeeRoles.get(asc.employeeId) === roleId)
          .flatMap((asc) => asc.weekData)
          .reduce((sum, weekData) => sum + (weekData.allocatedHours || 0), 0)
      );
    },
    async handleAddEmployee(employeeId) {
      const today = new Date();
      await this.saveAssociation({
        employeeId: employeeId,
        year: today.getFullYear(),
        week: getWeekNumber(today),
        hours: 8,
        priority: RALLOC_PRIORITY.MEDIUM.value,
      });

      this.allocationModalOpen = false;
    },
    nextWeek() {
      this.startWeekDate = new Date(this.addDay(this.startWeekDate, 7));
    },
    prevWeek() {
      this.startWeekDate = new Date(this.subtractDay(this.startWeekDate, 7));
    },
    resetWeek() {
      this.startWeekDate = new Date();
    },
    generateWeeks() {
      this.weeks = generateNext6Weeks(this.startWeekDate);
    },
    employee(employeeId) {
      return this.employees.find((emp) => emp.id === employeeId);
    },
    filterByRole(associations, roleId) {
      const employeeMap = this.employees.reduce((map, emp) => {
        map[emp.id] = emp;
        return map;
      }, {});

      return associations.filter((asc) => {
        const employee = employeeMap[asc.employeeId];
        return employee && employee.roleId === roleId;
      });
    },
    addToCommitmentList(c) {
      let found = false;
      this.commitmentList = this.commitmentList.map((com) => {
        if (com.roleId === c.role.id && com.seniorityId === c.seniority?.id) {
          com.workDays = c.workDays;
          found = true;
        }
        return com;
      });
      if (!found) {
        this.commitmentList.push({
          roleId: c.role.id,
          seniorityId: c.seniority?.id,
          workDays: c.workDays,
        });
      }
      this.commitmentModalOpen = false;
    },
    editCommitment(c) {
      this.$buefy.dialog.prompt({
        message: this.$tf(
          "projectResourceView.commitment.editModal.message|Vállalt napok:"
        ),
        inputAttrs: {
          type: "number",
          value: c.workDays,
        },
        trapFocus: true,
        onConfirm: (value) => {
          this.commitmentList = this.commitmentList.map((com) => {
            if (com.roleId === c.roleId && com.seniorityId === c.seniorityId) {
              com.workDays = value;
            }
            return com;
          });
        },
      });
    },
    deleteCommitment(c) {
      this.$buefy.dialog.confirm({
        message: this.$tf(
          "projectResourceView.commitment.deleteModal.message|Biztos vagy benne, hogy törölni szeretnéd a vállalást?"
        ),
        confirmText: this.$tf(
          "projectResourceView.commitment.deleteModal.confirm|Törlés"
        ),
        cancelText: this.$tf(
          "projectResourceView.commitment.deleteModal.cancel|Mégse"
        ),
        type: "is-danger",
        hasIcon: true,
        onConfirm: async () => {
          this.commitmentList = this.commitmentList.filter((com) => com !== c);
        },
      });
    },
    async updateCommitment() {
      await this.$store.dispatch("commitment/updateCommitment", {
        projectId: this.projectData.id,
        commitments: this.commitmentList.map((c) => {
          return {
            roleId: c.roleId,
            seniorityId: c.seniorityId,
            workDays: c.workDays,
          };
        }),
      });
      await this.$store.dispatch(
        "commitment/getCommitments",
        this.projectData.id
      );
    },
  },
});
</script>

<style lang="scss" scoped>
@import "~@/assets/scss/colors";

.commitment-box {
  border: 1px solid black;
  border-radius: 5px;
}

.commitment-progress {
  height: 0.6rem !important;
  background-color: #eee;

  &::-webkit-progress-bar {
    background-color: #eee;
  }

  &::-webkit-progress-value {
    background-color: var(--custom-dark-blue);
  }

  &::-moz-progress-bar {
    background-color: var(--custom-dark-blue);
  }
}

.is-grey {
  background: #e8e8e8;
}
</style>
