<template>
  <section v-if="!loading">
    <p class="title">Dolgozók utilizációja</p>
    <div class="columns">
      <div class="column is-one-third">
        <b-field
          :label="$tf('resource.utilization.filter.employee|Szűrő')"
          expanded
        >
          <multiselect-dropdown
            v-model="filters.selectedEmployees"
            :items="employees"
            append-to-body
            identifier-field="id"
            name-field="name"
            use-tf
            expanded
            searchable
            clearable
            scrollable
            max-height="300"
            icon="circle-xmark"
            @iconRightClick="filters.selectedEmployees = []"
          >
            <template #empty>{{
              $tf("resource.utilization.filter.empty|Nincs találat")
            }}</template>
          </multiselect-dropdown>
        </b-field>
      </div>
      <div class="column is-one-third">
        <b-field
          :label="$tf('resource.utilization.filter.team|Csapat kiválasztása')"
        >
          <f-autocomplete
            :data="teams"
            id-field="identifier"
            v-model="filters.team"
            clearable
          />
        </b-field>
      </div>
      <div class="column is-one-third has-text-left">
        Hetek
        <p class="mt-3">
          <b-icon
            icon="chevron-left"
            class="icon-xl cursor-pointer mr-2"
            @click="minusWeek"
          />
          <b-icon
            icon="undo"
            class="icon-xl cursor-pointer mr-2"
            @click="resetWeek"
          />
          <b-icon
            icon="chevron-right"
            class="icon-xl cursor-pointer"
            @click="plusWeek"
          />
        </p>
      </div>
      <!--      <div class="column">-->
      <!--        <b-field :label="$tf('resource.utilization.filter.roles|Munkakör')">-->
      <!--          <multiselect-dropdown-->
      <!--            v-model="filters.roles"-->
      <!--            :items="roles"-->
      <!--            append-to-body-->
      <!--            has-select-all-option-->
      <!--            identifier-field="id"-->
      <!--            name-field="name"-->
      <!--            use-tf-->
      <!--          />-->
      <!--        </b-field>-->
      <!--      </div>-->
      <!--      <div class="column">-->
      <!--        <b-field-->
      <!--          :label="$tf('resource.utilization.filter.seniority|Szenioritás')"-->
      <!--        >-->
      <!--          <multiselect-dropdown-->
      <!--            v-model="filters.seniorities"-->
      <!--            :items="seniorities"-->
      <!--            append-to-body-->
      <!--            has-select-all-option-->
      <!--            identifier-field="name"-->
      <!--            name-field="name"-->
      <!--            use-tf-->
      <!--          />-->
      <!--        </b-field>-->
      <!--      </div>-->
    </div>
    <div id="utilization-table-container" ref="tableWrapper">
      <b-table
        :data="extendedAssociations"
        class="table-height-hack"
        detailed
        v-if="extendedAssociations?.length > 0"
      >
        <b-table-column
          field="employee.name"
          :label="$tf('resource.utilization.table.employee|Név')"
          v-slot="prop"
        >
          <UtilizationEmployeeBrief :data="prop.row"></UtilizationEmployeeBrief>
        </b-table-column>
        <b-table-column
          field="employee.roles"
          :label="$tf('resource.utilization.table.roles|Munkakör/Szenioritás')"
          v-slot="prop"
        >
          <indicator-tag :indicator-id="prop.row.employee.roleId" />
          <indicator-tag
            class="ml-2"
            :indicator-id="prop.row.employee.seniorityId"
          />
        </b-table-column>
        <b-table-column
          v-for="(week, i) in displayedWeeks"
          field="weekData.week"
          :key="i"
        >
          <template #header>
            <div>
              {{
                $tf(
                  "resource.utilization.table.weekUtilization|{weekNumber}. hét",
                  {
                    weekNumber: week.week,
                  }
                )
              }}
            </div>
            <div>
              {{ dateRange(week.year, week.week) }}
            </div>
          </template>
          <template v-slot="props">
            <VerticalProgressBar
              :value="resolveAllocatedHours(props, week.week)"
              :max-value="resolveExpectedHours(props, week.week)"
              :info="(value, _) => `${convertHoursToMWD(value)} MWD`"
            ></VerticalProgressBar>
          </template>
        </b-table-column>
        <template #detail="prop">
          <EmployeeUtilizationDetails
            :data="prop.row"
            :max-width="tableWidth"
          ></EmployeeUtilizationDetails>
        </template>
      </b-table>
      <div v-else>
        {{
          $tf(
            "resource.utilization.selectionRequired|A megjelenítéshez válassz legalább egy kollégát!"
          )
        }}
      </div>
    </div>
  </section>
</template>
<script>
import { defineComponent } from "vue";
import { mapGetters } from "vuex";
import MultiselectDropdown from "@/components/MultiselectDropdown.vue";
import debounce from "lodash.debounce";
import VerticalProgressBar from "@/components/VerticalProgressBar.vue";
import UtilizationEmployeeBrief from "@/components/ralloc/UtilizationEmployeeBrief.vue";
import EmployeeUtilizationDetails from "@/components/ralloc/EmployeeUtilizationDetails.vue";
import {
  convertHoursToMWD,
  formatDate,
  addDay,
  subtractDay,
  getWeekData,
  localFetch,
} from "@/utils/util";
import IndicatorTag from "@/components/module/info/IndicatorTag.vue";
import moment from "moment";
import FAutocomplete from "@/components/module/input/FAutocomplete.vue";

export default defineComponent({
  name: "UtilizationEmployees",
  components: {
    FAutocomplete,
    IndicatorTag,
    EmployeeUtilizationDetails,
    UtilizationEmployeeBrief,
    VerticalProgressBar,
    MultiselectDropdown,
  },
  created() {
    window.addEventListener("resize", this.resizeHandler);
  },
  async mounted() {
    await this.fetchData();
    this.loading = false;
    this.$nextTick(() => {
      this.tableWidth = this.$refs.tableWrapper?.clientWidth;
    });
  },
  unmounted() {
    window.removeEventListener("resize", this.resizeHandler);
  },
  data() {
    return {
      loading: true,
      filters: {
        team: undefined,
        selectedEmployees: [],
        roles: [],
        seniorities: [],
      },
      tableWidth: 0,
      fromDate: new Date(
        new Date().setDate(
          new Date().getDate() -
            (new Date().getDay() === 0 ? 6 : new Date().getDay() + 6)
        )
      ),
      toDate: new Date(
        new Date().setDate(new Date().getDate() + (19 - new Date().getDay()))
      ),
      storedLocale: localFetch("stored_locale") ?? "hu",
    };
  },
  computed: {
    roles() {
      return this.indicators.filter((indicator) => indicator.type === "ROLE");
    },
    seniorities() {
      return this.indicators.filter(
        (indicator) => indicator.type === "SENIORITY"
      );
    },
    extendedAssociations() {
      return this.associations.map((association) => ({
        employee: this.getEmployee(association.employeeId),
        expectedHoursSum: this.getExpectedHoursSum(association),
        allocatedHoursSum: this.getAllocatedHoursSum(association),
        totalLoggedHours:
          this.worklogs(association.employeeId)?.totalSeconds / 3600,
        ...association,
      }));
    },
    displayedWeeks() {
      if (!this.associations || this.associations.length === 0) {
        return [];
      }
      const weekData = this.associations[0].weekData;
      return weekData
        .sort((a, b) => (a.year === b.year ? a.week - b.week : a.year - b.year))
        .slice(0, 7);
    },
    ...mapGetters({
      teams: "census_team/accessibleTeams",
      employees: "employee/loggingEmployees",
      associations: "association/associationsByUsers",
      indicators: "user_indicator/indicators",
      worklogs: "worklog/worklogOfWeekForUser",
    }),
  },
  watch: {
    "filters.selectedEmployees": {
      handler: debounce(async function () {
        await this.fetchAssociations();
      }, 500),
      deep: true,
    },
    "filters.team": {
      handler: debounce(async function () {
        if (this.filters.team) {
          this.teams.forEach((team) => {
            if (team.identifier === this.filters.team) {
              this.filters.selectedEmployees.push(
                ...team.employees
                  .map((e) => e.employeeId)
                  .filter((e) => !this.filters.selectedEmployees.includes(e))
              );
            }
          });
        }
      }, 500),
      deep: true,
    },
  },
  methods: {
    formatDate,
    addDay,
    subtractDay,
    convertHoursToMWD,
    dateRange(year, weekNumber) {
      const formattedWeek = weekNumber.toLocaleString("en-US", {
        minimumIntegerDigits: 2,
        useGrouping: false,
      });
      const startOfWeek = moment(`${year}W${formattedWeek}`)
        .startOf("isoWeek")
        .locale(this.storedLocale);
      const endOfWeek = moment(`${year}W${formattedWeek}`)
        .endOf("isoWeek")
        .locale(this.storedLocale);
      return `${startOfWeek.format("MMM D")}. - ${endOfWeek.format("MMM D")}.`;
    },
    getMondayAndFriday(year, weekNumber) {
      const firstDayOfYear = new Date(year, 0, 1);
      const firstDayOfWeek = firstDayOfYear.getDay();
      const dayOffset = firstDayOfWeek === 0 ? 1 : 8 - firstDayOfWeek;

      const monday = new Date(year, 0, 1 + dayOffset + (weekNumber - 1) * 7);
      const friday = new Date(monday);
      friday.setDate(monday.getDate() + 4);

      return { monday, friday };
    },
    async fetchData() {
      await this.$store.dispatch("census_team/fetchAccessibleTeams");
      // await this.$store.dispatch("employee/fetchWorkers");
      await this.fetchAssociations();
    },
    async fetchAssociations() {
      await this.$store.dispatch("association/getForUsers", {
        employees: this.filters.selectedEmployees,
        from: this.fromDate,
        to: this.toDate,
      });

      const promises = this.filters.selectedEmployees.map((employee) =>
        this.$store.dispatch("worklog/fetchWeekForUser", {
          user: employee,
          from: formatDate(this.fromDate),
          to: formatDate(this.toDate),
        })
      );
      await Promise.all(promises);
    },
    getEmployee(employeeId) {
      return this.employees.find((e) => e.id === employeeId);
    },
    getExpectedHoursSum(association) {
      return association.weekData
        .map((a) => a.expectedHours)
        .reduce((a, b) => a + b, 0);
    },
    getAllocatedHoursSum(association) {
      return association.weekData
        .map((a) => a.allocatedHours)
        .reduce((a, b) => a + b, 0);
    },
    resolveExpectedHours(props, week) {
      return props.row.weekData.find((data) => data.week === week)
        ?.expectedHours;
    },
    resolveAllocatedHours(props, week) {
      return props.row.weekData.find((data) => data.week === week)
        ?.allocatedHours;
    },
    resizeHandler() {
      this.tableWidth = this.$refs.tableWrapper.clientWidth;
    },
    plusWeek() {
      this.fromDate = new Date(this.addDay(this.fromDate, 7));
      this.toDate = new Date(this.addDay(this.toDate, 7));
      this.fetchAssociations();
    },
    minusWeek() {
      this.fromDate = new Date(this.subtractDay(this.fromDate, 7));
      this.toDate = new Date(this.subtractDay(this.toDate, 7));
      this.fetchAssociations();
    },
    resetWeek() {
      this.fromDate = new Date(
        new Date().setDate(
          new Date().getDate() -
            (new Date().getDay() === 0 ? 6 : new Date().getDay() + 6)
        )
      );
      this.toDate = new Date(
        new Date().setDate(new Date().getDate() + (19 - new Date().getDay()))
      );
      this.fetchAssociations();
    },
  },
});
</script>

<style>
.table-height-hack td {
  height: 1px !important;
  white-space: nowrap;
  text-overflow: ellipsis;
}
</style>

<style scoped lang="scss"></style>
