<template>
  <b-table
    :data="conflictData"
    detailed
    :show-detail-icon="true"
    :opened-detailed="openedRows"
  >
    <b-table-column
      field="name"
      :label="$tf('ralloc.conflicts.table.column.name|Név')"
      v-slot="props"
    >
      <UserInfo
        :user="employees.find((emp) => emp.id === props.row.employeeId)"
        displayMode="long"
        tooltipPosition="right"
      />
    </b-table-column>
    <b-table-column
      centered
      field="expectedHours"
      :label="
        $tf('ralloc.conflicts.table.column.expectedHours|Dolgozó erőforrása')
      "
      v-slot="props"
    >
      {{ props.row.expectedHours / 8 + " MWD" }}
    </b-table-column>
    <b-table-column
      centered
      field="allocatedHours"
      :label="
        $tf('ralloc.conflicts.table.column.allocatedHours|Allokált erőforrás')
      "
      v-slot="props"
    >
      <div
        :class="
          props.row.allocatedHours > props.row.expectedHours
            ? 'has-text-danger'
            : 'has-text-warning'
        "
        class="is-flex is-justify-content-center"
      >
        {{ props.row.allocatedHours / 8 + " MWD" }}
        <b-icon
          v-if="props.row.allocatedHours > props.row.expectedHours"
          icon="triangle-exclamation"
          class="is-justify-content-center ml-1"
        ></b-icon>
      </div>
    </b-table-column>
    <b-table-column
      field="role"
      :label="$tf('ralloc.conflicts.table.column.role|Szerepkör')"
      v-slot="props"
    >
      <indicator-tag
        :indicator-id="getEmployee(props.row.employeeId)?.roleId"
      />
    </b-table-column>
    <b-table-column
      field="seniority"
      :label="$tf('ralloc.conflicts.table.column.seniority|Szenioritás')"
      v-slot="props"
    >
      <indicator-tag
        :indicator-id="getEmployee(props.row.employeeId)?.seniorityId"
      />
    </b-table-column>

    <template #detail="props">
      <div class="columns p-0 mb-0">
        <div class="column is-two-thirds p-0">
          <b-table :data="props.row.projects">
            <b-table-column
              field="project"
              :label="
                $tf(
                  'ralloc.conflicts.employeeDetails.table.column.project|Projekt'
                )
              "
              v-slot="props"
            >
              <div class="is-inline-flex">
                <div class="mr-4">
                  <div class="has-font-weight-700">
                    {{ props.row.projectData.identifier }}
                  </div>
                  <div
                    v-if="!props.row.editing"
                    class="is-flex is-justify-content-center"
                  >
                    <b-icon
                      class="mr-1"
                      :icon="RALLOC_PRIORITY[props.row.priority]?.icon"
                    ></b-icon>
                    <span class="conflict-table-priority">
                      {{ $tf(RALLOC_PRIORITY[props.row.priority].token) }}
                    </span>
                  </div>
                  <div v-else class="is-flex is-justify-content-center">
                    <b-select
                      v-model="props.row.priority"
                      :placeholder="
                        $tf(
                          'ralloc.conflicts.employeeDetails.priority.placeholder|Foglalás kiválasztása'
                        )
                      "
                    >
                      <option :value="RALLOC_PRIORITY.LOW.value">
                        {{ $tf(RALLOC_PRIORITY.LOW.token) }}
                      </option>
                      <option :value="RALLOC_PRIORITY.MEDIUM.value">
                        {{ $tf(RALLOC_PRIORITY.MEDIUM.token) }}
                      </option>
                      <option :value="RALLOC_PRIORITY.HIGH.value">
                        {{ $tf(RALLOC_PRIORITY.HIGH.token) }}
                      </option>
                    </b-select>
                  </div>
                </div>
                <div>{{ props.row.projectData.name }}</div>
              </div>
            </b-table-column>
            <b-table-column
              field="client"
              :label="
                $tf(
                  'ralloc.conflicts.employeeDetails.table.column.client|Ügyfél / Felelős'
                )
              "
              v-slot="props"
            >
              <div class="has-font-weight-700">
                {{
                  clients.find(
                    (client) => client.id === props.row.projectData.clientId
                  )?.name ??
                  $tf("ralloc.conflicts.table.unknownData|Ismeretlen")
                }}
              </div>
              <div>
                {{
                  employees.find(
                    (emp) => emp.id === props.row.projectData.leadUserId
                  )?.name ??
                  $tf("ralloc.conflicts.table.unknownData|Ismeretlen")
                }}
              </div>
            </b-table-column>
            <b-table-column
              centered
              field="client"
              :label="
                $tf(
                  'ralloc.conflicts.employeeDetails.table.column.hours|Allokált erőforrás'
                )
              "
              v-slot="props"
              width="242px"
            >
              <template v-if="!props.row.editing">
                <span>
                  {{ props.row.hours / 8 + " MWD" }}
                </span>
                <clickable-icon
                  class="ml-2"
                  icon="pencil"
                  @click="props.row.editing = true"
                />
              </template>
              <template v-else>
                <div class="is-flex has-gap-1 is-align-items-center">
                  <button
                    class="button is-flex is-align-items-center"
                    @click="handleMinusClick(props.row)"
                  >
                    <b-icon icon="minus" />
                  </button>

                  <b-input
                    disabled
                    class="mx-2"
                    custom-class="has-text-centered"
                    :value="props.row.hours / 8 + ' MWD'"
                  />
                  <button
                    class="button is-flex is-justify-content-center is-align-items-center"
                    @click="handlePlusClick(props.row)"
                  >
                    <b-icon icon="plus" />
                  </button>
                </div>
              </template>
            </b-table-column>
          </b-table>
        </div>
        <div class="column pb-0">
          <h6>
            {{
              $tf(
                "ralloc.conflicts.employeeDetails.title|Utilizációs információk"
              )
            }}
          </h6>
          <div>
            <span>
              {{
                $tf(
                  "ralloc.conflicts.employeeDetails.workSchedule|Munkaszerződés:"
                )
              }}
            </span>
            <span class="has-font-weight-700">
              {{ ` ${props.row.workSchedule}h` }}
            </span>
          </div>
          <div>
            <span>
              {{
                $tf(
                  "ralloc.conflicts.employeeDetails.expectedHours|Heti elérhető erőforrás:"
                )
              }}
            </span>
            <span class="has-font-weight-700">
              {{ " " + props.row.expectedHours / 8 + " MWD" }}
            </span>
          </div>
          <div>
            <span>
              {{
                $tf(
                  "ralloc.conflicts.employeeDetails.allocatedHours|Heti foglalt erőforrás:"
                )
              }}
            </span>
            <span class="has-font-weight-700">
              {{ " " + props.row.allocatedHours / 8 + " MWD" }}
            </span>
          </div>
          <div>
            <span>
              {{
                $tf("ralloc.conflicts.employeeDetails.utilization|Utilizáció:")
              }}
            </span>
            <span
              class="has-font-weight-700"
              :class="
                props.row.allocatedHours / props.row.expectedHours > 1
                  ? 'has-text-danger'
                  : 'has-text-warning'
              "
            >
              {{
                " " +
                percentify(props.row.allocatedHours / props.row.expectedHours)
              }}
            </span>
          </div>
        </div>
      </div>
      <div class="columns p-0 mb-0">
        <div class="column is-two-thirds px-0">
          <b-field label="Projekt" label-position="on-border">
            <b-autocomplete
              :ref="`ac-${props.row.employeeId}`"
              rounded
              v-model="projectSearch[props.row.employeeId]"
              :data="filteredProjects(props.row)"
              :custom-formatter="projectFormatter"
              icon="search"
              clearable
              expanded
              field="name"
              @select="
                (option) => (selectedProject[props.row.employeeId] = option)
              "
              :placeholder="
                $tf(
                  'ralloc.conflicts.employeeDetails.projectPicker.placeholder|Projekt keresése...'
                )
              "
            >
              <template #empty>{{
                $tf(
                  "ralloc.conflicts.employeeDetails.projectPicker.notFound|Nincs találat..."
                )
              }}</template>
            </b-autocomplete>
            <b-select
              v-model="selectedPriority[props.row.employeeId]"
              class="mx-5"
              expanded
              :placeholder="
                $tf(
                  'ralloc.conflicts.employeeDetails.priority.placeholder|Foglalás kiválasztása'
                )
              "
            >
              <option :value="RALLOC_PRIORITY.LOW.value">
                {{ $tf(RALLOC_PRIORITY.LOW.token) }}
              </option>
              <option :value="RALLOC_PRIORITY.MEDIUM.value">
                {{ $tf(RALLOC_PRIORITY.MEDIUM.token) }}
              </option>
              <option :value="RALLOC_PRIORITY.HIGH.value">
                {{ $tf(RALLOC_PRIORITY.HIGH.token) }}
              </option>
            </b-select>
            <b-button
              icon-left="plus"
              type="is-primary"
              class="ml-2"
              :disabled="!this.selectedProject[props.row.employeeId]"
              @click="addProject(props.row)"
            >
              {{
                $tf(
                  "ralloc.conflicts.employeeDetails.newAllocation|Új allokáció hozzáadása"
                )
              }}
            </b-button>
          </b-field>
        </div>
        <div class="column">
          <b-button
            class="is-pulled-right"
            type="is-primary"
            icon-left="save"
            @click="saveEmployee(props.row)"
          >
            {{ $tf("ralloc.conflicts.employeeDetails.save|Mentés") }}
          </b-button>
        </div>
      </div>
    </template>

    <template #empty>
      <div class="has-text-centered">
        {{
          $tf("ralloc.conflicts.table.empty|Erre a hétre nincs ilyen kolléga.")
        }}
      </div>
    </template>
  </b-table>
</template>

<script>
import UserInfo from "@/components/module/info/UserInfo.vue";
import { percentify } from "@/utils/util";
import { RALLOC_PRIORITY, USER_INDICATOR_TYPE_ENUM } from "@/utils/const";
import ClickableIcon from "@/components/module/icon/ClickableIcon.vue";
import { mapGetters } from "vuex";
import IndicatorTag from "@/components/module/info/IndicatorTag.vue";
import select from "buefy/src/components/select";

export default {
  name: "ConflictTable",
  components: { IndicatorTag, ClickableIcon, UserInfo },
  props: {
    conflictData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      RALLOC_PRIORITY,
      openedRows: [],
      projectSearch: {},
      selectedProject: {},
      selectedPriority: {},
    };
  },
  computed: {
    ...mapGetters({
      employees: "employee/loggingEmployees",
      clients: "enterprise_clients/clients",
      projects: "enterprise_core/limitedProjects",
    }),
  },
  methods: {
    percentify,
    projectFormatter(project) {
      return `${project.identifier} - ${project.name}`;
    },
    filteredProjects(row) {
      let projects = this.projects || [];
      return projects.filter((element) => {
        let passesFilter = true;
        if (this.projectSearch[row.employeeId]) {
          passesFilter =
            passesFilter &&
            (element.name
              .toString()
              .toLowerCase()
              .indexOf(this.projectSearch[row.employeeId].toLowerCase()) >= 0 ||
              element.identifier
                .toString()
                .toLowerCase()
                .indexOf(this.projectSearch[row.employeeId].toLowerCase()) >=
                0) &&
            !row.projects.map((p) => p.projectId).includes(element.id);
        }
        return passesFilter;
      });
    },
    getEmployee(employeeId) {
      return this.employees.find((emp) => emp.id === employeeId);
    },
    handleMinusClick(association) {
      if (association.hours % 4 === 0) {
        association.hours = association.hours > 4 ? association.hours - 4 : 0;
      } else
        association.hours = association.hours - (4 - (association.hours % 4));
    },
    handlePlusClick(association) {
      association.hours =
        association.hours % 4 === 0
          ? association.hours + 4
          : association.hours + (association.hours % 4);
    },
    addProject(row) {
      row.projects.push({
        editing: true,
        hours: 0,
        priority:
          this.selectedPriority[row.employeeId] ?? RALLOC_PRIORITY.LOW.value,
        projectId: this.selectedProject[row.employeeId].id,
        type: this.selectedProject[row.employeeId].type,
        projectData: {
          identifier: this.selectedProject[row.employeeId].identifier,
          name: this.selectedProject[row.employeeId].name,
          leadUserId: this.selectedProject[row.employeeId].leadUserId,
          clientId: this.selectedProject[row.employeeId].clientId,
        },
      });
    },
    async saveEmployee(association) {
      association.editing = false;
      this.$emit("save", association);
    },
  },
};
</script>

<style scoped lang="scss">
@import "~@/assets/scss/media-queries.scss";

.conflict-table {
  &-priority {
    display: block;
    width: 60px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    font-size: small;

    @include lg-max() {
      width: 60px;
    }

    @include xl() {
      width: 100px;
    }
  }
}
</style>
