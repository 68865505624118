<template>
  <b-tooltip
    :label="tooltip"
    :position="tooltipPosition"
    v-if="tooltip"
    :type="type"
  >
    <button class="clickable-icon-button" :disabled="disabled">
      <b-icon
        :icon="icon"
        :size="size"
        :type="type"
        :pack="pack"
        custom-class="clickable-icon"
      />
    </button>
  </b-tooltip>
  <button class="clickable-icon-button" :disabled="disabled" v-else>
    <b-icon
      :icon="icon"
      :size="size"
      :type="type"
      :pack="pack"
      custom-class="clickable-icon"
    />
  </button>
</template>

<script>
export default {
  name: "ClickableIcon",
  components: {},
  props: {
    icon: {
      type: String,
      required: true,
    },
    size: {
      type: String,
      default: "is-small",
    },
    type: {
      type: String,
      default: "is-primary",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    tooltip: {
      type: String,
    },
    tooltipPosition: {
      type: String,
      default: "is-top",
    },
    pack: {
      type: String,
      required: false,
    },
  },
};
</script>

<style lang="scss">
@import "~@/assets/scss/colors.scss";

.clickable-icon-button {
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
}

.clickable-icon-button:hover .icon,
.clickable-icon-button:hover .clickable-icon {
  color: $grey-dark;
}
</style>
