<template>
  <section>
    <div class="employee-container">
      <UserInfo display-mode="long" :user="data.employee" />
    </div>
    <div class="employee-capacity-progress-bar">
      <b-progress
        show-value
        :rounded="false"
        size="is-medium"
        :max="data.expectedHoursSum"
        class="progress-with-tooltip"
      >
        <template #bar>
          <b-progress-bar
            :value="data.totalLoggedHours"
            type="is-info"
            class="bg-dark-blue"
            ><b-tooltip
              :label="`Logolt: ${convertHoursToMWD(data.totalLoggedHours)} MWD`"
              ><span v-if="data.totalLoggedHours / data.expectedHoursSum >= 0.2"
                >{{ convertHoursToMWD(data.totalLoggedHours) }} MWD</span
              ></b-tooltip
            ></b-progress-bar
          >
          <b-progress-bar
            v-if="data.allocatedHoursSum > 0"
            :value="data.allocatedHoursSum"
            type="is-primary"
            ><b-tooltip
              :label="`Foglalt: ${convertHoursToMWD(
                data.allocatedHoursSum
              )} MWD`"
              ><span
                v-if="data.allocatedHoursSum / data.expectedHoursSum >= 0.2"
                >{{ convertHoursToMWD(data.allocatedHoursSum) }} MWD</span
              ></b-tooltip
            ></b-progress-bar
          >
        </template>
      </b-progress>
    </div>
  </section>
</template>

<script>
import { defineComponent } from "vue";
import { convertHoursToMWD } from "@/utils/util";
import UserInfo from "@/components/module/info/UserInfo.vue";

export default defineComponent({
  name: "UtilizationEmployeeBrief",
  components: { UserInfo },
  methods: { convertHoursToMWD },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
});
</script>

<style>
.progress-with-tooltip {
  overflow: visible !important;
}

.progress-with-tooltip .progress-value {
  height: 100%;
  width: 100%;
}

.progress-with-tooltip .b-tooltip {
  width: 100%;
  height: 100%;
  cursor: default;
  font-size: 10px;
}
</style>

<style scoped lang="scss">
.employee-container {
  display: flex;
  align-items: center;
  gap: 12px;

  .employee-name {
    height: fit-content;
  }
}

.employee-capacity-progress-bar {
  margin-top: 10px;
}
</style>
