<template>
  <div class="is-flex is-flex-direction-row employee-row">
    <div class="cell is-flex-2">
      <UserInfo :user="employee" displayMode="long" tooltipPosition="bottom" />
      <div class="is-flex is-flex-wrap-wrap has-gap-2 m-2">
        <indicator-tag :indicator-id="employee?.roleId" />
      </div>
    </div>
    <div class="cell is-flex-1" v-for="(weekData, index) of weeks" :key="index">
      <div class="pr-2 pl-2 pt-1 pb-1">
        <div class="is-flex is-align-items-center mb-2">
          <div
            @click="openAllocationBox(weekData)"
            :class="allocationButtonClass(weekData)"
          >
            {{ formatAllocationTime(allocatedHours(weekData)) }}
          </div>
          <div class="ml-auto mt-2">
            <div
              @click="copyAllocation(index, weekData)"
              class="ml-1 cursor-pointer"
              v-if="!cloningWeek"
            >
              <b-icon class="icon-xl" icon="copy" />
            </div>
            <div
              @click="pasteAllocation(weekData)"
              class="ml-1 cursor-pointer"
              v-else-if="
                !(
                  cloningWeek.year === weekData.year &&
                  cloningWeek.week === weekData.week
                )
              "
            >
              <b-icon icon="check" />
            </div>
            <div
              @click="closeCopyMode"
              class="ml-1 cursor-pointer"
              v-else-if="cloningWeek"
            >
              <b-icon icon="times-circle" />
            </div>
          </div>
        </div>

        <div class="cursor-pointer" @click="openAllocationBox(weekData)">
          <div class="is-flex is-align-items-center">
            <span class="mx-auto">
              <b-tag
                size="is-medium"
                :type="RALLOC_PRIORITY[priority(weekData)]?.color"
                :icon="RALLOC_PRIORITY[priority(weekData)]?.icon"
              >
                {{ $tf(RALLOC_PRIORITY[priority(weekData)]?.token) }}</b-tag
              ></span
            >
          </div>
        </div>

        <div class="mt-4 ml-1 mb-2">
          <div class="is-flex">
            <div
              class="is-flex cursor-pointer"
              @click="openAllocationBox(weekData)"
            >
              <div style="width: 1.35rem">
                <b-icon size="is-small" icon="clock-rotate-left" />
              </div>
              <div>
                :
                <span
                  class="has-font-weight-700"
                  v-html="availability(weekData)"
                ></span>
              </div>
            </div>
          </div>

          <!--          TODO: backend műtét szükséges, nem érkezik ugyanis ilyen adat-->
          <!--          <div class="is-flex">-->
          <!--            <div style="width: 1.35rem">-->
          <!--              <img-->
          <!--                src="/beach.svg"-->
          <!--                :alt="$tf('resource.allocationRow.imageAlt.dayOff|Szabadság')"-->
          <!--              />-->
          <!--            </div>-->
          <!--            <p>: -</p>-->
          <!--          </div>-->
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { defineComponent } from "vue";
import AllocationBox from "@/components/ralloc/AllocationBox.vue";
import { RALLOC_PRIORITY } from "@/utils/const";
import IndicatorTag from "@/components/module/info/IndicatorTag.vue";
import UserInfo from "@/components/module/info/UserInfo.vue";

export default defineComponent({
  name: "ResourceAllocationRow",
  components: { UserInfo, IndicatorTag },
  props: {
    employee: {
      type: Object,
      required: true,
    },
    weeks: {
      type: Array,
      required: true,
    },
    allocations: {
      type: Array,
      required: true,
    },
    projectId: {
      type: String,
      Number,
      required: true,
    },
    showAllocatedTimeInHours: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      RALLOC_PRIORITY,
      cloningWeek: null,
      modalInstance: null,
    };
  },
  watch: {
    allocations() {
      if (!this.modalInstance) return;

      const weekData =
        this.modalInstance.modalVNode.props.props.allocationData.weekData;
      this.modalInstance.modalVNode.props.props.availabilityHtml =
        this.availability(weekData);
      this.modalInstance.modalVNode.props.props.allocationsByProject =
        this.allocationsByProject(weekData);
      this.modalInstance.$forceUpdate();
    },
  },
  methods: {
    allocationData(weekData) {
      return this.allocations.find(
        (allocation) =>
          allocation.year === weekData.year && allocation.week === weekData.week
      );
    },
    allocatedHoursAll(weekData) {
      return this.allocationData(weekData)?.allocatedHours || 0;
    },
    allocatedHours(weekData) {
      const allocationData = this.allocationData(weekData);
      const association = allocationData?.associations.find(
        (asc) => asc.projectId === this.projectId
      );

      return association?.hours || 0;
    },
    expectedHours(weekData) {
      return this.allocationData(weekData)?.expectedHours || 0;
    },
    priority(weekData) {
      const allocationData = this.allocationData(weekData);
      const association = allocationData?.associations.find(
        (asc) => asc.projectId === this.projectId
      );

      return association?.priority || RALLOC_PRIORITY.MEDIUM.value;
    },
    availability(weekData) {
      const expectedHours = this.expectedHours(weekData);
      const allocatedHours = this.allocatedHoursAll(weekData);

      let color = "";
      if (allocatedHours === 0) {
        color = "#2F2E6B";
      } else if (allocatedHours === expectedHours) {
        color = "#299218";
      } else if (allocatedHours > expectedHours) {
        color = "#D93333";
      } else {
        color = "#E48228";
      }

      const formattedAllocated = this.showAllocatedTimeInHours
        ? `${allocatedHours} / ${expectedHours} H`
        : `${this.decimalFormat(allocatedHours / 8)} / ${this.decimalFormat(
            expectedHours / 8
          )} MWD`;

      return `<span style="color: ${color}">${formattedAllocated}</span>`;
    },
    formatAllocationTime(timeInHours) {
      return this.showAllocatedTimeInHours
        ? `${timeInHours} H`
        : `${timeInHours / 8} MWD`;
    },
    decimalFormat(num) {
      const rounded = Math.round(num * 10) / 10;
      return rounded % 1 === 0 ? rounded.toString() : rounded.toFixed(1);
    },
    allocationsByProject(weekData) {
      const allocationsByProject =
        this.allocationData(weekData)?.associations ?? [];

      return allocationsByProject.sort(
        (a, b) =>
          RALLOC_PRIORITY[b.priority].order - RALLOC_PRIORITY[a.priority].order
      );
    },
    closeCopyMode() {
      this.cloningWeek = null;
    },
    openAllocationBox(weekData) {
      this.closeCopyMode();
      const expectedHours = this.expectedHours(weekData);
      const allocatedHours = this.allocatedHours(weekData);
      const allocationsByProject = this.allocationsByProject(weekData);
      const priority = this.priority(weekData);

      this.modalInstance = this.$buefy.modal.open({
        parent: this,
        component: AllocationBox,
        customClass: "allocation-box",
        events: {
          "modify-allocation": async (updatedData) => {
            const allocationRequest = {
              employeeId: this.employee.id,
              year: weekData.year,
              week: weekData.week,
              hours: updatedData.allocatedHours,
              priority: updatedData.priority,
            };

            this.$emit("modifyAllocation", allocationRequest);
          },
        },
        props: {
          allocationData: {
            weekData: weekData,
            priority: priority,
            allocatedHours: allocatedHours,
          },
          showAllocatedTimeInHours: this.showAllocatedTimeInHours,
          expectedHours: expectedHours,
          allocationsByProject: allocationsByProject,
          availabilityHtml: this.availability(weekData),
        },
      });
    },
    copyAllocation(index, cloningWeek) {
      this.cloningWeek = {
        year: cloningWeek.year,
        week: cloningWeek.week,
        allocatedHours: this.allocatedHours(cloningWeek),
        priority: this.priority(cloningWeek),
      };
    },
    async pasteAllocation(targetWeek) {
      if (!this.cloningWeek) return;
      const targetAllocation = this.allocationData(targetWeek);
      if (targetAllocation) {
        this.$buefy.dialog.confirm({
          title: this.$tf("resource.allocationRow.modal.title|Figyelem!"),
          message: this.$tf(
            "resource.allocationRow.modal.message|A kiválasztatott időszakra már vannak kitöltött allokációk. Szeretnéd ezeket felülírni?"
          ),
          cancelText: this.$tf(
            "resource.allocationRow.modal.cancelText|Megtartás"
          ),
          confirmText: this.$tf(
            "resource.allocationRow.modal.confirmText|Felülírás"
          ),
          type: "is-info",
          onConfirm: async () => {
            await this.emitCloneAllocation(
              targetWeek.year,
              targetWeek.week,
              this.cloningWeek.allocatedHours,
              this.cloningWeek.priority
            );
            this.closeCopyMode();
          },
        });
      } else {
        await this.emitCloneAllocation(
          targetWeek.year,
          targetWeek.week,
          this.cloningWeek.allocatedHours,
          this.cloningWeek.priority
        );
        this.closeCopyMode();
      }
    },
    async emitCloneAllocation(year, week, allocatedHours, priority) {
      const allocationRequest = {
        cloningWeek: this.cloningWeek,
        employeeId: this.employee.id,
        year: year,
        week: week,
        hours: allocatedHours,
        priority: priority,
      };

      this.$emit("cloneAllocation", allocationRequest);
    },
    allocationButtonClass(weekData) {
      let classString =
        "w-100 allocation-button cursor-pointer has-text-centered pr-1 pl-1 pt-2 pb-2 is-rounded-5";

      const allocatedHours = this.allocatedHours(weekData);
      const expectedHours = this.expectedHours(weekData);
      const allocationRatio = (allocatedHours / expectedHours) * 100 || 0;

      if (allocationRatio === 0) {
        return classString.concat(" has-text-dark-blue");
      }
      if (allocationRatio > 0 && allocationRatio <= 20) {
        return classString.concat(
          " has-text-always-black allocation-button-20"
        );
      }
      if (allocationRatio > 20 && allocationRatio <= 40) {
        return classString.concat(
          " has-text-always-black allocation-button-40"
        );
      }
      if (allocationRatio > 40 && allocationRatio <= 60) {
        return classString.concat(
          " has-text-always-white allocation-button-60"
        );
      }
      if (allocationRatio > 60 && allocationRatio <= 80) {
        return classString.concat(
          " has-text-always-white allocation-button-80"
        );
      }
      if (allocationRatio > 80) {
        return classString.concat(
          " has-text-always-white allocation-button-100"
        );
      }

      return classString;
    },
    priorityCircleClass(priority) {
      if (priority === "LOW") {
        return "circle green";
      }
      if (priority === "MEDIUM") {
        return "circle green";
      }
      if (priority === "HIGH") {
        return "circle red";
      }
    },
  },
});
</script>
<style scoped lang="scss">
@import "~@/assets/scss/colors";

.employee-row {
  border-bottom: 0.5px solid $grey-lighter;
  border-top: 0.5px solid $grey-lighter;
}

.cell {
  border-right: 0.5px solid $grey-lighter;
  &:last-child {
    border: none;
  }
}

.circle {
  width: 12px;
  height: 12px;
  border-radius: 50%;
}

.circle.orange {
  background-color: #edb37e;
}

.circle.red {
  background-color: #d93333;
}

.circle.green {
  background-color: #95e888;
}

.availability-green {
  color: #299218;
}
.availability-orange {
  color: #e48228;
}
.availability-red {
  color: #d93333;
}

.is-rounded-5 {
  border-radius: 5px;
}

.allocation-button {
  border: 1px solid $custom-dark-blue;
}
.allocation-button-20 {
  background: #b1b0fa;
}
.allocation-button-40 {
  background: #8785e1;
}
.allocation-button-60 {
  background: #6765c2;
}
.allocation-button-80 {
  background: #494892;
}
.allocation-button-100 {
  background: #2f2e6b;
}
</style>
