<template>
  <div id="table-container">
    <table>
      <tr>
        <td></td>
        <WeekHeader
          v-for="weekData in sortedWeekData"
          :key="`${weekData.year}-${weekData.week}`"
          :year="weekData.year"
          :week="weekData.week"
        ></WeekHeader>
      </tr>
      <tr>
        <td>
          {{
            $tf(
              "resource.utilization.table.weeklyAllocationSum|HETI TELJES ALLOKÁCIÓ"
            )
          }}:
        </td>
        <td
          v-for="weekData in sortedWeekData"
          :key="weekData.week"
          class="allocation-sum"
        >
          <span
            :style="{ color: getAllocatedHoursColor(weekData) }"
            class="bold"
            >{{ convertHoursToMWD(weekData.allocatedHours) }} /
            {{ convertHoursToMWD(weekData.expectedHours) }} MWD ({{
              percentify(weekData.allocatedHours / weekData.expectedHours)
            }}%)
            <b-icon
              icon="triangle-exclamation"
              v-if="weekData.allocatedHours > weekData.expectedHours"
            ></b-icon
          ></span>
        </td>
      </tr>
      <template v-for="project in projects" :key="project.id">
        <tr>
          <td rowspan="2">
            <div class="flex">
              <div
                class="circle"
                :style="{ backgroundColor: project.projectColor }"
              ></div>
              <div class="project-info">
                <span class="inline project-title">{{ project.name }}</span>
                <span class="inline project-lead">{{ project.lead }}</span>
              </div>
            </div>
          </td>
        </tr>
        <tr>
          <td
            v-for="weekData in projectWeekData(project.id)"
            :key="weekData.week"
            class="content-container"
            :class="{
              'padding-left': weekData.firstCol,
              'no-right-border':
                (weekData.firstCol && !weekData.lastCol) ||
                (!weekData.firstCol &&
                  !weekData.lastCol &&
                  weekData.association),
              'padding-right': weekData.lastCol,
            }"
          >
            <div
              :style="{
                backgroundColor:
                  weekData.association?.projectData.projectColor ??
                  'var(--blue)',
              }"
              :class="{
                'project-card-first-col': weekData.firstCol,
                'project-card-last-col': weekData.lastCol,
              }"
              class="project-card-content"
              v-if="weekData.association"
            >
              <span>
                {{ convertHoursToMWD(weekData.association.hours) }} MWD
                <b-icon
                  :icon="RALLOC_PRIORITY[weekData.association.priority].icon"
                />
              </span>
            </div>
          </td>
        </tr>
      </template>
      <tr v-if="projects.length > 5">
        <td>
          <span class="too-much-projects"
            ><b-icon icon="triangle-exclamation"></b-icon> Túl sok project</span
          >
        </td>
      </tr>
    </table>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import WeekHeader from "@/components/ralloc/project-allocations/WeekHeader.vue";
import { mapGetters } from "vuex";
import { convertHoursToMWD, percentify } from "@/utils/util";
import { RALLOC_PRIORITY } from "@/utils/const";

export default defineComponent({
  name: "EmployeeProjectAllocations",
  components: { WeekHeader },
  props: {
    allocations: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      RALLOC_PRIORITY,
    };
  },
  computed: {
    sortedWeekData() {
      return [...this.allocations.weekData].sort((a, b) =>
        a.year === b.year ? a.week - b.week : a.year - b.year
      );
    },
    projects() {
      return [
        ...new Map(
          this.allocations.weekData
            .flatMap((item) => item.associations)
            .map((item) => item.projectData)
            .map((item) => [item.id, item])
        ).values(),
      ].map((item) => ({
        lead: this.resolveLeadName(item.leadUserId),
        ...item,
      }));
    },
    ...mapGetters({
      employees: "employee/loggingEmployees",
    }),
  },
  methods: {
    convertHoursToMWD,
    percentify,
    resolveLeadName(id) {
      return this.employees.find((e) => e.id === id)?.name;
    },
    projectWeekData(projectId) {
      const sortedData = this.sortedWeekData.map((item) => ({
        association: item.associations.find(
          (assoc) => assoc.projectId === projectId
        ),
        ...item,
      }));

      return sortedData.map((item, i) => ({
        firstCol: this.isFirstCol(sortedData, item, i),
        lastCol: this.isLastCol(sortedData, item, i),
        ...item,
      }));
    },
    isFirstCol(dataArray, item, i) {
      if (!item.association) {
        return false;
      }

      if (i === 0) {
        return true;
      }

      return !dataArray[i - 1].association;
    },
    isLastCol(dataArray, item, i) {
      if (!item.association) {
        return false;
      }

      if (i === dataArray.length - 1) {
        return true;
      }

      return !dataArray[i + 1].association;
    },
    projectMergedColumns(projectId) {
      const weekData = this.projectWeekData(projectId);
      const result = [];
      let counter = 0;
      for (const [i, data] of weekData.entries()) {
        if (data.association) {
          counter++;
          if (weekData.length > i + 1 && weekData[i + 1].association) {
            continue;
          }
        }
        result.push({
          columnSpan: counter !== 0 ? counter : 1,
          ...data,
        });
        counter = 0;
      }

      return result;
    },
    getAllocatedHoursColor(weekData) {
      if (weekData.allocatedHours < weekData.expectedHours) {
        return "var(--custom-orange)";
      } else if (weekData.allocatedHours > weekData.expectedHours) {
        return "var(--red)";
      } else {
        return "var(--green)";
      }
    },
  },
});
</script>

<style scoped lang="scss">
@import "~@/assets/scss/colors";
#table-container {
  background-color: var(--white);
  border-radius: 8px;
  padding: 12px 12px 12px 0;
  overflow-x: scroll;
  overflow-y: visible;
  position: relative;

  tr:first-child {
    td {
      text-align: center;
      border-bottom: 1px solid var(--grey-lighter);
    }
  }

  tr:not(:first-child) td {
    border-bottom: none;
  }

  td:first-child {
    position: sticky;
    left: 0;
    z-index: 11111;
  }

  td {
    white-space: normal;
    border: unset;
  }

  td:not(:last-child) {
    border-right: 1px solid var(--grey-lighter);
  }

  tr:first-child td:first-child,
  tr:nth-child(2) td:first-child {
    border-right: 2px solid var(--grey-light) !important;
    background-color: var(--white);
  }

  tr:first-child td:not(:last-child) {
    border-right: 1px solid var(--grey-lighter);
  }

  tr:nth-child(odd) td:first-child {
    border-right: none;
  }

  tr:nth-child(odd) td {
    padding-bottom: 0;
  }

  .project-card-header {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    padding: 0 8px;
    color: white;
    height: 100%;
  }

  .project-card-first-col {
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
  }

  .project-card-last-col {
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
  }

  .no-right-border {
    border-right: none !important;
  }

  .project-info {
    line-height: 1rem;
  }

  .padding-left {
    padding-left: 0.75rem !important;
  }

  .padding-right {
    padding-right: 0.75rem !important;
  }

  tr:nth-child(even) td {
    padding-top: 0;
  }

  .content-container {
    padding-left: 0;
    padding-right: 0;
  }

  tr:nth-child(odd) td:first-child {
    border-right: 2px solid var(--grey-light) !important;
    width: 12rem;
    background-color: var(--white);
    z-index: 9999999;
  }

  .allocation-sum {
    font-size: 1rem;
    text-align: center;
    color: var(--custom-dark-blue);
  }

  .project-name {
    font-weight: 700;
    margin-right: 6px;
  }

  .too-much-projects {
    color: var(--red);
    font-size: 14px;
    display: flex;
    align-items: center;
  }
}
table {
  width: 100%;
  table-layout: fixed;
  border-collapse: separate;
  border-spacing: 0;
}
td,
th {
  display: table-cell;
  vertical-align: middle;
  word-wrap: break-word;
}

tr:first-child {
  border-bottom: 1px solid var(--grey-lighter) !important;
}

tr:first-child td {
  padding-bottom: 0;
}

td {
  width: 12rem;
}

.circle {
  width: 12px;
  height: 12px;
  background-color: green;
  border-radius: 50%;
}

.flex {
  display: flex;
  justify-items: center;
  align-items: center;
  gap: 6px;
}

.inline {
  display: inline-block;
}

.project-title {
  font-weight: 700;
  display: block;
}

.bold {
  font-weight: 700;
}

.project-lead {
  font-size: 12px;
}

.project-card-content {
  padding: 6px 8px;
  color: white;
  height: 100%;
  font-size: 1rem;
  text-align: center;
}
</style>
