<template>
  <section v-if="!loading">
    <p class="title">Projektek allokációi, utilizáltságuk</p>
    <div class="is-flex has-gap-5">
      <div style="width: 500px">
        <b-field expanded>
          <multiselect-dropdown
            v-model="selectedProjects"
            :items="activeProjects"
            append-to-body
            identifier-field="id"
            name-field="name"
            use-tf
            expanded
            searchable
            icon="magnifying-glass"
          >
            <template #empty>{{
              $tf("resource.utilization.filter.empty|Nincs találat")
            }}</template>
          </multiselect-dropdown>
        </b-field>
      </div>
      <div class="ml-4 pt-2">
        <b-icon
          class="cursor-pointer mr-2 icon-xl"
          icon="chevron-left"
          @click="prevWeek"
        />
        <b-icon
          class="cursor-pointer mr-2 icon-xl"
          icon="undo"
          @click="resetWeek"
        />
        <b-icon
          class="cursor-pointer icon-xl"
          icon="chevron-right"
          @click="nextWeek"
        />
      </div>
    </div>
    <div id="utilization-table-container" ref="tableWrapper">
      <b-table
        :data="extendedAssociations"
        class="table-height-hack"
        v-if="extendedAssociations.length > 0"
      >
        <b-table-column
          field="name"
          :label="$tf('resource.utilization.projects.table.project|Projekt')"
          v-slot="{ row }"
        >
          <div class="utilization-top-row">
            {{ row.identifier }}
          </div>
          <div class="utilization-bottom-row">{{ row.name }}</div>
        </b-table-column>
        <b-table-column
          field="totalHours"
          :label="
            $tf('resource.utilization.projects.table.summary|Összefoglaló')
          "
          v-slot="{ row }"
          centered
        >
          <div class="utilization-top-row">
            <b-icon icon="sigma" /><b-icon icon="ballot-check" />{{
              workdayify(row.totalAllocated)
            }}
            / <b-icon icon="sigma" /><b-icon icon="business-time" />
            {{ workdayify(row.totalLogged) }}
          </div>
          <div class="utilization-bottom-row has-font-weight-700">
            {{
              percentify(
                (row.totalLogged - row.totalAllocated) / row.totalAllocated
              )
            }}
          </div>
        </b-table-column>
        <b-table-column field="actions" label="Műveletek" centered>
          <div class="util-actions-column">
            <b-icon icon="user-plus" />
            <b-icon icon="up-right-from-square" />
          </div>
        </b-table-column>
        <b-table-column
          v-for="(week, i) in displayedWeeks"
          field="weekData.week"
          :key="i"
          centered
        >
          <template v-slot:header="">
            {{ `${week.week}. hét` }} <br />
            {{ formatDate(week.start) }} -
            {{ formatDate(week.end) }}
          </template>
          <template v-slot="{ row }">
            <WeeklyProjectUtilization
              :weekly="
                findWeekData({ year: week.year, week: week.week }, row.weekly)
              "
              :future="week.start > new Date()"
            />
          </template>
        </b-table-column>
        <template #detail=""> </template>
      </b-table>
      <div v-if="loading">
        {{
          $tf(
            "resource.utilization.projects.loading|Projektadatok betöltése folyamatban..."
          )
        }}
      </div>
      <div v-if="extendedAssociations.length <= 0">
        {{
          $tf(
            "resource.utilization.projects.empty|A megjelenítéshez válassz legalább egy projektet!"
          )
        }}
      </div>
    </div>
  </section>
</template>
<script>
import { defineComponent } from "vue";
import { mapGetters } from "vuex";
import MultiselectDropdown from "@/components/MultiselectDropdown.vue";
import debounce from "lodash.debounce";
import {
  workdayify,
  convertHoursToMWD,
  formatDate,
  generateNext4Weeks,
  addDay,
  subtractDay,
  percentify,
} from "@/utils/util";
import WeeklyProjectUtilization from "@/components/ralloc/WeeklyProjectUtilization.vue";

export default defineComponent({
  name: "UtilizationProjects",
  components: { WeeklyProjectUtilization, MultiselectDropdown },
  created() {
    window.addEventListener("resize", this.resizeHandler);
  },
  async mounted() {
    await this.fetchData();
    this.loading = false;
    this.$nextTick(() => {
      this.tableWidth = this.$refs.tableWrapper.clientWidth;
    });
  },
  unmounted() {
    window.removeEventListener("resize", this.resizeHandler);
  },
  data() {
    return {
      workdayify,
      formatDate,
      loading: true,
      selectedProjects: [],
      tableWidth: 0,
      startWeekDate: new Date(),
    };
  },
  computed: {
    extendedAssociations() {
      let data = [];
      this.associations.forEach((project) => {
        let worklogData = this.findWorklogProject(
          project.projectId,
          this.worklogs
        );
        let weeklyData = [];
        project.weeklyUtilizations.forEach((week) => {
          let loggedData = this.findMatchingWeekly(
            week,
            worklogData?.weeklyWorklogs
          );
          weeklyData.push({
            year: week.year,
            week: week.week,
            allocatedHours: week.allocatedHours,
            loggedHours: loggedData?.loggedHours
              ? loggedData.loggedHours / 8
              : 0,
            weeklyLogs: loggedData?.worklogs,
            weeklyAllocations: loggedData?.allocations,
          });
        });
        data.push({
          identifier: project.projectData.identifier,
          name: project.projectData.name,
          totalAllocated: project.totalHours / 8,
          totalLogged: worklogData?.totalLogged
            ? worklogData.totalLogged / 8
            : 0,
          weekly: weeklyData,
        });
      });
      return data;
    },
    displayedWeeks() {
      return generateNext4Weeks(this.startWeekDate);
    },
    activeProjects() {
      return this.projects
        .filter(
          (project) =>
            !project.archived &&
            project.status !== "CANCELLED" &&
            project.status !== "DONE" &&
            project.status !== "CLOSED"
        )
        .sort((a, b) => a.name.localeCompare(b.name));
    },
    ...mapGetters({
      associations: "association/projectUtilizations",
      worklogs: "worklog/worklogsForProjects",
      projects: "enterprise_core/limitedProjects",
      employees: "employee/employees",
    }),
  },
  watch: {
    "selectedProjects.length": {
      handler: debounce(async function () {
        await this.fetchDisplayData();
      }, 500),
    },
  },
  methods: {
    percentify,
    convertHoursToMWD,
    addDay,
    subtractDay,
    findWorklogProject(projectId, worklogData) {
      return worklogData.find((worklog) => worklog.projectId === projectId);
    },
    findMatchingWeekly(assWeekly, worklogWeeklys) {
      return worklogWeeklys.find(
        (wweekly) =>
          assWeekly.year === wweekly.year && wweekly.week === assWeekly.week
      );
    },
    nextWeek() {
      this.startWeekDate = new Date(this.addDay(this.startWeekDate, 7));
    },
    prevWeek() {
      this.startWeekDate = new Date(this.subtractDay(this.startWeekDate, 7));
    },
    resetWeek() {
      this.startWeekDate = new Date();
    },
    findEmployee(employeeId) {
      return this.employees.find((employee) => employee.id === employeeId);
    },
    findWeekData({ year, week }, weeklyData) {
      for (let weekly of weeklyData) {
        if (weekly.year === year && weekly.week === week) {
          return weekly;
        }
      }
      return {
        noData: true,
      };
    },
    getMondayAndFriday(year, weekNumber) {
      const firstDayOfYear = new Date(year, 0, 1);
      const firstDayOfWeek = firstDayOfYear.getDay();
      const dayOffset = firstDayOfWeek === 0 ? 1 : 8 - firstDayOfWeek;

      const monday = new Date(year, 0, 1 + dayOffset + (weekNumber - 1) * 7);
      const friday = new Date(monday);
      friday.setDate(monday.getDate() + 4);

      return { monday, friday };
    },
    async fetchData() {
      await this.$store.dispatch("enterprise_core/fetchLimitedProjects");
      await this.$store.dispatch("employee/fetch");
      this.loading = false;
    },
    async fetchDisplayData() {
      this.loading = true;
      await this.$store.dispatch(
        "association/getUtilizationForProjects",
        this.selectedProjects
      );
      await this.$store.dispatch(
        "worklog/fetchWorklogsForProjects",
        this.selectedProjects
      );
      this.loading = false;
    },
    resizeHandler() {
      this.tableWidth = this.$refs.tableWrapper.clientWidth;
    },
  },
});
</script>

<style></style>

<style scoped lang="scss">
@import "~@/assets/scss/colors.scss";

.table-height-hack td {
  height: 1px !important;
  white-space: nowrap;
  text-overflow: ellipsis;
}

#utilization-table-container {
  padding-top: 25px;
}
.utilization-top-row {
  font-size: 1.1rem;
}
.utilization-bottom-row {
  font-size: 0.9rem;
}
.util-actions-column {
  color: $blue;
}
</style>
