import { newLogger } from "@/utils/util";
import StoreCore from "../../../StoreCore";

let logger = newLogger("Census-Team");

const urls = {
  teams: "/census/teams",
  accessibleTeams: "/census/accessible-teams",
  myTeams: "/census/my-teams",
  leadTeams: "/census/lead-teams",
  team: "/census/team",
  teamWithId: (id) => `/census/team/${id}`,
  automaticProjectJoin: "/census/team/automatic-join",
};

const initState = () => {
  return {
    teams: [],
    accessibleTeams: [],
    myTeams: [],
    leadTeams: [],
    team: {},
  };
};

const storeCore = new StoreCore();
const actions = {
  fetchTeams: async function ({ commit, dispatch }, force) {
    try {
      let data = await this.remoteRequest(
        "get",
        urls.teams,
        null,
        true,
        dispatch,
        "Csapatok betöltése...",
        null,
        force
      );
      commit("saveTeams", data);
    } catch (err) {
      if ("Cached!" !== err) {
        logger.error(err);
      }
    }
  }.bind(storeCore),
  fetchAccessibleTeams: async function ({ commit, dispatch }, force) {
    try {
      let data = await this.remoteRequest(
        "get",
        urls.accessibleTeams,
        null,
        true,
        dispatch,
        "Csapatok betöltése...",
        null,
        force
      );
      commit("saveAccessibleTeams", data);
    } catch (err) {
      if ("Cached!" !== err) {
        logger.error(err);
      }
    }
  }.bind(storeCore),
  fetchMyTeams: async function ({ commit, dispatch }) {
    try {
      let data = await this.remoteRequest(
        "get",
        urls.myTeams,
        null,
        true,
        dispatch,
        "Csapataim betöltése..."
      );
      commit("saveMyTeams", data);
    } catch (err) {
      if ("Cached!" !== err) {
        logger.error(err);
      }
    }
  }.bind(storeCore),
  fetchLeadTeams: async function ({ commit, dispatch }) {
    try {
      let data = await this.remoteRequest(
        "get",
        urls.leadTeams,
        null,
        true,
        dispatch,
        "Általam leadelt csapatok betöltése..."
      );
      commit("saveLeadTeams", data);
    } catch (err) {
      if ("Cached!" !== err) {
        logger.error(err);
      }
    }
  }.bind(storeCore),
  fetchTeam: async function ({ commit, dispatch }, id) {
    try {
      let data = await this.remoteRequest(
        "get",
        urls.teamWithId(id),
        null,
        true,
        dispatch,
        "Csapat betöltése..."
      );
      commit("saveTeam", data);
    } catch (err) {
      if ("Cached!" !== err) {
        logger.error(err);
      }
    }
  }.bind(storeCore),
  saveTeam: async function ({ dispatch }, team) {
    try {
      await this.remoteRequest(
        "put",
        urls.team,
        team,
        false,
        dispatch,
        "team.save.flair|Csapat mentése",
        undefined,
        undefined,
        false,
        true,
        {
          progress: "team.save.progress|Csapat mentése folyamatban...",
          fail: "team.save.fail|Csapat mentése sikertelen!",
          success: "team.save.success|Csapat mentése sikeres!",
        }
      );
    } catch (err) {
      if ("Cached!" !== err) {
        logger.error(err);
      }
    }
  }.bind(storeCore),
  deleteTeam: async function ({ dispatch }, id) {
    try {
      await this.remoteRequest(
        "delete",
        urls.teamWithId(id),
        null,
        false,
        dispatch,
        "team.delete.flair|Csapat törlése",
        undefined,
        undefined,
        false,
        true,
        {
          progress: "team.delete.progress|Csapat törlése folyamatban...",
          fail: "team.delete.fail|Csapat törlése sikertelen!",
          success: "team.delete.success|Csapat törlése sikeres!",
        }
      );
    } catch (err) {
      if ("Cached!" !== err) {
        logger.error(err);
      }
    }
  }.bind(storeCore),
  automaticProjectJoin: async function ({ commit, dispatch }, activityPercent) {
    try {
      let data = await this.remoteRequest(
        "post",
        urls.automaticProjectJoin,
        activityPercent,
        false,
        dispatch,
        "team.automaticProjectJoin.flair|Projektek automatikus összerendelése",
        undefined,
        undefined,
        true,
        {
          progress:
            "team.automaticProjectJoin.progress|Projektek automatikus összerendelése folyamatban...",
          fail: "team.automaticProjectJoin.fail|Projektek automatikus összerendelése sikertelen!",
          success:
            "team.automaticProjectJoin.success|Projektek automatikus összerendelése sikeres!",
        }
      );
      commit("saveTeam", data);
    } catch (err) {
      if ("Cached!" !== err) {
        logger.error(err);
      }
    }
  }.bind(storeCore),
  clearCache: async function ({}) {
    this.clearCoreCache();
  }.bind(storeCore),
};

const mutations = {
  saveTeams: (state, teams) => {
    state.teams = teams;
  },
  saveAccessibleTeams: (state, teams) => {
    state.accessibleTeams = teams;
  },
  saveMyTeams: (state, teams) => {
    state.myTeams = teams;
  },
  saveLeadTeams: (state, teams) => {
    state.leadTeams = teams;
  },
  saveTeam: (state, team) => {
    state.team = team;
  },
};

const getters = {
  teams: (state) => {
    return state.teams;
  },
  accessibleTeams: (state) => {
    return state.accessibleTeams;
  },
  myTeams: (state) => {
    return state.myTeams;
  },
  leadTeams: (state) => {
    return state.leadTeams;
  },
  team: (state) => {
    return state.team;
  },
};

export default {
  namespaced: true,
  state: initState(),
  mutations: mutations,
  actions: actions,
  getters: getters,
};
