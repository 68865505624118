import { newLogger, subtractDay, addDay, formatDate } from "@/utils/util";
import StoreCore from "@/store/StoreCore";

let storeName = "DashboardStore";
let logger = newLogger(storeName);

let compareDates = function (a, b) {
  if (a.dates.length > b.dates.length) {
    return -1;
  }
  if (a.dates.length < b.dates.length) {
    return 1;
  }
  if (a.dates.length === b.dates.length) {
    if (a.userId < b.userId) {
      return -1;
    }
    if (a.userId > b.userId) {
      return 1;
    }
  }
  return 0;
};

let getDateOfISOWeek = function (w, y) {
  const simple = new Date(y, 0, 1 + (w - 1) * 7);
  const dow = simple.getDay();
  const ISOWeekStart = simple;
  if (dow <= 4) ISOWeekStart.setDate(simple.getDate() - simple.getDay() + 1);
  else ISOWeekStart.setDate(simple.getDate() + 8 - simple.getDay());
  return ISOWeekStart;
};

let getISOWeek = function (dt) {
  const tdt = new Date(dt.valueOf());
  const dayNum = (dt.getDay() + 6) % 7;
  tdt.setDate(tdt.getDate() - dayNum + 3);
  const firstThursday = tdt.valueOf();
  tdt.setMonth(0, 1);
  if (tdt.getDay() !== 4) {
    tdt.setMonth(0, 1 + ((4 - tdt.getDay() + 7) % 7));
  }
  return 1 + Math.ceil((firstThursday - tdt) / 604800000);
};

const urls = {
  actives: "/absence/absence-request/dashboard",
  workSchedules: "/census/work-schedule/dashboard",
};

const initState = () => {
  return {
    actYear: new Date().getFullYear(),
    actWeek: null,
    fromDate: null,
    toDate: null,
    absences: [],
    workSchedules: [],
  };
};

const storeCore = new StoreCore();
const actions = {};
actions.fetch = async function ({ commit, dispatch, state }) {
  if (!state.actWeek) {
    let ISOWeek = getISOWeek(new Date());
    commit("setISOWeek", ISOWeek);
  }
  let fromDate = getDateOfISOWeek(state.actWeek, state.actYear);
  commit("setFromToDate", fromDate);

  try {
    let absences = await this.remoteRequest(
      "get",
      urls.actives,
      {
        params: {
          from: formatDate(state.fromDate),
          to: formatDate(state.toDate),
        },
      },
      true,
      dispatch,
      "Aktív szabadságok betöltése..."
    );
    commit("save", absences);
  } catch (err) {
    if ("Cached!" !== err) {
      logger.error(err);
    } else {
      logger.info(storeName + ".fetch() was cached!");
    }
  }
}.bind(storeCore);
actions.fetchWorkSchedules = async function ({ commit, dispatch, state }) {
  if (!state.actWeek) {
    let ISOWeek = getISOWeek(new Date());
    commit("setISOWeek", ISOWeek);
  }
  let fromDate = getDateOfISOWeek(state.actWeek, state.actYear);
  commit("setFromToDate", fromDate);

  try {
    let data = await this.remoteRequest(
      "get",
      urls.workSchedules,
      {
        params: { year: state.actYear, week: state.actWeek },
      },
      true,
      dispatch,
      "Szabadságkérelem admin típusok betöltése...",
      "fetchWorkSchedules"
    );
    commit("saveWorkSchedules", data);
  } catch (err) {
    if ("Cached!" !== err) {
      logger.error(err);
    }
  }
}.bind(storeCore);

(actions.clearCache = function ({}) {
  this.clearCoreCache();
}.bind(storeCore)),
  (actions.prevWeek = ({ commit, state }) => {
    let prevWeeksFirstDay = new Date(subtractDay(state.fromDate, 14));
    let prevWeek = getISOWeek(prevWeeksFirstDay);
    if (prevWeeksFirstDay.getFullYear() !== state.actYear) {
      state.actYear = prevWeeksFirstDay.getFullYear();
    }
    commit("setISOWeek", prevWeek);
  });
actions.nextWeek = ({ commit, state }) => {
  let nextWeeksFirstDay = new Date(addDay(state.fromDate, 14));
  let nextWeek = getISOWeek(nextWeeksFirstDay);
  if (nextWeeksFirstDay.getFullYear() !== state.actYear) {
    state.actYear = nextWeeksFirstDay.getFullYear();
  }
  commit("setISOWeek", nextWeek);
};
actions.resetActWeek = ({ commit, state }) => {
  state.actYear = new Date().getFullYear();
  let week = getISOWeek(new Date());
  commit("setISOWeek", week);
};

const mutations = {
  setISOWeek(state, week) {
    state.actWeek = week;
  },
  setFromToDate(state, fromDate) {
    state.fromDate = fromDate;
    state.toDate = new Date(fromDate);
    state.toDate.setDate(state.fromDate.getDate() + 13);
  },
  save: (state, absences) => {
    if (state.absences) {
      state.absences.length = 0;
    }
    for (let abs in absences) {
      if (!absences.hasOwnProperty(abs) || !absences[abs]) continue;
      state.absences.push(absences[abs]);
    }
    state.absences.sort(compareDates);
  },
  saveWorkSchedules: (state, workschedules) => {
    if (state.workSchedules) {
      state.workSchedules.length = 0;
    }
    for (let ws in workschedules) {
      if (!workschedules.hasOwnProperty(ws) || !workschedules[ws]) continue;
      state.workSchedules.push(workschedules[ws]);
    }
  },
};

const getters = {
  absences: (state) => {
    return state.absences;
  },
  workschedules: (state) => {
    return state.workSchedules;
  },
  actWeek: (state) => {
    return state.actWeek;
  },
  actYear: (state) => {
    return state.actYear;
  },
  fromDate: (state) => {
    return state.fromDate;
  },
  toDate: (state) => {
    return state.toDate;
  },
};

export default {
  namespaced: true,
  state: initState(),
  mutations: mutations,
  actions: actions,
  getters: getters,
};
