<template>
  <div
    class="newForecastCard is-flex is-flex-direction-column"
    style="gap: 24px"
  >
    <h3 class="title">
      {{ $tf("projectPage.forecastWholeStatus.title|Egész projekt") }}
    </h3>
    <div>
      <ForecastWholeTable
        class="is-flex-5"
        :project-details="projectDetails"
        :project-data="projectData"
      />
    </div>
  </div>
</template>

<script>
import ForecastWholeTable from "@/components/enterprise/project/module/forecast/ForecastWholeTable.vue";
import { mapGetters } from "vuex";
import { getCurrentColorSet } from "@/utils/util";

export default {
  name: "ForecastWholeStatusOverview",
  components: {
    ForecastWholeTable,
  },
  mixins: [],
  props: {
    projectDetails: {
      type: Object,
      required: true,
    },
    projectData: {
      type: Object,
      required: true,
    },
  },
  computed: {
    currentColors() {
      return getCurrentColorSet();
    },
    ...mapGetters({
      currencySymbol: "uiConfigStore/getCurrencySymbol",
    }),
    budgetData() {
      return {
        title: this.$tf(
          "projectPage.forecastWholeStatus.budgetChart.title|Büdzsé - ezer {currency}",
          { currency: this.currencySymbol }
        ),
        tooltip: this.$tf(
          "projectPage.forecastWholeStatus.budgetChart.tooltip|Az oszlopdiagram megmutatja, hogy a projekt jelenlegi állapota szerint a várható költés hogyan fog alakulni a tervvel szemben. Optimális esetben a két előrejelzési adat a terv alatt van, vagy megegyezik vele. Amennyiben a költés előrejelzés meghaladja a tervezett büdzsé értékét, a diagram jelzi az egyes oszlopokon a különbséget."
        ),
      };
    },
    mwdData() {
      return {
        title: this.$tf(
          "projectPage.forecastWholeStatus.mwdChart.title|Munkaidő - MWD"
        ),
        tooltip: this.$tf(
          "projectPage.forecastWholeStatus.mwdChart.tooltip|Az oszlopdiagram megmutatja, hogy a projekt jelenlegi állapota szerint a várható erőforrás ráfordítás hogyan fog alakulni a tervvel szemben. Optimális esetben a két előrejelzési adat a terv alatt van, vagy megegyezik vele. Amennyiben a ráfordítás előrejelzés meghaladja a tervezett ráfordítás értékét, a diagram jelzi az egyes oszlopokon a különbséget."
        ),
      };
    },
    profitData() {
      return {
        title: this.$tf(
          "projectPage.forecastWholeStatus.profitChart.title|Profit - ezer {currency}",
          { currency: this.currencySymbol }
        ),
        tooltip: this.$tf(
          "projectPage.forecastWholeStatus.profitChart.tooltip|Az oszlopdiagram megmutatja, hogy a projekt jelenlegi állapota szerint a várható profit hogyan fog alakulni a tervvel szemben. Optimális esetben a két előrejelzési adat a terv felett van, vagy megegyezik vele. Amennyiben a profit előrejelzés alulmúlja a tervezett profit értékét, a diagram jelzi az egyes oszlopokon a különbséget."
        ),
      };
    },
    dueDateChartHeight() {
      const ref = this.$refs.dueDateChart;
      if (!ref) return 120;
      return ref.style.height;
    },
  },
};
</script>

<style scoped lang="scss">
@import "~@/assets/scss/colors.scss";

.is-flex-basis-0 {
  flex-basis: 0;
}
</style>
