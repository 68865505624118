<template>
  <section v-if="!loading">
    <div class="columns">
      <div class="column is-one-third">&nbsp;</div>
      <div class="column is-one-third">
        <b-field
          :label="$tf('ralloc.conflicts.weekSelect.label|Hét kiválasztása')"
          label-position="on-border"
          size="is-large"
          expanded
        >
          <b-select v-model="selectedWeek" expanded>
            <option
              v-for="week in generateNext6Weeks(this.now)"
              :key="week.week"
              :value="week"
            >
              {{
                $tf(
                  "ralloc.conflicts.weekSelect.value|{number}. hét ({start}.-{end}.)",
                  {
                    number: week.week,
                    start: week.startDate,
                    end: week.endDate,
                  }
                )
              }}
            </option>
          </b-select>
        </b-field>
      </div>
      <div class="column is-one-third">&nbsp;</div>
    </div>

    <div class="container mx-0">
      <h3>
        {{ $tf("ralloc.conflicts.overutilized.title|Túlutilizált kollégák") }}
      </h3>
      <conflict-table
        :conflict-data="
          utilization.filter((elem) => elem.allocatedHours > elem.expectedHours)
        "
        v-on:save="(association) => saveAssociation(association)"
      ></conflict-table>
    </div>

    <div class="container mx-0">
      <h3>
        {{ $tf("ralloc.conflicts.underutilized.title|Alulutilizált kollégák") }}
      </h3>
      <conflict-table
        :conflict-data="
          utilization.filter((elem) => elem.allocatedHours < elem.expectedHours)
        "
        v-on:save="(association) => saveAssociation(association)"
      ></conflict-table>
    </div>
  </section>
</template>

<script>
import { defineComponent } from "vue";
import { formatDate, generateNext6Weeks } from "@/utils/util";
import ConflictTable from "@/components/ralloc/ConflictTable.vue";
import { mapGetters } from "vuex";

export default defineComponent({
  name: "UtilizationConflict",
  components: { ConflictTable },
  data() {
    return {
      now: new Date(),
      selectedWeek: null,
      loading: false,
    };
  },
  computed: {
    ...mapGetters({
      utilization: "association/utilization",
    }),
  },
  watch: {
    async selectedWeek(newVal) {
      this.loading = true;
      await this.$store.dispatch("association/getUtilization", {
        from: formatDate(newVal.start),
        to: formatDate(newVal.end),
      });
      this.loading = false;
    },
  },
  methods: {
    generateNext6Weeks,
    async saveAssociation(association) {
      await this.$store.dispatch("association/updateAssociation", {
        employeeId: association.employeeId,
        year: this.selectedWeek.year,
        week: this.selectedWeek.week,
        projects: association.projects.filter((project) => project.editing),
      });
      await this.$store.dispatch("association/getUtilization", {
        from: formatDate(this.selectedWeek.start),
        to: formatDate(this.selectedWeek.end),
        force: true,
      });
    },
  },
  async mounted() {
    this.loading = true;
    this.selectedWeek = generateNext6Weeks(this.now)[0];
    // await this.$store.dispatch("employee/fetchWorkers");
    await this.$store.dispatch("enterprise_clients/fetchClients");
    await this.$store.dispatch("enterprise_core/fetchLimitedProjects");
    await this.$store.dispatch("association/getUtilization", {
      from: formatDate(this.selectedWeek.start),
      to: formatDate(this.selectedWeek.end),
    });
    this.loading = false;
  },
});
</script>

<style scoped lang="scss"></style>
