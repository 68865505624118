import { createApp } from "vue";
import App from "./App.vue";
import i18n from "@/plugins/i18n";
import cleave from "./directives/cleave";
import router from "./router";
import store from "./store";
import Buefy from "buefy";
import VueApexCharts from "vue3-apexcharts";
import "@/assets/scss/main.scss";
import {
  hourToShortWorkDayFormat,
  isNullOrUndefined,
  percentify,
  roundToNDecimals,
  roundToTwoDecimals,
  secondsToHoursAndMinutes,
  secondToShortWorkDayFormat,
  secondToTimeFormat,
  secondToWorkDayFormat,
  localFetch,
  localStore,
  ProjectsTab,
} from "./utils/util";
import dayjs from "dayjs";
import { DEBUG_LANGUAGE } from "@/utils/const";

const app = createApp(App);
app.use(store);
app.use(router);
app.use(i18n);
app.use(VueApexCharts);
app.mount("#app");

app.config.globalProperties.gapi = window.gapi;

app.use(Buefy, {
  defaultToastPosition: "is-bottom",
  defaultSnackbarPosition: "is-bottom",
  // defaultIconComponent: "vue-fontawesome",
  defaultIconPack: "fa-duotone",
  customIconPacks: {
    "fa-duotone": {
      sizes: {
        default: "lg",
        "is-small": "1x",
        "is-medium": "2x",
        "is-large": "3x",
      },
      iconPrefix: "fa-",
    },
  },
});
// app.use(VueApexCharts);
// app.use(vueSmoothScroll);
// Nem működik a paraméter átadás ha app szintű pluginként van behúzva a Vuelidate, mixinnel kell megoldani ahol kell (lásd többi)
// app.use(VueMask);
app.component("apexChart", VueApexCharts);
app.directive("cleave", cleave);

// app.config.globalProperties.$filters = {
//   percent(data) {
//     return percentify(data);
//   },
// };

// app.config.globalProperties.$filters = {
//   secondsToHoursAndMinutes(data) {
//     return secondsToHoursAndMinutes(data);
//   },
// };

// app.config.globalProperties.$filters = {
//   minutesToHoursAndMinutes(time) {
//     if (!time) {
//       return "";
//     }

//     let hours = Math.floor(time / 60);
//     let minutes = time % 60;
//     let formatted = `${hours} óra`;
//     if (minutes !== 0) {
//       formatted = formatted.concat(` ${minutes} perc`);
//     }
//     return formatted;
//   },
// };

app.config.globalProperties.$filterSecondToTimeFormat = (data) =>
  secondToTimeFormat(data);

app.config.globalProperties.$filterDateFormat = (date) =>
  date ? dayjs(date).format("YYYY. MM. DD") : "-";

// app.config.globalProperties.$filters = {
//   empty(data) {
//     return data ? data : "-";
//   },
// };

// Vue.filter("secondToWorkDayFormat", function (data) {
//   return secondToWorkDayFormat(data);
// });

app.config.globalProperties.$filterHourToShortWorkDayFormat = (data) =>
  hourToShortWorkDayFormat(data);

// Vue.filter("secondToShortWorkDayFormat", function (data) {
//   return secondToShortWorkDayFormat(data);
// });

app.config.globalProperties.$filterSecondToShortWorkDayFormat = (data) =>
  secondToShortWorkDayFormat(data);

// Vue.filter("empty", function (data) {
//   return data ? data : "-";
// });

// app.config.globalProperties.$filters = {
//   quarterStatus(status) {
//     return status ? "Lezárt" : "Aktív";
//   },
// };

app.config.globalProperties.$filterPhone = (phone) =>
  phone
    ? phone
        .replace(/\D/g, "")
        .replace(/(\d{2})(\d{2})(\d{3})(\d{4})/, "+$1 $2 $3 $4")
    : "-";

// app.config.globalProperties.$filters = {
//   boolean(boolean) {
//     return boolean ? "Igen" : "Nem";
//   },
// };

// app.config.globalProperties.$filters = {
//   shortFormMoney(value, decimals = 0) {
//     let divided = value;
//     let suffix = "";
//     if (Math.abs(value) > 1000000) {
//       divided = value / 1000000;
//       suffix = "m";
//     } else if (Math.abs(value) > 1000) {
//       divided = value / 1000;
//       suffix = "e";
//     }
//     return roundToNDecimals(divided, decimals) + suffix;
//   },
// };

app.config.globalProperties.$tf = function (key, params) {
  if (!key) return "_%404%_";
  let fallback = key; // default fallback
  // Split the key and the fallback value if '|' is used
  if (key.includes("|")) {
    [key, fallback] = key.split("|");
    key = key.trim();
    fallback = fallback.trim();
  }
  const translation = i18n.global.t(key, params);
  // If the translation is the same as the key, it means the translation doesn't exist
  // Skip if selected language is the debug key
  if (localFetch("stored_locale") !== DEBUG_LANGUAGE && translation === key) {
    // console.log("Missing token for key: " + key);
    return fallback;
  } else {
    return translation;
  }
};

app.config.globalProperties.$tcf = function (key, params) {
  if (!key) return "_%404%_";
  let fallback = key; // default fallback
  // Split the key and the fallback value if '|' is used
  if (key.includes("|")) {
    [key, fallback] = key.split("|");
    key = key.trim();
    fallback = fallback.trim();
  }
  const translation = this.$tc(key, params);
  // If the translation is the same as the key, it means the translation doesn't exist
  if (translation === key) {
    return fallback;
  } else {
    return translation;
  }
};

export default app;
