<template>
  <section class="section">
    <div class="container">
      <h1 class="title mb-0">
        {{ $tf("utilizationPage.title|Erőforrás menedzsment") }}
      </h1>
      <div class="w-40">
        <b-tabs
          v-model="activeTab"
          type="is-toggle"
          class="toggle-only"
          expanded
        >
          <b-tab-item :value="TABS.PROJECTS">
            <template #header>
              <span class="tab-item">
                {{ $tf("utilizationPage.tab.projects|Projektek") }}
              </span>
            </template>
          </b-tab-item>
          <b-tab-item :value="TABS.EMPLOYEES">
            <template #header>
              <span class="tab-item">
                {{ $tf("utilizationPage.tab.employees|Dolgozók") }}
              </span>
            </template>
          </b-tab-item>
          <b-tab-item :value="TABS.CONFLICTS">
            <template #header>
              <span class="tab-item">
                {{ $tf("utilizationPage.tab.conflicts|Konfliktusok") }}
              </span>
            </template>
          </b-tab-item>
        </b-tabs>
      </div>

      <div class="mt-6">
        <template v-if="activeTab === TABS.PROJECTS">
          <UtilizationProjects />
        </template>
        <template v-if="activeTab === TABS.EMPLOYEES">
          <UtilizationEmployees />
        </template>
        <template v-else-if="activeTab === TABS.CONFLICTS">
          <UtilizationConflict />
        </template>
      </div>
    </div>
  </section>
</template>

<script>
import { defineComponent } from "vue";
import UtilizationEmployees from "@/components/ralloc/UtilizationEmployees.vue";
import UtilizationConflict from "@/components/ralloc/UtilizationConflict.vue";
import UtilizationProjects from "@/components/ralloc/UtilizationProjects.vue";
import { RALLOC_TABS } from "@/utils/const";

export default defineComponent({
  name: "RallocMainPage",
  components: {
    UtilizationProjects,
    UtilizationConflict,
    UtilizationEmployees,
  },
  data() {
    return {
      TABS: RALLOC_TABS,
    };
  },
  computed: {
    activeTab: {
      get() {
        switch (this.$router.currentRoute.value.hash) {
          case RALLOC_TABS.HASH.PROJECTS:
            return RALLOC_TABS.PROJECTS;
          case RALLOC_TABS.HASH.EMPLOYEES:
            return RALLOC_TABS.EMPLOYEES;
          case RALLOC_TABS.HASH.CONFLICTS:
            return RALLOC_TABS.CONFLICTS;
          default:
            return RALLOC_TABS.EMPLOYEES;
        }
      },
      set(val) {
        switch (val) {
          case RALLOC_TABS.PROJECTS:
            this.$router.push({
              name: "RallocMainPage",
              hash: RALLOC_TABS.HASH.PROJECTS,
            });
            break;
          case RALLOC_TABS.EMPLOYEES:
            this.$router.push({
              name: "RallocMainPage",
              hash: RALLOC_TABS.HASH.EMPLOYEES,
            });
            break;
          case RALLOC_TABS.CONFLICTS:
            this.$router.push({
              name: "RallocMainPage",
              hash: RALLOC_TABS.HASH.CONFLICTS,
            });
            break;
          default:
            this.$router.push({
              name: "RallocMainPage",
              hash: RALLOC_TABS.HASH.EMPLOYEES,
            });
            break;
        }
      },
    },
  },
});
</script>

<style scoped lang="scss">
@import "~@/assets/scss/colors.scss";

.tab-item {
  font-weight: 800;
}
</style>
