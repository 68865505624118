const APP_COLORS = {
  LIGHT: {
    black: "#111111",
    "black-bis": "#121212",
    "black-ter": "#242424",
    "always-black": "#000",

    "grey-darker": "#363636",
    "grey-dark": "#4A4A4A",
    grey: "#7A7A7A",
    "grey-light": "#B5B5C3",
    "grey-lighter": "#CAD3DE",
    "grey-lightest": "#F7F8F9",

    "white-ter": "#F5F5F5",
    "white-bis": "#FAFAFA",
    white: "#FFFFFF",
    "always-white": "#FFFFFF",

    "custom-orange": "#E48228",
    yellow: "#FFC700",
    green: "#24C77B",
    "custom-green-light": "#6df1ad",
    turquoise: "#59C7C7",
    //cyan: "#3298DC",
    blue: "#4E75D9",
    purple: "#B541BF",
    red: "#F9504B",
    "forecast-primary": "#B541BF",
    "forecast-secondary": "#4E75D9",
    "forecast-tertiary": "#59C7C7",
    "custom-dark-blue": "#2F2E6B",
    "custom-light-blue": "#4E75D9",
    "custom-grayer-blue": "#677D99",
    "sidebar-text-color": "#2F2E6B",
    "sidebar-bg-color": "#FFFFFF",
    "custom-lighter-blue": "#d9e3fe",

    primary: "#4E75D9", // from blue
    info: "#4E75D9", // from blue
    "scheme-main": "#FFFFFF", // from white
    text: "#4A4A4A", // from grey-dark
    "text-strong": "#363636", // from grey-darker

    "custom-success": "#DCF7E3",
    "custom-text-success": "#009C56",
    "custom-warning": "#FEF0C7",
    "custom-text-warning": "#C64900",
    "custom-danger": "#FED9D8",
    "custom-text-danger": "#B81B10",
    "custom-fatal": "#4B0000",
    "custom-text-fatal": "#FFD1D1",
    "sidebar-toggle-bg-color": "#FFFFFF",
  },
  DARK: {
    black: "#EEEEEE", // pipa
    "black-bis": "#D8D8D8", // pipa
    "black-ter": "#D0D0D0", // pipa
    "always-black": "#000",

    "grey-darker": "#DFDFDF", // pipa
    "grey-dark": "#DCDCDC", // pipa
    grey: "#7A7A7A", // maradhat?
    "grey-light": "#272727", // pipa
    "grey-lighter": "#313131", // pipa
    "grey-lightest": "#404040", // pipa

    "white-ter": "#2A2A2A", // pipa
    "white-bis": "#2C2C2C", // pipa
    white: "#212124", // pipa
    "always-white": "#FFFFFF",

    "custom-orange": "#E48228",
    yellow: "#CF9700", // maradhat?
    green: "#04974B", // maradhat?
    "custom-green-light": "#6df1ad",
    turquoise: "#59C7C7", // maradhat?
    //cyan: "#3298DC", // nincs használva
    blue: "#4E75D9", // maradhat?
    purple: "#B541BF", // maradhat?
    red: "#C9201B", // maradhat?
    "forecast-primary": "#B541BF",
    "forecast-secondary": "#4E75D9",
    "forecast-tertiary": "#59C7C7",
    "custom-dark-blue": "#FFFFFF", // pipa
    "custom-light-blue": "#4E75D9", // maradhat?
    "custom-grayer-blue": "#9BABBF", // pipa
    "sidebar-text-color": "#FFFFFF", // pipa
    "sidebar-bg-color": "#111111", // pipa
    "custom-lighter-blue": "#4E75D9",

    primary: "#4E75D9", // from blue
    info: "#4E75D9", // from blue
    "scheme-main": "#212124", // from white
    text: "#DCDCDC", // from grey-dark
    "text-strong": "#DFDFDF", // from grey-darker

    "custom-success": "#DCF7E3",
    "custom-text-success": "#009C56",
    "custom-warning": "#FEF0C7",
    "custom-text-warning": "#C64900",
    "custom-danger": "#FED9D8",
    "custom-text-danger": "#B81B10",
    "custom-fatal": "#4B0000",
    "custom-text-fatal": "#FFD1D1",
    "sidebar-toggle-bg-color": "#404040",
  },
};

module.exports.app_colors = APP_COLORS;
