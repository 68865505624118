<template>
  <div class="billableModal">
    <h3 class="title">
      <template v-if="editedActivity">
        {{
          $tf("billableForm.base.editTitle|{name} szerkesztése", {
            name: editedActivity.name,
          })
        }}
      </template>
      <template v-else>
        {{ $tf("billableForm.base.newTitle|Aktivitás hozzáadása") }}
      </template>
    </h3>
    <div class="columns mt-5">
      <div class="column is-two-fifths">
        <b-field
          :label="$tf('billableForm.base.name|Aktivitás neve')"
          label-position="on-border"
        >
          <b-input v-model="activityEdit.name" :expanded="true"></b-input>
        </b-field>
      </div>
      <div class="column">
        <b-field
          :label="$tf('billableForm.base.identifier|Aktivitás azonosítója')"
          label-position="on-border"
        >
          <b-input v-model="activityEdit.identifier" :expanded="true"></b-input>
        </b-field>
      </div>
      <div class="column">
        <b-field expanded>
          <vuelidated-form :validations="v$.activityEdit.invoiceDate">
            <template v-slot="{ errors, invalid }">
              <b-field
                :expanded="true"
                :message="v$.activityEdit.invoiceDate.$error ? errors[0] : null"
                :type="{ 'is-danger': invalid }"
                label-for="invoiceDate"
                label-position="on-border"
              >
                <template #label>
                  {{ $tf("billableForm.base.invoiceDate|Elszámolás dátuma") }}
                  <span class="has-text-danger">*</span>
                </template>
                <f-datepicker
                  id="invoiceDate"
                  ref="invoiceDate"
                  v-model="activityEdit.invoiceDate"
                  :placeholder="
                    $tf('billableForm.dates.date.placeholder|Elszámolás dátuma')
                  "
                  class="has-blue-icon"
                  expanded
                  icon="calendar-day"
                  name="invoiceDate"
                  @blur="v$.activityEdit.invoiceDate.$touch()"
                />
              </b-field>
            </template>
          </vuelidated-form>
        </b-field>
      </div>
      <div class="column">
        <b-field
          :label="$tf('billableForm.base.date|Státusz')"
          label-position="on-border"
        >
          <b-select v-model="activityEdit.status">
            <option
              v-for="status in Object.keys(SUPPORT_ACTIVITY_STATUS)"
              :key="status"
              :value="status"
            >
              {{ SUPPORT_ACTIVITY_STATUS[status] }}
            </option>
          </b-select>
        </b-field>
      </div>
    </div>
    <div class="columns">
      <div class="column is-three-fifths">
        <b-field
          :label="$tf('billableForm.base.description|Leírás')"
          label-position="on-border"
        >
          <b-input v-model="activityEdit.description" type="textarea"></b-input>
        </b-field>
      </div>
    </div>
    <div class="columns">
      <div class="column">
        <b-field :label="$tf('billableForm.base.type|Aktivitás típusa')">
          <b-radio
            v-model="billableFinancials.type"
            class="mr-5"
            name="type"
            native-value="FIX"
          >
            {{ $tf("billableForm.types.fix|Fix áras aktivitás") }}
          </b-radio>
          <b-radio
            v-model="billableFinancials.type"
            name="type"
            native-value="TM"
          >
            {{ $tf("billableForm.types.tm|T&M alapú aktivitás") }}
          </b-radio>
        </b-field>
      </div>
    </div>
    <h4 class="title">
      {{ $tf("billableForm.base.financialDetails|Elszámolási adatok") }}
    </h4>
    <div class="columns">
      <div class="column is-one-fifth">
        <money-input
          v-model="billableFinancials.days"
          :label="$tf('billableForm.base.hours|Allokált munkaidő')"
          day
          label-position="on-border"
        />
      </div>
      <div v-if="billableFinancials.type === 'FIX'" class="column is-one-fifth">
        <money-input
          v-model="billableFinancials.income"
          :disabled="disableFee"
          :label="$tf('billableForm.base.income|Bevétel')"
          label-position="on-border"
        />
      </div>
      <div v-if="billableFinancials.type === 'FIX'" class="column is-one-fifth">
        <money-input
          v-model="billableFinancials.budget"
          :label="$tf('billableForm.base.budget|Büdzsé')"
          label-position="on-border"
        />
      </div>
    </div>
    <div v-if="billableFinancials.type === 'TM'" class="columns">
      <div class="column">
        <b-field :label="$tf('billableForm.base.type|Aktivitás típusa')">
          <div class="is-block">
            <div>
              <b-checkbox
                v-model="competencyHourlyRate"
                :disabled="
                  selectedCompetencies.length === 0 &&
                  unselectedCompetencies.length === 0
                "
                >{{
                  $tf(
                    "billableForm.base.hourlyFeePerCompetency|Órabérek megadása kompetenciánként"
                  )
                }}
              </b-checkbox>
            </div>
            <div>
              <b-checkbox
                v-if="competencyHourlyRate && this.seniorities.length !== 0"
                v-model="seniorityHourlyRate"
                >{{
                  $tf(
                    "billableForm.base.hourlyFeePerSeniority|Senioritások megadáas"
                  )
                }}
              </b-checkbox>
            </div>
          </div>
        </b-field>
        <div
          v-if="competencyHourlyRate"
          :style="{
            overflow:
              seniorityHourlyRate && selectedCompetencies.length > 0
                ? 'auto'
                : 'visible',
          }"
          class="mb-4"
        >
          <div
            v-if="seniorityHourlyRate && selectedCompetencies.length > 0"
            :style="{
              display: 'grid',
              'grid-template-columns': '3fr 14fr 2fr',
              'column-gap': '1 rem',
            }"
          >
            <div></div>
            <div class="columns mb-0">
              <div
                v-for="seniority in seniorities"
                :key="seniority.id"
                class="column pt-2 pb-1"
              >
                {{ seniority.name }}
              </div>
            </div>
            <div></div>
          </div>

          <div
            v-for="competency in selectedCompetencies"
            :key="competency.id"
            :style="{
              display: 'grid',
              'grid-template-columns': '3fr 14fr 2fr',
              'column-gap': '1 rem',
            }"
            class="my-1"
          >
            <div class="column is-1 is-flex is-align-items-center mr-4">
              {{ competency.name }}
            </div>
            <template v-if="seniorityHourlyRate">
              <div class="columns mb-0">
                <div
                  v-for="seniority in seniorities"
                  :key="seniority.id"
                  class="column py-1"
                >
                  <money-input
                    v-model="hourlyFees[competency.id][seniority.id]"
                    expanded
                  />
                </div>
              </div>
            </template>
            <div v-else>
              <money-input v-model="hourlyFees[competency.id]" expanded />
            </div>
            <div
              :class="{ 'is-align-items-end': !seniorityHourlyRate }"
              class="is-flex is-justify-content-end"
            >
              <b-button
                icon-left="trash"
                type="is-danger"
                @click="removeSelectedCompetency(competency.id)"
              ></b-button>
            </div>
          </div>
        </div>
        <div class="columns">
          <div class="column is-two-fifths">
            <money-input
              v-if="!competencyHourlyRate && !seniorityHourlyRate"
              v-model="billableFinancials.averageHourlyFee"
              :label="$tf('billableForm.base.hourlyFee|Általános órabér')"
              expanded
              label-position="on-border"
            />
            <b-dropdown
              v-if="competencyHourlyRate"
              :disabled="unselectedCompetencies.length === 0"
              aria-role="list"
              expanded
              max-height="120"
              scrollable
            >
              <template #trigger>
                <b-button
                  :label="
                    $tf(
                      'billableForm.competencies.placeholder|Kompetencia hozzáadása...'
                    )
                  "
                  expanded
                  icon-right="chevron-down"
                />
              </template>

              <b-dropdown-item
                v-for="competency in unselectedCompetencies"
                :key="competency.id"
                aria-role="listitem"
                @click="addSelectedCompetency(competency)"
                >{{ competency.name }}
              </b-dropdown-item>
            </b-dropdown>
          </div>
        </div>
      </div>
    </div>
    <div v-if="!isSelfTrackingMode" class="mb-4">
      <external-issue-targeting-form
        v-model="activityEdit.externalIssueTargeting"
        :prefix="prefix"
        :project-identifier="projectIdentifier"
        :project-type="PROJECT_TYPES.SUPPORT"
        :value="activityEdit.externalIssueTargeting"
      />
    </div>

    <div class="columns">
      <div class="column is-one-fifth is-offset-four-fifths">
        <b-button
          :expanded="true"
          icon-left="save"
          type="is-info"
          @click="createActivity"
          >Mentés
        </b-button>
      </div>
    </div>
  </div>
</template>

<script>
import ExternalIssueTargetingForm from "@/components/enterprise/targeting/ExternalIssueTargetingForm.vue";
import {
  ISSUE_TRACKING_SYSTEM,
  PROJECT_TYPES,
  SUPPORT_ACTIVITY_STATUS,
  USER_INDICATOR_TYPE_ENUM,
} from "@/utils/const";
import { correctWithTimezoneOffset, deepCopy } from "@/utils/util";
import { mapGetters } from "vuex";
import MoneyInput from "@/components/module/input/MoneyInput.vue";
import VuelidatedForm from "@/components/module/input/VuelidatedForm.vue";
import { useVuelidate } from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import FDatepicker from "@/components/module/input/FDatepicker.vue";

export default {
  name: "BillableForm",
  components: {
    FDatepicker,
    VuelidatedForm,
    MoneyInput,
    ExternalIssueTargetingForm,
  },
  setup: () => ({ v$: useVuelidate() }),
  emits: ["activity-submitted"],
  props: {
    projectIdentifier: {
      type: String,
      required: true,
    },
    prefix: {
      type: String,
      required: true,
    },
    editedActivity: {
      type: Object,
      required: false,
    },
    disableFee: {
      type: Boolean,
      default: false,
    },
  },
  validations: {
    activityEdit: {
      invoiceDate: {
        required,
      },
    },
  },
  computed: {
    isSelfTrackingMode() {
      return this.issueTrackingSystem === ISSUE_TRACKING_SYSTEM.SELF.enum;
    },
    unselectedCompetencies() {
      return (
        this.indicators?.filter(
          (indicator) =>
            indicator.type === USER_INDICATOR_TYPE_ENUM.COMPETENCY &&
            !this.selectedCompetencies.includes(indicator)
        ) ?? []
      );
    },
    seniorities() {
      return (
        this.indicators?.filter(
          (indicator) => indicator.type === USER_INDICATOR_TYPE_ENUM.SENIORITY
        ) ?? []
      );
    },
    ...mapGetters({
      indicators: "user_indicator/indicators",
      issueTrackingSystem: "uiConfigStore/getIssueTrackingSystem",
    }),
  },
  data() {
    return {
      activityEdit: {
        status: "ONGOING",
        externalIssueTargeting: {},
      },
      billableFinancials: {
        type: "FIX",
        days: null,
        income: null,
        budget: null,
        averageHourlyFee: null,
      },
      competencyHourlyRate: false,
      seniorityHourlyRate: false,
      selectedCompetencies: [],
      hourlyFees: {},
      PROJECT_TYPES,
      SUPPORT_ACTIVITY_STATUS,
    };
  },
  watch: {
    seniorityHourlyRate(newVal) {
      if (newVal) {
        this.selectedCompetencies.forEach((competency) => {
          if (
            this.hourlyFees[competency.id] == null ||
            typeof this.hourlyFees[competency.id] !== "object"
          ) {
            this.hourlyFees[competency.id] = {};
          }
        });
      } else {
        this.selectedCompetencies.forEach((competency) => {
          this.hourlyFees[competency.id] = null;
        });
      }
    },
  },
  mounted() {
    if (this.editedActivity) {
      this.activityEdit = deepCopy(this.editedActivity);
      this.billableFinancials.days =
        this.editedActivity.financials.allocatedDays;
      if (this.editedActivity.billableHourlyFees) {
        this.billableFinancials.type = "TM";
        this.billableFinancials.averageHourlyFee =
          this.editedActivity.billableHourlyFees.averageHourlyFee;
        this.competencyHourlyRate =
          this.editedActivity.billableHourlyFees.hourlyFees.length > 0;
        this.seniorityHourlyRate =
          this.editedActivity.billableHourlyFees.hourlyFees.every(
            (elem) => elem.seniorityFees.length > 0
          );
        this.editedActivity.billableHourlyFees.hourlyFees.forEach((fee) => {
          this.selectedCompetencies.push(
            this.indicators.find(
              (indicator) => indicator.id === fee.competencyId
            )
          );
          this.hourlyFees[fee.competencyId] =
            fee.hourlyFee ??
            fee.seniorityFees.reduce(
              (prev, cur) => ({ ...prev, [cur.seniorityId]: cur.hourlyFee }),
              {}
            );
        });
      } else {
        this.billableFinancials.type = "FIX";
        this.billableFinancials.income = this.editedActivity.financials.income;
        this.billableFinancials.budget = this.editedActivity.financials.income;
      }
      if (this.activityEdit.invoiceDate) {
        this.activityEdit.invoiceDate = new Date(this.activityEdit.invoiceDate);
      }
    }
  },
  methods: {
    addSelectedCompetency(competency) {
      this.hourlyFees[competency.id] = this.seniorityHourlyRate ? {} : null;
      this.selectedCompetencies.push(competency);
    },
    removeSelectedCompetency(id) {
      delete this.hourlyFees[id];
      this.selectedCompetencies = this.selectedCompetencies.filter(
        (competency) => competency.id !== id
      );
    },
    async createActivity() {
      if (this.activityType === "BILLABLE" && !this.activityEdit.invoiceDate) {
        this.$buefy.toast.open({
          duration: 5000,
          message: `Elszámolás dátum megadása <strong>KÖTELEZŐ</strong>!`,
          position: "is-bottom",
          type: "is-danger",
        });
        return;
      }
      const request = deepCopy(this.activityEdit);
      request.type = "BILLABLE";
      if (request.invoiceDate) {
        request.invoiceDate = correctWithTimezoneOffset(
          new Date(request.invoiceDate)
        );
      }
      if (this.isSelfTrackingMode) {
        delete request.externalIssueTargeting;
      }
      request.billableFinancials = this.billableFinancials;
      if (request.billableFinancials.type === "TM") {
        delete request.billableFinancials.income;
        delete request.billableFinancials.budget;
        if (this.competencyHourlyRate) {
          delete request.billableFinancials.averageHourlyFee;
          request.billableFinancials.hourlyFees = this.selectedCompetencies.map(
            (competency) => {
              let c = {};
              c.competencyId = competency.id;
              if (this.seniorityHourlyRate) {
                c.seniorityFees = this.seniorities.map((seniority) => {
                  return {
                    seniorityId: seniority.id,
                    hourlyFee: this.hourlyFees[competency.id][seniority.id],
                  };
                });
              } else {
                c.hourlyFee = this.hourlyFees[competency.id];
              }
              return c;
            }
          );
        }
      }
      if (this.editedActivity) {
        await this.$store.dispatch("support_projects/updateActivity", request);
      } else {
        await this.$store.dispatch("support_projects/createActivity", {
          identifier: this.projectIdentifier,
          activity: request,
        });
      }
      this.$emit("activity-submitted");
    },
  },
};
</script>

<style scoped>
.billableModal {
  overflow-y: auto !important;
  overflow-x: hidden !important;
  padding-right: 0.5rem;
  max-height: 640px;
}
</style>
