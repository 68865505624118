<template>
  <div>
    <div class="is-flex is-flex-direction-column has-gap-4">
      <normal-project-number-cards
        v-if="projectData.status === PROJECT_STATUS_ENUM.ONGOING"
        :project-details="projectDetails"
        :project-data="projectData"
      />
      <div
        v-if="projectData.status === PROJECT_STATUS_ENUM.ONGOING"
        class="status-tables"
      >
        <project-focus :project-data="projectData" />
        <div class="status-tables-table">
          <ForecastSinceStartStatusOverview
            :project-data="projectData"
            :project-details="projectDetails"
          ></ForecastSinceStartStatusOverview>
        </div>
        <div class="status-tables-table">
          <ForecastFutureStatusOverview
            :project-data="projectData"
            :project-details="projectDetails"
          ></ForecastFutureStatusOverview>
        </div>
      </div>
      <ForecastWholeStatusOverview
        :project-data="projectData"
        :project-details="projectDetails"
      ></ForecastWholeStatusOverview>

      <normal-project-timeline-cards
        v-if="projectData.status === PROJECT_STATUS_ENUM.ONGOING"
        :project-data="projectData"
        :project-details="projectDetails"
      />
      <div
        class="has-text-centered"
        v-if="projectData.status !== PROJECT_STATUS_ENUM.ONGOING"
      >
        <h5>
          <span class="is-italic">{{
            $tf(
              "projectPage.forecastAndProgressNotAvailable|A forecast és a folyamati adatok nem érhetőek el."
            )
          }}</span>
          <b-tooltip
            :auto-close="['outside', 'escape']"
            :triggers="['click']"
            type="is-info"
            :label="
              $tf(
                'projectPage.forecastAndProgressNotAvailable.reason|Forecast adatok kizárólag folyamatban lévő projekteknél érhetőek el'
              )
            "
          >
            <p class="is-dotted-underlined is-italic has-text-blue">
              {{
                $tf("projectPage.forecastAndProgressNotAvailable.why|Miért?")
              }}
            </p>
          </b-tooltip>
        </h5>
      </div>
    </div>
  </div>
</template>

<script>
import {
  deepCopy,
  formatDate,
  formatDateTime,
  formatDayWithMonthNameShort,
  moneyify,
  percentify,
} from "@/utils/util";
import ForecastSinceStartStatusOverview from "@/components/enterprise/project/module/forecast/ForecastSinceStartStatusOverview.vue";
import ForecastFutureStatusOverview from "@/components/enterprise/project/module/forecast/ForecastFutureStatusOverview.vue";
import ForecastWholeStatusOverview from "@/components/enterprise/project/module/forecast/ForecastWholeStatusOverview.vue";
import NormalProjectNumberCards from "@/components/enterprise/ProjectDetails/NormalProjectNumberCards.vue";
import NormalProjectTimelineCards from "@/components/enterprise/ProjectDetails/NormalProjectTimelineCards.vue";
import ProjectFocus from "@/components/enterprise/project/module/ProjectFocus.vue";
import { PROJECT_STATUS_ENUM } from "@/utils/const";

export default {
  name: "ProjectStatus",
  props: {
    projectData: {
      type: Object,
      required: true,
    },
    projectDetails: {
      type: Object,
      required: true,
    },
    bars: {
      type: Object,
      required: true,
    },
    forecastBars: {
      type: Object,
      required: true,
    },
    projectIdentifier: {
      type: String,
      required: true,
    },
    employees: {
      type: Array,
      required: true,
    },
  },
  components: {
    NormalProjectTimelineCards,
    NormalProjectNumberCards,
    ForecastFutureStatusOverview,
    ForecastSinceStartStatusOverview,
    ForecastWholeStatusOverview,
    ProjectFocus,
  },
  data() {
    return {
      PROJECT_STATUS_ENUM,
      moneyify,
      formatDate,
      today: formatDate(new Date()),
      jegyTab: 0,
      statusTab: 0,
      dueDate: this.projectDetails.dueDate,
      selectedTig: null,
    };
  },
  computed: {
    sortedTigs() {
      let sorted = deepCopy(this.projectDetails.tigDetails);
      if (!sorted) return [];
      return sorted.sort((a, b) =>
        a.identifier > b.identifier ? 1 : b.identifier > a.identifier ? -1 : 0
      );
    },
    currentTigs() {
      return this.projectData?.tigDatas?.filter(
        (tig) => tig.status === "PENDING"
      );
    },
    isReady() {
      return this.today > this.projectData.startDate;
    },
    todayPercent() {
      const startDate = new Date(this.projectData.startDate);
      const endDate = new Date(this.projectData.dueDate);
      const today = new Date();
      const difference =
        ((today.getTime() - startDate.getTime()) /
          (endDate.getTime() - startDate.getTime())) *
        100;

      if (today.getTime() < startDate.getTime()) {
        return "-2.5%";
      } else if (today.getTime() > endDate.getTime()) {
        return "105%";
      } else {
        return `${percentify(difference / 100)}`;
      }
    },
    monthDays() {
      const startDate = new Date(this.projectData.startDate);
      const endDate = new Date(this.projectData.dueDate);
      const monthDiff =
        endDate.getMonth() -
        startDate.getMonth() +
        12 * (endDate.getFullYear() - startDate.getFullYear()) +
        1;

      const aDay = 24 * 60 * 60 * 1000;
      let ranges = [];
      ranges.push(startDate);

      for (let i = startDate.getTime(), end = endDate.getTime(); i <= end; ) {
        const first = new Date(i);
        let last = new Date(first.getFullYear(), first.getMonth() + 1, 0);
        if (endDate.getTime() < last.getTime()) {
          last = endDate;
        }
        let middle;
        if (monthDiff < 5) {
          middle = new Date((first.getTime() + last.getTime()) / 2);
          if ((middle - first) / (1000 * 3600 * 24) > 8) {
            ranges.push(middle);
          }
          ranges.push(last);
        } else {
          ranges.push(last);
        }
        i = last.getTime() + aDay;
      }

      const timeLineDays = [];
      if (monthDiff > 12) {
        ranges.filter((e, i) => {
          if (i % 2 === 2 - 1) {
            timeLineDays.push(formatDayWithMonthNameShort(e));
          }
        });
      } else {
        ranges.forEach((item) => {
          timeLineDays.push(formatDayWithMonthNameShort(item));
        });
      }

      return timeLineDays;
    },
  },
  methods: {
    percentify,
    formatDateTime,
    findTigData(tigIdentifier) {
      for (let t in this.projectData.tigDatas) {
        if (this.projectData.tigDatas[t].identifier === tigIdentifier)
          return this.projectData.tigDatas[t];
      }
      return {};
    },
    timeBar(obj, field, optValue) {
      let max = Math.max(
        obj.sumSpentMd,
        obj.sumEstimateMd,
        this.projectData.commitment
      );
      if (max <= 0) {
        max = 1;
      }
      if (optValue) {
        return (optValue / max) * 100;
      } else {
        return ((obj[field] ? obj[field] : 0) / max) * 100;
      }
    },
    moneyBar(key, field) {
      return (
        ((this.bars[key][field] ? this.bars[key][field] : 0) /
          this.bars[key].max) *
        100
      );
    },
    pieIssueStatusNumberOptions() {
      return {
        chart: {
          type: "pie",
          width: 200,
        },
        labels: ["ToDo", "InProgress", "Done"],
        colors: ["#bbbbbb", "#3273dc", "#48c774"],
        plotOptions: {
          chart: {
            width: 200,
          },
          legend: {
            position: "bottom",
          },
        },
      };
    },
    pieIssueStatusEstimateOptions() {
      return {
        chart: {
          type: "pie",
          width: 200,
        },
        labels: ["ToDo", "InProgress", "Done"],
        colors: ["#bbbbbb", "#3273dc", "#48c774"],
      };
    },
    pieIssueTypeOptions() {
      return {
        chart: {
          type: "pie",
          width: 200,
        },
        labels: ["Development", "General Assignment", "Bug", "Other"],
        colors: ["#e5692c", "#cccccc", "#f14668", "#111111"],
      };
    },
    issueStatusPie(details) {
      return [
        details.waitingIssueCount,
        details.proceedingIssueCount,
        details.doneIssueCount,
      ];
    },
    issueStatusMoneyPie(details) {
      return [
        details.waitingEstimateMd,
        details.proceedingEstimateMd,
        details.doneEstimateMd,
      ];
    },
    issueCountPie(details) {
      return [
        details.devIssueCount,
        details.generalIssueCount,
        details.bugIssueCount,
        details.otherIssueCount,
      ];
    },
    getTextClass(value, type) {
      switch (type) {
        case "log":
          return value > 0
            ? value > 28800
              ? "is-danger"
              : "is-warning"
            : "is-success";
        case "commitment":
          const dueDate = new Date(this.projectData.dueDate);
          const valDate = new Date(value);
          return valDate.getTime() > dueDate.getTime()
            ? "is-danger"
            : "is-success";
        case "estimation":
          return value > 0 ? "is-danger" : "is-success";
      }
    },
    getEmployeeNameById(employeeId) {
      return this.allEmployees.find((e) => e.id === employeeId)?.name;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/scss/colors.scss";
@import "~@/assets/scss/media-queries.scss";

//@include xxl-min {
//  .status-tables {
//    display: flex;
//    flex-direction: row;
//    &-table {
//      flex: 1 0 50%;
//    }
//  }
//}

.timeline {
  h5 {
    color: $custom-dark-blue;
    font-size: 1rem;
  }
  &-times {
    max-width: 460px;
  }
  &-dates {
    max-width: 340px;
    padding-top: 2.875rem;
    .column {
      &:first-child {
        position: relative;
        &::before {
          content: "";
          position: absolute;
          right: 0;
          top: 1.25rem;
          bottom: calc(0.313rem + 1rem);
          border-right: 1px solid $grey-lightest;
        }
      }
    }
  }
  &-date {
    padding: 0.625rem;
    max-width: 160px;
    margin: 0 auto;

    strong {
      color: $custom-dark-blue;
    }
    span {
      color: $custom-grayer-blue;
    }
  }
  &-line {
    margin: 4rem 10% 0 0;
    font-size: 0.875rem;
    &.not-ready {
      margin-left: 5%;
      .timeline-line-start {
        border-radius: 0.5rem 0 0 0.5rem;
        background: $grey-lightest;
        opacity: 0.7;
        left: -6%;
      }
    }
    &-holder {
      height: 1rem;
      background-color: $grey-lightest;
    }
    &-start {
      background: linear-gradient(270deg, #7b61ff 14.55%, #0aa6fe 75.13%);
      border-radius: 0 0.5rem 0.5rem 0;
      height: 1rem;
      position: absolute;
      left: 0;
      top: 0;
    }
    &-end {
      background: repeating-linear-gradient(
        -45deg,
        $red,
        $red 6px,
        $white 6px,
        $white 12px
      );
      border-radius: 0 0.5rem 0.5rem 0;
      height: 1rem;
      position: absolute;
      left: calc(100% + 2px);
      top: 0;
      width: 7%;
    }
    &-end-text,
    &-today-text {
      background-color: $custom-light-blue;
      width: 2px;
      height: 2.5rem;
      margin-top: 0.813rem;
      position: absolute;
      top: -1.5rem;
      color: $custom-grayer-blue;
      span {
        color: $custom-light-blue;
        font-weight: 500;
        &.is-danger {
          color: $red;
        }
      }
    }
    &-today-text {
      top: -2.6rem;
      height: 3.6rem;
      z-index: 10;
    }
    &-end-text {
      left: 100%;
      margin-left: 0;
    }
    .is-absolute {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      white-space: nowrap;
      top: -1.5rem;
    }
  }
  &-months {
    font-size: 0.875rem;
    font-weight: 500;
    &-day {
      margin-left: -20px;
    }
    .column {
      position: relative;
      padding: 1.563rem 0 2.5rem 0;
      &::before {
        content: "";
        position: absolute;
        top: 0;
        background-color: rgba($custom-grayer-blue, 0.5);
        width: 2px;
        height: 0.813rem;
        left: 0;
      }
      &:first-child {
      }
      &:last-child {
        position: absolute;
        left: 100%;
        white-space: nowrap;
      }
    }
  }
}
.is-danger {
  color: $red !important;
}
.is-success {
  color: $green !important;
}
.project-tig-tabs {
  background: $grey-lightest;
  margin-left: -3.5em;
  margin-right: -3.5em;
  margin-bottom: -2em;
}

.progress-history-grid {
  display: grid;
  grid-template-columns: 2fr 1fr 3fr 4fr;
  grid-gap: 8px;
  max-width: 792px;
}
</style>
