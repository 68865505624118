<template>
  <div class="scrollable-modal-card">
    <div class="container">
      <header class="modal-card-head">
        <h1 v-if="newUser" class="title">
          {{ $tf("userModal.newTitle|Új munkatárs felvétele") }}
        </h1>
        <h1 v-else class="title">
          {{
            $tf("userModal.editTitle|{name} módosítása", {
              name: userData.name,
            })
          }}
        </h1>
      </header>
      <section class="modal-card-body">
        <user-form
          ref="userForm"
          :new-user="newUser"
          :user-data="userData"
        ></user-form>
      </section>
      <footer class="modal-card-footer">
        <b-field class="ml-auto">
          <b-button class="mr-3" @click="cancel">
            {{ $tf("userModal.cancel|Mégse") }}
          </b-button>
          <b-field>
            <b-button type="is-info" @click="submit">
              {{ $tf("userModal.save|Mentés") }}
            </b-button>
          </b-field>
        </b-field>
      </footer>
    </div>
  </div>
</template>

<script>
import UserForm from "@/components/census/user/UserForm";
import {
  correctWithTimezoneOffset,
  dateToMonthDay,
  deepCopy,
} from "@/utils/util";
import { getCurrentInstance } from "vue";
import { mapGetters } from "vuex";
import { useVuelidate } from "@vuelidate/core";

export default {
  name: "UserModal",
  setup: () => {
    const instance = getCurrentInstance();
    return {
      v$: useVuelidate(),
      instance: instance,
    };
  },
  components: { UserForm },
  props: {
    newUser: {
      type: Boolean,
      required: true,
    },
    userData: {
      type: Object,
      required: false,
      default: null,
    },
    superadminCreate: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    ...mapGetters({
      env: "env/getField",
    }),
  },
  methods: {
    async submit() {
      let userForm = this.$refs.userForm;
      userForm.v$.$touch();

      let phoneInput = userForm.$refs.phoneInput;
      phoneInput.v$.$touch();

      let contactInput = userForm.$refs.contactInput;
      contactInput.v$.$touch();

      let changeEntryComponents = userForm.$refs.entries;
      let entryValues = [];

      if (
        !userForm.v$.$invalid &&
        !phoneInput.v$.$invalid &&
        !contactInput.v$.$invalid
      ) {
        if (changeEntryComponents) {
          for (let index in changeEntryComponents) {
            let changeEntryComponent = changeEntryComponents[index];
            if (await changeEntryComponent.isInvalid()) {
              return;
            }
            entryValues.push(changeEntryComponent.getValues());
          }
        }

        const request = deepCopy(userForm.userEdit);

        if (request.rolesEdit) {
          request.roles = request.rolesEdit.map((r) => r.value).join(",");
        }
        delete request.rolesEdit;

        if (request.competenciesEdit) {
          request.competencies = request.competenciesEdit.map((c) => {
            return { id: c.value };
          });
        }
        delete request.competenciesEdit;

        if (request.startDate) {
          request.startDate = correctWithTimezoneOffset(
            new Date(request.startDate)
          );
        }

        if (request.birthDate) {
          request.birthDate = dateToMonthDay(request.birthDate);
        }

        if (request.endDate) {
          request.endDate = correctWithTimezoneOffset(
            new Date(request.endDate)
          );
        }

        request.phone = request.phone?.replace(/\s/g, "");
        request.contact = request.contact?.replace(/\s/g, "");

        const avatarForm = new FormData();
        if (request.avatar) {
          avatarForm.append("file", request.avatar);
          avatarForm.append("fileName", request.avatar.name);
          avatarForm.append("documentType", "USER_AVATAR");
        }

        if (userForm.newUser) {
          request.tenantId = this.tenantId;
          request.lang = this.env("DEFAULT_LANGUAGE");

          if (this.superadminCreate) {
            await this.$store
              .dispatch("employee/superadminCreate", {
                request,
                avatarForm,
              })
              .then(async () => {
                await this.close();
              });
          } else {
            await this.$store
              .dispatch("employee/create", {
                createUserRequest: request,
                createJiraUser: userForm.createsJiraUser,
                avatarForm,
              })
              .then(async () => {
                await this.close();
              });
          }
        } else {
          request.id = userForm.userId;
          await this.$store
            .dispatch("employee/update", {
              request,
              avatarForm,
            })
            .then(async () => {
              await this.close();
            });
        }
      }
    },
    async close() {
      this.$emit("fetch");
    },
    cancel() {
      this.$emit("close");
    },
  },
};
</script>

<style lang="scss" scoped>
.animation-content {
  .scrollable-modal-card {
    h3 {
      &:not(:first-child) {
        margin-top: 1rem;
      }

      margin-bottom: 1rem;
    }
  }
}
</style>
