<template>
  <div class="mt-2 workers mx-0" v-if="employeeData && employeeData.length">
    <div class="is-flex is-align-items-center">
      <h3 class="title">
        {{
          $tf(
            "projectPage.workersList.title|Dolgozói, szerepköri és szenioritási lebontás"
          )
        }}
        <b-tooltip
          type="is-info"
          :triggers="['click']"
          :auto-close="['outside', 'escape']"
        >
          <template v-slot:content>
            <div class="content tooltip">
              <p>
                {{
                  $tf(
                    "projectPage.workersList.info|A projektre logolt idők és költségek megoszlása a résztvevők alapján. A megjelenített ráta átlag, ha a dolgozó besorolása esetleg a projekt közben változott. A projektre vonatkozó esetleges költségfelülírás az itteni számításra is érvényes. A költség kiszámítása itt is percre pontos kerekítéssel van kiszámolva."
                  )
                }}
              </p>
            </div>
          </template>
          <b-icon icon="info-circle" />
        </b-tooltip>
      </h3>
      <div class="ml-auto">
        <b-tabs v-model="detailsTab" type="is-toggle">
          <b-tab-item>
            <template #header>
              <div class="mx-4">
                {{
                  $tf(
                    "projectPage.workersList.detailTabs.byRoles|Szerepkör szerint"
                  )
                }}
              </div>
            </template>
          </b-tab-item>

          <b-tab-item>
            <template #header>
              <div class="mx-4">
                {{
                  $tf(
                    "projectPage.workersList.detailTabs.byEmployees|Dolgozók szerint"
                  )
                }}
              </div>
            </template>
          </b-tab-item>

          <b-tab-item>
            <template #header>
              <div class="mx-4">
                {{
                  $tf(
                    "projectPage.workersList.detailTabs.bySeniorities|Szenioritás szerint"
                  )
                }}
              </div>
            </template>
          </b-tab-item>
        </b-tabs>
      </div>
    </div>

    <div class="content">
      <template v-if="detailsTab === 0">
        <div class="employee-graph">
          <template v-if="noDataForRoles">
            <section class="section">
              <div class="content has-text-grey has-text-centered">
                <p>
                  <b-icon icon="exclamation-triangle" size="is-large"></b-icon>
                </p>
                <p>
                  {{
                    $tf(
                      "projectPage.workersList.byRoles.atLeastOneRoleNeeded|Legalább egy szerepkört ki kell jelölni!"
                    )
                  }}
                </p>
              </div>
            </section>
          </template>
          <template v-else>
            <div class="columns">
              <div class="column is-half">
                <div class="is-inline-flex is-align-items-center">
                  <b-icon icon="clock-rotate-left" />
                  <h3 class="heading">
                    {{
                      $tf(
                        "projectPage.workersList.byRoles.byTimelogs|Idő szerint"
                      )
                    }}
                  </h3>
                </div>
                <apex-chart
                  type="bar"
                  height="300"
                  :options="monthlyBarChartOptionsByRoleTime"
                  :series="monthlyBarChartDataByRoles"
                ></apex-chart>
              </div>
              <div class="column is-half">
                <div class="is-inline-flex is-align-items-center">
                  <b-icon icon="wallet" />
                  <h3 class="heading">
                    {{
                      $tf(
                        "projectPage.workersList.byRoles.byCost|Költség szerint"
                      )
                    }}
                  </h3>
                </div>

                <apex-chart
                  type="bar"
                  height="300"
                  :options="monthlyBarChartOptionsByRoleMoney"
                  :series="monthlyBarChartDataByRolesMoney"
                ></apex-chart>
              </div>
            </div>
          </template>
        </div>

        <div class="is-flex mt-4 mb-4">
          <div>
            <h3 class="heading" :style="'margin-bottom: 0.3rem !important'">
              {{
                $tf(
                  "projectPage.workersList.byRoles.selectData.title|Adatok kiválasztása"
                )
              }}
            </h3>
            <h2 class="subtitle">
              {{
                $tf(
                  "projectPage.workersList.byRoles.subtitle|A kijelölt szerepkörök adatai jelennek meg a grafikonokon, illetve számítódnak bele a táblázat összegző sorába."
                )
              }}
            </h2>
          </div>

          <div class="is-flex ml-auto is-align-items-center">
            <div class="mr-3">
              <b-button
                outlined
                type="is-dark"
                @click="unSelectAllRoles"
                :disabled="noRolesSelected"
                >{{
                  $tf(
                    "projectPage.workersList.byRoles.selectData.none|Kijelöltek törlése"
                  )
                }}
              </b-button>
            </div>

            <div>
              <b-button type="is-info" class="mr-1" @click="doSelectAllRoles"
                >{{
                  $tf(
                    "projectPage.workersList.byRoles.selectData.all|Összes kijelölése"
                  )
                }}
              </b-button>
            </div>
          </div>
        </div>

        <div>
          <b-tabs v-model="workerTab" type="is-toggle" class="toggle-only">
            <b-tab-item
              key="projectWorker"
              :label="
                $tf('projectPage.workersList.tabs.wholeProject|Teljes projekt')
              "
              id="projectWorker"
              :visible="true"
              icon="folder"
            >
            </b-tab-item>

            <b-tab-item
              v-for="tigDetails in sortedTigs"
              :key="tigDetails.identifier"
              :label="tigDetails.name"
              :id="'worker' + tigDetails.identifier"
              icon="file"
            >
            </b-tab-item>
          </b-tabs>

          <div class="overflow-x-auto">
            <table class="worker-table">
              <tr>
                <th colspan="4"></th>
                <th
                  class="bg-lightgray"
                  v-for="(month, index) in months"
                  :key="month"
                  :style="{
                    borderRadius: index === 0 ? '8px 0 0 8px' : '0',
                  }"
                >
                  {{ month }}
                </th>
                <th class="bg-lightgray" style="border-radius: 0 8px 8px 0">
                  {{
                    $tf("projectPage.workersList.byRoles.table.sum|Összesen")
                  }}
                </th>
              </tr>
              <tr class="blank-row-small">
                <td></td>
              </tr>

              <tr>
                <td colspan="2" rowspan="3"></td>
                <td
                  rowspan="3"
                  class="bg-light-blue"
                  style="border-radius: 8px 0 0 8px"
                >
                  <div
                    class="is-flex is-align-items-center ml-3 is-size-5 has-font-weight-500"
                    style="height: 64px"
                  >
                    {{
                      $tf("projectPage.workersList.byRoles.table.sum|Összesen")
                    }}
                  </div>
                </td>
                <td class="bg-light-blue">
                  {{
                    $tf(
                      "projectPage.workersList.byRoles.table.timeSpent|Logolt idő"
                    )
                  }}
                </td>

                <td
                  v-for="(m, index) in months"
                  :key="index"
                  class="bg-light-blue"
                >
                  {{
                    secondToShortWorkDayFormat(
                      filteredRoles
                        .filter((fc) =>
                          selectedRoles[selectionIndex]?.includes(fc.id)
                        )
                        .map((med) => {
                          return med.monthlyLogged[index];
                        })
                        .reduce((partialSum, a) => partialSum + a, 0)
                    )
                  }}
                </td>
                <td
                  class="bg-light-blue has-font-weight-500"
                  :style="{
                    borderTopRightRadius: '8px',
                  }"
                >
                  {{
                    secondToShortWorkDayFormat(
                      filteredRoles
                        .filter((fc) =>
                          selectedRoles[selectionIndex]?.includes(fc.id)
                        )
                        .reduce(
                          (partialSum, a) => partialSum + a.filteredTimespent,
                          0
                        )
                    )
                  }}
                </td>
              </tr>
              <tr>
                <td class="bg-light-blue">
                  {{
                    $tf(
                      "projectPage.workersList.byRoles.table.moneySpent|Költés"
                    )
                  }}
                </td>

                <td
                  v-for="(m, index) in months"
                  :key="index"
                  class="bg-light-blue"
                >
                  {{
                    moneyify(
                      filteredRoles
                        .filter((fc) =>
                          selectedRoles[selectionIndex]?.includes(fc.id)
                        )
                        .map((med) => {
                          return med.monthlySpent[index];
                        })
                        .reduce((partialSum, a) => partialSum + a, 0)
                    )
                  }}
                </td>
                <td class="bg-light-blue has-font-weight-500">
                  {{
                    moneyify(
                      filteredRoles
                        .filter((fc) =>
                          selectedRoles[selectionIndex]?.includes(fc.id)
                        )
                        .reduce(
                          (partialSum, a) => partialSum + a.filteredMoneyspent,
                          0
                        )
                    )
                  }}
                </td>
              </tr>
              <tr>
                <td class="bg-light-blue">
                  {{
                    $tf(
                      "projectPage.workersList.byRoles.table.moneySpentAccumulated|Költés (kummulált)"
                    )
                  }}
                </td>
                <td
                  v-for="(monthSum, index) in accumulatedByRole"
                  :key="index"
                  class="bg-light-blue"
                >
                  {{ moneyify(monthSum) }}
                </td>
                <td
                  class="bg-light-blue has-font-weight-500"
                  :style="{
                    borderBottomRightRadius: '8px',
                  }"
                >
                  {{ moneyify(accumulatedByRole.slice(-1)) }}
                </td>
              </tr>

              <tr class="blank-row-small">
                <td></td>
              </tr>

              <template v-for="role in filteredRoles" :key="role.id">
                <tr
                  :class="{
                    'inactive-row': !selectedRoles[selectionIndex]?.includes(
                      role.id
                    ),
                  }"
                >
                  <td rowspan="2" style="width: 50px" class="checkbox-cell">
                    <div
                      class="is-flex is-justify-content-center is-align-items-center"
                      style="height: 70px; width: 50px"
                    >
                      <div>
                        <b-checkbox
                          v-model="selectedRoles[selectionIndex]"
                          :native-value="role.id"
                        />
                      </div>
                    </div>
                  </td>
                  <td rowspan="2" style="width: 64px">
                    <f-avatar
                      :data="role"
                      :predefined-color="role.color"
                      size="64x64"
                      :font-size="1.5"
                    />
                  </td>
                  <td>
                    <div class="has-font-weight-500">{{ role.name }}</div>
                  </td>
                  <td>
                    {{
                      $tf(
                        "projectPage.workersList.byRoles.table.timeSpent|Logolt idő"
                      )
                    }}
                  </td>
                  <td
                    v-for="(monthlyLog, index) in role.monthlyLogged"
                    :key="index"
                  >
                    {{ secondToShortWorkDayFormat(monthlyLog) }}
                  </td>
                  <td>
                    <span class="has-font-weight-500">{{
                      secondToShortWorkDayFormat(role.filteredTimespent)
                    }}</span>
                  </td>
                </tr>
                <tr
                  :class="{
                    'inactive-row': !selectedRoles[selectionIndex]?.includes(
                      role.id
                    ),
                  }"
                >
                  <td></td>
                  <td>
                    {{
                      $tf(
                        "projectPage.workersList.byRoles.table.moneySpent|Költés"
                      )
                    }}
                  </td>
                  <td
                    v-for="(monthlySpent, index) in role.monthlySpent"
                    :key="index"
                  >
                    {{ moneyify(monthlySpent) }}
                  </td>
                  <td>
                    <span class="has-font-weight-500">{{
                      moneyify(role.filteredMoneyspent)
                    }}</span>
                  </td>
                </tr>
                <tr class="blank-row-small">
                  <td></td>
                </tr>
              </template>
            </table>
          </div>
        </div>

        <div class="mt-5 is-flex is-justify-content-space-between">
          <div>
            <h3 class="heading">
              {{
                $tf(
                  "projectPage.workersList.byRoles.distribution.title|Szerepkör megoszlás"
                )
              }}
            </h3>
            <h2 class="subtitle">
              {{
                $tf(
                  "projectPage.workersList.byRoles.distribution.subtitle|Szerepkörök megoszlása összesítve, a teljes projekt, vagy a kiválasztott mérföldkő ideje alatt."
                )
              }}
            </h2>
            <div
              class="is-flex is-flex-direction-column has-gap-2 has-text-dark-blue"
            >
              <div
                v-for="role in filteredRoles"
                :key="role.id"
                class="is-flex has-gap-2 is-align-items-center"
              >
                <div
                  style="width: 16px; height: 16px; border-radius: 4px"
                  :style="{ background: role.color }"
                ></div>
                <div>{{ role.name }}</div>
              </div>
            </div>
          </div>
          <div class="is-flex is-flex-direction-column is-align-items-center">
            <h3 class="heading">
              {{
                $tf(
                  "projectPage.workersList.byRoles.distribution.byTimeSpent|Logolt idő szerint"
                )
              }}
            </h3>
            <apex-chart
              :options="rolesRadialOptions"
              :series="rolesRadialTimeData"
              :height="200"
            />
          </div>
          <div class="is-flex is-flex-direction-column is-align-items-center">
            <h3 class="heading">
              {{
                $tf(
                  "projectPage.workersList.byRoles.distribution.byMoneySpent|Költés szerint"
                )
              }}
            </h3>
            <apex-chart
              :options="rolesRadialOptions"
              :series="rolesRadialMoneyData"
              :height="200"
            />
          </div>
        </div>
      </template>
      <template v-if="detailsTab === 1">
        <div class="employee-graph">
          <template v-if="noDataForEmployees">
            <section class="section">
              <div class="content has-text-grey has-text-centered">
                <p>
                  <b-icon icon="exclamation-triangle" size="is-large"></b-icon>
                </p>
                <p>
                  {{
                    $tf(
                      "projectPage.workersList.byWorkers.atLeastOneWorkerNeeded|Legalább egy dolgozót ki kell jelölni!"
                    )
                  }}
                </p>
              </div>
            </section>
          </template>
          <template v-else>
            <div class="columns">
              <div class="column is-half">
                <div class="is-inline-flex is-align-items-center">
                  <b-icon icon="clock-rotate-left" />
                  <h3 class="heading">
                    {{
                      $tf(
                        "projectPage.workersList.byWorkers.byTimelogs|Idő szerint"
                      )
                    }}
                  </h3>
                </div>

                <apex-chart
                  type="bar"
                  height="300"
                  :options="monthlyBarChartOptionsByEmployeeTime"
                  :series="monthlyBarChartDataByEmployees"
                ></apex-chart>
              </div>
              <div class="column is-half">
                <div class="is-inline-flex is-align-items-center">
                  <b-icon icon="wallet" />
                  <h3 class="heading">
                    {{
                      $tf(
                        "projectPage.workersList.byWorkers.byCost|Költség szerint"
                      )
                    }}
                  </h3>
                </div>

                <apex-chart
                  type="bar"
                  height="300"
                  :options="monthlyBarChartOptionsByEmployeeMoney"
                  :series="monthlyBarChartDataByEmployeesMoney"
                ></apex-chart>
              </div>
            </div>
          </template>
        </div>

        <div class="is-flex mt-4 mb-4">
          <div>
            <h3 class="heading" :style="'margin-bottom: 0.3rem !important'">
              {{
                $tf(
                  "projectPage.workersList.byWorkers.selectData.title|Adatok kiválasztása"
                )
              }}
            </h3>
            <h2 class="subtitle">
              {{
                $tf(
                  "projectPage.workersList.byWorkers.selectData.subitle|A kijelölt dolgozók adatai jelennek meg a grafikonokon, illetve számítódnak bele a táblázat összegző sorába."
                )
              }}
            </h2>
          </div>

          <div class="is-flex ml-auto is-align-items-center">
            <div class="mr-3">
              <b-button
                outlined
                type="is-dark"
                @click="unSelectAllEmployees"
                :disabled="noEmployeesSelected"
                >{{
                  $tf(
                    "projectPage.workersList.byWorkers.selectData.none|Kijelöltek törlése"
                  )
                }}
              </b-button>
            </div>

            <div>
              <b-button
                type="is-info"
                class="mr-1"
                @click="doSelectAllEmployees"
                >{{
                  $tf(
                    "projectPage.workersList.byWorkers.selectData.all|Összes kijelölése"
                  )
                }}
              </b-button>
            </div>
          </div>
        </div>

        <div>
          <b-tabs v-model="workerTab" type="is-toggle" class="toggle-only">
            <b-tab-item
              key="projectWorker"
              :label="
                $tf('projectPage.workersList.tabs.wholeProject|Teljes projekt')
              "
              id="projectWorker"
              :visible="true"
              icon="folder"
            >
            </b-tab-item>

            <b-tab-item
              v-for="tigDetails in sortedTigs"
              :key="tigDetails.identifier"
              :label="tigDetails.name"
              :id="'worker' + tigDetails.identifier"
              icon="file"
            >
            </b-tab-item>
          </b-tabs>

          <div class="overflow-x-auto">
            <table class="worker-table">
              <tr>
                <th colspan="4"></th>

                <th
                  class="bg-lightgray"
                  v-for="(month, index) in months"
                  :key="month"
                  :style="{
                    borderRadius: index === 0 ? '8px 0 0 8px' : '0',
                  }"
                >
                  {{ month }}
                </th>

                <th class="bg-lightgray" style="border-radius: 0 8px 8px 0">
                  {{
                    $tf("projectPage.workersList.byWorkers.table.sum|Összesen")
                  }}
                </th>
              </tr>
              <tr class="blank-row-small">
                <td></td>
              </tr>

              <tr>
                <td colspan="2" rowspan="3"></td>
                <td
                  rowspan="3"
                  class="bg-light-blue"
                  style="border-radius: 8px 0 0 8px"
                >
                  <div
                    class="is-flex is-align-items-center ml-3 is-size-5 has-font-weight-500"
                    style="height: 64px"
                  >
                    {{
                      $tf(
                        "projectPage.workersList.byWorkers.table.sum|Összesen"
                      )
                    }}
                  </div>
                </td>
                <td class="bg-light-blue">
                  {{
                    $tf(
                      "projectPage.workersList.byWorkers.table.timeSpent|Logolt idő"
                    )
                  }}
                </td>

                <td
                  v-for="(m, index) in months"
                  :key="index"
                  class="bg-light-blue"
                >
                  {{
                    secondToShortWorkDayFormat(
                      monthlySelectedEmployeeData
                        .map((med) => {
                          return med.monthlyLogged[index];
                        })
                        .reduce((partialSum, a) => partialSum + a, 0)
                    )
                  }}
                </td>
                <td
                  class="bg-light-blue has-font-weight-500"
                  :style="{
                    borderTopRightRadius: '8px',
                  }"
                >
                  {{
                    secondToShortWorkDayFormat(
                      monthlySelectedEmployeeData.reduce(
                        (partialSum, a) => partialSum + a.timespent,
                        0
                      )
                    )
                  }}
                </td>
              </tr>
              <tr>
                <td class="bg-light-blue">
                  {{
                    $tf(
                      "projectPage.workersList.byWorkers.table.moneySpent|Költés"
                    )
                  }}
                </td>

                <td
                  v-for="(m, index) in months"
                  :key="index"
                  class="bg-light-blue"
                >
                  {{
                    moneyify(
                      monthlySelectedEmployeeData
                        .map((med) => {
                          return med.monthlySpent[index];
                        })
                        .reduce((partialSum, a) => partialSum + a, 0)
                    )
                  }}
                </td>
                <td class="bg-light-blue has-font-weight-500">
                  {{
                    moneyify(
                      monthlySelectedEmployeeData.reduce(
                        (partialSum, a) => partialSum + a.moneyspent,
                        0
                      )
                    )
                  }}
                </td>
              </tr>
              <tr>
                <td class="bg-light-blue">
                  {{
                    $tf(
                      "projectPage.workersList.byWorkers.table.moneySpentAccumulated|Költés (kummulált)"
                    )
                  }}
                </td>

                <td
                  v-for="(monthSum, index) in accumulatedMonthlySpent"
                  :key="index"
                  class="bg-light-blue"
                >
                  {{ moneyify(monthSum) }}
                </td>
                <td
                  class="bg-light-blue has-font-weight-500"
                  :style="{
                    borderBottomRightRadius: '8px',
                  }"
                >
                  {{ moneyify(accumulatedMonthlySpent.slice(-1)) }}
                </td>
              </tr>

              <tr class="blank-row-small">
                <td></td>
              </tr>

              <template
                v-for="emp in monthlyEmployeeData"
                :key="emp.externalId"
              >
                <tr
                  :class="{
                    'inactive-row': !selectedEmployees[
                      selectionIndex
                    ]?.includes(emp.externalId),
                  }"
                >
                  <td rowspan="2" style="width: 50px" class="checkbox-cell">
                    <div
                      class="is-flex is-justify-content-center is-align-items-center"
                      style="height: 70px; width: 50px"
                    >
                      <div>
                        <b-checkbox
                          v-model="selectedEmployees[selectionIndex]"
                          :native-value="emp.externalId"
                        />
                      </div>
                    </div>
                  </td>
                  <td rowspan="2" style="width: 64px">
                    <f-avatar
                      :data="emp"
                      :predefined-color="emp.color"
                      :border="4"
                      size="64x64"
                      :font-size="1.5"
                    />
                  </td>
                  <td>
                    <div class="has-font-weight-500">{{ emp.name }}</div>
                  </td>
                  <td>
                    {{
                      $tf(
                        "projectPage.workersList.byWorkers.table.timeSpent|Logolt idő"
                      )
                    }}
                  </td>

                  <td
                    v-for="(monthlyLog, index) in emp.monthlyLogged"
                    :key="index"
                  >
                    {{ secondToShortWorkDayFormat(monthlyLog) }}
                  </td>
                  <td>
                    <span class="has-font-weight-500">{{
                      secondToShortWorkDayFormat(emp.timespent)
                    }}</span>
                  </td>
                </tr>
                <tr
                  :class="{
                    'inactive-row': !selectedEmployees[
                      selectionIndex
                    ]?.includes(emp.externalId),
                  }"
                >
                  <td></td>
                  <td>
                    {{
                      $tf(
                        "projectPage.workersList.byWorkers.table.moneySpent|Költés"
                      )
                    }}
                  </td>

                  <td
                    v-for="(monthlySpent, index) in emp.monthlySpent"
                    :key="index"
                  >
                    {{ moneyify(monthlySpent) }}
                  </td>
                  <td>
                    <span class="has-font-weight-500">{{
                      moneyify(emp.moneyspent)
                    }}</span>
                  </td>
                </tr>
                <tr class="blank-row-small">
                  <td></td>
                </tr>
              </template>
            </table>
          </div>
        </div>
      </template>
      <template v-if="detailsTab === 2">
        <div class="employee-graph">
          <template v-if="noDataForSeniorities">
            <section class="section">
              <div class="content has-text-grey has-text-centered">
                <p>
                  <b-icon icon="exclamation-triangle" size="is-large"></b-icon>
                </p>
                <p>
                  {{
                    $tf(
                      "projectPage.workersList.bySeniorities.atLeastOneRoleNeeded|Legalább egy szenioritást ki kell jelölni!"
                    )
                  }}
                </p>
              </div>
            </section>
          </template>
          <template v-else>
            <div class="columns">
              <div class="column is-half">
                <div class="is-inline-flex is-align-items-center">
                  <b-icon icon="clock-rotate-left" />
                  <h3 class="heading">
                    {{
                      $tf(
                        "projectPage.workersList.bySeniorities.byTimelogs|Idő szerint"
                      )
                    }}
                  </h3>
                </div>

                <apex-chart
                  type="bar"
                  height="300"
                  :options="monthlyBarChartOptionsBySeniorityTime"
                  :series="monthlyBarChartDataBySeniorities"
                ></apex-chart>
              </div>
              <div class="column is-half">
                <div class="is-inline-flex is-align-items-center">
                  <b-icon icon="wallet" />
                  <h3 class="heading">
                    {{
                      $tf(
                        "projectPage.workersList.bySeniorities.byCost|Költség szerint"
                      )
                    }}
                  </h3>
                </div>

                <apex-chart
                  type="bar"
                  height="300"
                  :options="monthlyBarChartOptionsBySeniorityMoney"
                  :series="monthlyBarChartDataBySenioritiesMoney"
                ></apex-chart>
              </div>
            </div>
          </template>
        </div>

        <div class="is-flex mt-4 mb-4">
          <div>
            <h3 class="heading" :style="'margin-bottom: 0.3rem !important'">
              {{
                $tf(
                  "projectPage.workersList.bySeniorities.selectData.title|Adatok kiválasztása"
                )
              }}
            </h3>
            <h2 class="subtitle">
              {{
                $tf(
                  "projectPage.workersList.bySeniorities.subtitle|A kijelölt szenioritások adatai jelennek meg a grafikonokon, illetve számítódnak bele a táblázat összegző sorába."
                )
              }}
            </h2>
          </div>

          <div class="is-flex ml-auto is-align-items-center">
            <div class="mr-3">
              <b-button
                outlined
                type="is-dark"
                @click="unSelectAllSeniorities"
                :disabled="noSenioritiesSelected"
                >{{
                  $tf(
                    "projectPage.workersList.bySeniorities.selectData.none|Kijelöltek törlése"
                  )
                }}
              </b-button>
            </div>

            <div>
              <b-button
                type="is-info"
                class="mr-1"
                @click="doSelectAllSeniorities"
                >{{
                  $tf(
                    "projectPage.workersList.bySeniorities.selectData.all|Összes kijelölése"
                  )
                }}
              </b-button>
            </div>
          </div>
        </div>

        <div>
          <b-tabs v-model="workerTab" type="is-toggle" class="toggle-only">
            <b-tab-item
              key="projectWorker"
              :label="
                $tf('projectPage.workersList.tabs.wholeProject|Teljes projekt')
              "
              id="projectWorker"
              :visible="true"
              icon="folder"
            >
            </b-tab-item>

            <b-tab-item
              v-for="tigDetails in sortedTigs"
              :key="tigDetails.identifier"
              :label="tigDetails.name"
              :id="'worker' + tigDetails.identifier"
              icon="file"
            >
            </b-tab-item>
          </b-tabs>

          <div class="overflow-x-auto">
            <table class="worker-table">
              <tr>
                <th colspan="4"></th>

                <th
                  class="bg-lightgray"
                  v-for="(month, index) in months"
                  :key="month"
                  :style="{
                    borderRadius: index === 0 ? '8px 0 0 8px' : '0',
                  }"
                >
                  {{ month }}
                </th>

                <th class="bg-lightgray" style="border-radius: 0 8px 8px 0">
                  {{
                    $tf(
                      "projectPage.workersList.bySeniorities.table.sum|Összesen"
                    )
                  }}
                </th>
              </tr>
              <tr class="blank-row-small">
                <td></td>
              </tr>

              <tr>
                <td colspan="2" rowspan="3"></td>
                <td
                  rowspan="3"
                  class="bg-light-blue"
                  style="border-radius: 8px 0 0 8px"
                >
                  <div
                    class="is-flex is-align-items-center ml-3 is-size-5 has-font-weight-500"
                    style="height: 64px"
                  >
                    {{
                      $tf(
                        "projectPage.workersList.bySeniorities.table.sum|Összesen"
                      )
                    }}
                  </div>
                </td>
                <td class="bg-light-blue">
                  {{
                    $tf(
                      "projectPage.workersList.bySeniorities.table.timeSpent|Logolt idő"
                    )
                  }}
                </td>
                <td
                  v-for="(m, index) in months"
                  :key="index"
                  class="bg-light-blue"
                >
                  {{
                    secondToShortWorkDayFormat(
                      filteredSeniorities
                        .filter((fs) =>
                          selectedSeniorities[selectionIndex]?.includes(fs.id)
                        )
                        .map((med) => {
                          return med.monthlyLogged[index];
                        })
                        .reduce((partialSum, a) => partialSum + a, 0)
                    )
                  }}
                </td>
                <td
                  class="bg-light-blue has-font-weight-500"
                  :style="{
                    borderTopRightRadius: '8px',
                  }"
                >
                  {{
                    secondToShortWorkDayFormat(
                      filteredSeniorities
                        .filter((fs) =>
                          selectedSeniorities[selectionIndex]?.includes(fs.id)
                        )
                        .reduce(
                          (partialSum, a) => partialSum + a.filteredTimespent,
                          0
                        )
                    )
                  }}
                </td>
              </tr>
              <tr>
                <td class="bg-light-blue">
                  {{
                    $tf(
                      "projectPage.workersList.bySeniorities.table.moneySpent|Költés"
                    )
                  }}
                </td>
                <td
                  v-for="(m, index) in months"
                  :key="index"
                  class="bg-light-blue"
                >
                  {{
                    moneyify(
                      filteredSeniorities
                        .filter((fs) =>
                          selectedSeniorities[selectionIndex]?.includes(fs.id)
                        )
                        .map((med) => {
                          return med.monthlySpent[index];
                        })
                        .reduce((partialSum, a) => partialSum + a, 0)
                    )
                  }}
                </td>
                <td class="bg-light-blue has-font-weight-500">
                  {{
                    moneyify(
                      filteredSeniorities
                        .filter((fs) =>
                          selectedSeniorities[selectionIndex]?.includes(fs.id)
                        )
                        .reduce(
                          (partialSum, a) => partialSum + a.filteredMoneyspent,
                          0
                        )
                    )
                  }}
                </td>
              </tr>
              <tr>
                <td class="bg-light-blue">
                  {{
                    $tf(
                      "projectPage.workersList.bySeniorities.table.moneySpentAccumulated|Költés (kummulált)"
                    )
                  }}
                </td>
                <td
                  v-for="(monthSum, index) in accumulatedBySeniority"
                  :key="index"
                  class="bg-light-blue"
                >
                  {{ moneyify(monthSum) }}
                </td>
                <td
                  class="bg-light-blue has-font-weight-500"
                  :style="{
                    borderBottomRightRadius: '8px',
                  }"
                >
                  {{ moneyify(accumulatedBySeniority.slice(-1)) }}
                </td>
              </tr>

              <tr class="blank-row-small">
                <td></td>
              </tr>
              <template
                v-for="seniority in filteredSeniorities"
                :key="seniority.id"
              >
                <tr
                  :class="{
                    'inactive-row': !selectedSeniorities[
                      selectionIndex
                    ]?.includes(seniority.id),
                  }"
                >
                  <td rowspan="2" style="width: 50px" class="checkbox-cell">
                    <div
                      class="is-flex is-justify-content-center is-align-items-center"
                      style="height: 70px; width: 50px"
                    >
                      <div>
                        <b-checkbox
                          v-model="selectedSeniorities[selectionIndex]"
                          :native-value="seniority.id"
                        />
                      </div>
                    </div>
                  </td>
                  <td rowspan="2" style="width: 64px">
                    <f-avatar
                      :data="seniority"
                      :predefined-color="seniority.color"
                      size="64x64"
                      :font-size="1.5"
                    />
                  </td>
                  <td>
                    <div class="has-font-weight-500">{{ seniority.name }}</div>
                  </td>
                  <td>
                    {{
                      $tf(
                        "projectPage.workersList.bySeniorities.table.timeSpent|Logolt idő"
                      )
                    }}
                  </td>
                  <td
                    v-for="(monthlyLog, index) in seniority.monthlyLogged"
                    :key="index"
                  >
                    {{ secondToShortWorkDayFormat(monthlyLog) }}
                  </td>
                  <td>
                    <span class="has-font-weight-500">{{
                      secondToShortWorkDayFormat(seniority.filteredTimespent)
                    }}</span>
                  </td>
                </tr>
                <tr
                  :class="{
                    'inactive-row': !selectedSeniorities[
                      selectionIndex
                    ]?.includes(seniority.id),
                  }"
                >
                  <td></td>
                  <td>
                    {{
                      $tf(
                        "projectPage.workersList.bySeniorities.table.moneySpent|Költés"
                      )
                    }}
                  </td>
                  <td
                    v-for="(monthlySpent, index) in seniority.monthlySpent"
                    :key="index"
                  >
                    {{ moneyify(monthlySpent) }}
                  </td>
                  <td>
                    <span class="has-font-weight-500">{{
                      moneyify(seniority.filteredMoneyspent)
                    }}</span>
                  </td>
                </tr>
                <tr class="blank-row-small">
                  <td></td>
                </tr>
              </template>
            </table>
          </div>
        </div>

        <div class="mt-5 is-flex is-justify-content-space-between">
          <div>
            <h3 class="heading">
              {{
                $tf(
                  "projectPage.workersList.bySeniorities.distribution.title|Szenioritás megoszlás"
                )
              }}
            </h3>
            <h2 class="subtitle">
              {{
                $tf(
                  "projectPage.workersList.bySeniorities.distribution.subtitle|Szenioritások megoszlása összesítve, a teljes projekt, vagy a kiválasztott mérföldkő ideje alatt."
                )
              }}
            </h2>
            <div
              class="is-flex is-flex-direction-column has-gap-2 has-text-dark-blue"
            >
              <div
                v-for="seniority in filteredSeniorities"
                :key="seniority.id"
                class="is-flex has-gap-2 is-align-items-center"
              >
                <div
                  style="width: 16px; height: 16px; border-radius: 4px"
                  :style="{ background: seniority.color }"
                ></div>
                <div>{{ seniority.name }}</div>
              </div>
            </div>
          </div>
          <div class="is-flex is-flex-direction-column is-align-items-center">
            <h3 class="heading">
              {{
                $tf(
                  "projectPage.workersList.bySeniorities.distribution.byTimeSpent|Logolt idő szerint"
                )
              }}
            </h3>
            <apex-chart
              :options="senioritiesRadialOptions"
              :series="senioritiesRadialTimeData"
              :height="200"
            />
          </div>
          <div class="is-flex is-flex-direction-column is-align-items-center">
            <h3 class="heading">
              {{
                $tf(
                  "projectPage.workersList.bySeniorities.distribution.byMoneySpent|Költés szerint"
                )
              }}
            </h3>
            <apex-chart
              :options="senioritiesRadialOptions"
              :series="senioritiesRadialMoneyData"
              :height="200"
            />
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import {
  deepCopy,
  moneyify,
  secondToShortWorkDayFormat,
  secondToWorkDayFormat,
  workdayify,
} from "@/utils/util";
import { mapGetters, mapState } from "vuex";
import dayjs from "dayjs";
import FAvatar from "@/components/module/icon/FAvatar.vue";

export default {
  name: "WorkersList",
  components: { FAvatar },
  props: {
    employeeData: {
      type: Array,
      required: true,
    },
    projectData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      chartRestriction: {
        seniority: {
          time: { min: 0, max: 100000 },
          money: { min: 0, max: 100000 },
        },
        role: {
          time: { min: 0, max: 100000 },
          money: { min: 0, max: 100000 },
        },
        employee: {
          time: { min: 0, max: 100000 },
          money: { min: 0, max: 100000 },
        },
      },
      workerTab: 0,
      detailsTab: 0,
      moneyify,
      workdayify,
      secondToWorkDayFormat,
      secondToShortWorkDayFormat,
      selectedEmployees: [],
      selectedRoles: [],
      selectedSeniorities: [],
      graphType: 0,
      showSaturday: false,
      showSunday: false,
      initLoaded: {
        roles: false,
        seniorities: false,
        tigEmployeeDetails: false,
      },
    };
  },
  watch: {
    initLoaded: {
      async handler(newVal) {
        if (Object.values(newVal).every((flag) => flag)) {
          await this.setupSelectedRows();
          this.setupChartRestrictions();
        }
      },
      deep: true,
    },
    roles: {
      handler(newVal) {
        if (!this.initLoaded.roles && !!newVal && newVal.length > 0) {
          this.initLoaded.roles = true;
        }
      },
      immediate: true,
    },
    seniorities: {
      handler(newVal) {
        if (!this.initLoaded.seniorities && !!newVal && newVal.length > 0) {
          this.initLoaded.seniorities = true;
        }
      },
      immediate: true,
    },
    tigEmployeeDetails: {
      handler(newVal) {
        if (
          !this.initLoaded.tigEmployeeDetails &&
          !!newVal &&
          Object.keys(newVal).length > 0
        ) {
          this.initLoaded.tigEmployeeDetails = true;
        }
      },
      immediate: true,
    },
  },
  computed: {
    sourceData() {
      let pd = deepCopy(this.projectData);
      pd.employeeData = this.employeeData;
      return pd;
    },
    startDate() {
      const earliest = this.worklogs.reduce((acc, current) => {
        const currentDate = new Date(current.day);
        return !acc || currentDate < acc ? currentDate : acc;
      }, null);

      return earliest || new Date();
    },
    endDate() {
      const latest = this.worklogs.reduce((acc, current) => {
        const currentDate = new Date(current.day);
        return !acc || currentDate > acc ? currentDate : acc;
      }, null);

      return latest || new Date();
    },
    accumulatedBySeniority() {
      return this.calculateAccumulatedSum(
        this.filteredSeniorities,
        this.selectedSeniorities,
        true
      );
    },
    accumulatedByRole() {
      return this.calculateAccumulatedSum(
        this.filteredRoles,
        this.selectedRoles,
        true
      );
    },
    accumulatedMonthlySpent() {
      return this.calculateAccumulatedSum(
        this.monthlySelectedEmployeeData,
        [],
        false
      );
    },
    monthNumber() {
      return (
        Math.ceil(
          dayjs(this.endDate).diff(dayjs(this.startDate), "month", true)
        ) + 1
      );
    },
    months() {
      let months = [];

      let actYear = dayjs(this.startDate).year();
      let actMonth = dayjs(this.startDate).month();

      for (let i = 0; i < this.monthNumber; i++) {
        months.push(dayjs(new Date(actYear, actMonth, 1)).format("YYYY. MM."));
        actMonth++;
        if (actMonth > 12) {
          actMonth = 1;
          actYear++;
        }
      }

      return months;
    },
    monthlySelectedEmployeeData() {
      return this.monthlyEmployeeData.filter((med) =>
        this.selectedEmployeeObjects
          .map((seo) => seo.externalId)
          .includes(med.externalId)
      );
    },
    monthlyEmployeeData() {
      if (!this.employees || !this.worklogs) return [];

      const employeeData = this.employees.map((employee) => {
        const employeeWorklogs = this.filterWorklogsByEmployee(
          employee.externalId
        );

        return {
          ...employee,
          ...this.calculateEmployeeTotals(employee, employeeWorklogs),
        };
      });

      return this.selectedTig
        ? this.filterBySelectedTig(employeeData)
        : employeeData;
    },
    monthlyBarChartOptionsByEmployeeTime() {
      return this.createMonthlyBarChartOptions(
        "time",
        this.selectedEmployeeColors,
        this.chartRestriction.employee.time.min,
        this.chartRestriction.employee.time.max,
        function (val) {
          return workdayify(val / 28800);
        }
      );
    },
    monthlyBarChartOptionsByRoleTime() {
      return this.createMonthlyBarChartOptions(
        "time",
        this.selectedRoleColors,
        this.chartRestriction.role.time.min,
        this.chartRestriction.role.time.max,

        function (val) {
          return workdayify(val / 28800);
        }
      );
    },
    monthlyBarChartOptionsBySeniorityTime() {
      return this.createMonthlyBarChartOptions(
        "time",
        this.selectedSeniorityColors,
        this.chartRestriction.seniority.time.min,
        this.chartRestriction.seniority.time.max,
        function (val) {
          return workdayify(val / 28800);
        }
      );
    },
    monthlyBarChartOptionsByEmployeeMoney() {
      return this.createMonthlyBarChartOptions(
        "money",
        this.selectedEmployeeColors,
        this.chartRestriction.employee.money.min,
        this.chartRestriction.employee.money.max,
        function (val) {
          return moneyify.call(this, Math.round(val));
        }
      );
    },
    monthlyBarChartOptionsByRoleMoney() {
      return this.createMonthlyBarChartOptions(
        "money",
        this.selectedRoleColors,
        this.chartRestriction.role.money.min,
        this.chartRestriction.role.money.max,
        function (val) {
          return moneyify.call(this, Math.round(val));
        }
      );
    },
    monthlyBarChartOptionsBySeniorityMoney() {
      return this.createMonthlyBarChartOptions(
        "money",
        this.selectedSeniorityColors,
        this.chartRestriction.seniority.money.min,
        this.chartRestriction.seniority.money.max,
        function (val) {
          return moneyify.call(this, Math.round(val));
        }
      );
    },
    monthlyBarChartDataByEmployees() {
      return this.monthlySelectedEmployeeData.map((md) => ({
        name: md.name,
        data: md.monthlyLogged,
      }));
    },
    monthlyBarChartDataByEmployeesMoney() {
      return this.monthlySelectedEmployeeData.map((md) => ({
        name: md.name,
        data: md.monthlySpent,
      }));
    },
    monthlyBarChartDataByRoles() {
      if (!this.selectedRoles[this.selectionIndex]) return [];

      return this.filteredRoles
        .filter((fr) => this.selectedRoles[this.selectionIndex].includes(fr.id))
        .map((fr) => {
          return { name: fr.name, data: fr.monthlyLogged };
        });
    },
    monthlyBarChartDataByRolesMoney() {
      if (!this.selectedRoles[this.selectionIndex]) return [];

      return this.filteredRoles
        .filter((fr) => this.selectedRoles[this.selectionIndex].includes(fr.id))
        .map((fr) => {
          return { name: fr.name, data: fr.monthlySpent };
        });
    },
    monthlyBarChartDataBySeniorities() {
      if (!this.selectedSeniorities[this.selectionIndex]) return [];

      return this.filteredSeniorities
        .filter((fs) =>
          this.selectedSeniorities[this.selectionIndex].includes(fs.id)
        )
        .map((fs) => {
          return { name: fs.name, data: fs.monthlyLogged };
        });
    },
    monthlyBarChartDataBySenioritiesMoney() {
      if (!this.selectedSeniorities[this.selectionIndex]) return [];

      return this.filteredSeniorities
        .filter((fs) =>
          this.selectedSeniorities[this.selectionIndex].includes(fs.id)
        )
        .map((fs) => {
          return { name: fs.name, data: fs.monthlySpent };
        });
    },
    tigEmployeeDetails() {
      let tigs = {};
      for (let i in this.employeeData) {
        this.employeeData[i].segments.forEach((segment) => {
          if (!tigs[segment.identifier]) {
            tigs[segment.identifier] = [];
          }
          tigs[segment.identifier].push({
            externalId: this.employeeData[i].externalId,
            avgRate: this.employeeData[i].avgRate,
            name: this.employeeData[i].name,
            timespent: segment.timespent,
            moneyspent: segment.moneyspent,
          });
        });
      }
      return tigs;
    },
    sortedTigs() {
      let sorted = deepCopy(this.projectData.tigDatas);
      if (!sorted) return [];
      sorted = sorted.filter(
        (tig) =>
          tig.type !== "UNTRACKED" && !!this.tigEmployeeDetails[tig.identifier]
      );
      sorted.forEach((tig) => {
        tig.employeeData = this.tigEmployeeDetails[tig.identifier];
      });
      return sorted.sort((a, b) =>
        a.key > b.key ? 1 : b.key > a.key ? -1 : 0
      );
    },
    selectedTig() {
      return this.sortedTigs[this.workerTab - 1];
    },
    selectedEmployeeColors() {
      return this.selectedEmployeeObjects.map((emp) => emp.color);
    },
    selectedRoleColors() {
      if (!this.selectedRoles[this.selectionIndex]) return [];

      return this.filteredRoles
        .filter((r) =>
          this.selectedRoles[this.selectionIndex].some((rc) => r.id === rc)
        )
        .map((role) => role.color);
    },
    selectedSeniorityColors() {
      if (!this.selectedSeniorities[this.selectionIndex]) return [];

      return this.filteredSeniorities
        .filter((s) =>
          this.selectedSeniorities[this.selectionIndex].some(
            (sc) => s.id === sc
          )
        )
        .map((sen) => sen.color);
    },
    selectedEmployeeObjects() {
      if (!this.selectedEmployees[this.selectionIndex]) return [];

      let array = this.employees;
      return array?.filter((employee) =>
        this.selectedEmployees[this.selectionIndex]?.includes(
          employee.externalId
        )
      );
    },
    employees() {
      let toReturn =
        !this.sourceData.employeeData || !this.sourceData.employeeData.length
          ? []
          : this.sourceData.employeeData;
      let count = 2;
      toReturn.forEach((emp) => {
        const hue = count++ * 137.508; // use golden angle approximation
        emp.color = `hsl(${hue}, 75%, ${Math.random() * 40 + 31}%)`;
      });
      return toReturn;
    },
    selectionIndex() {
      return this.workerTab === 0
        ? 0
        : parseInt(/[^-]*$/.exec(this.selectedTig.identifier)[0]);
    },
    noEmployeesSelected() {
      return (
        !this.selectedEmployees[this.selectionIndex] ||
        this.selectedEmployees[this.selectionIndex].length === 0
      );
    },
    noRolesSelected() {
      return (
        !this.selectedRoles[this.selectionIndex] ||
        this.selectedRoles[this.selectionIndex].length === 0
      );
    },
    noSenioritiesSelected() {
      return (
        !this.selectedSeniorities[this.selectionIndex] ||
        this.selectedSeniorities[this.selectionIndex].length === 0
      );
    },
    noDataForEmployees() {
      return this.noEmployeesSelected;
    },
    noDataForRoles() {
      return this.noRolesSelected;
    },
    noDataForSeniorities() {
      return this.noSenioritiesSelected;
    },
    roles() {
      return this.collectEmployeeData({
        employees: this.employees,
        workers: this.workers,
        indicators: this.indicators,
        keyField: "roleId",
        nameField: "name",
      });
    },
    seniorities() {
      return this.collectEmployeeData({
        employees: this.employees,
        workers: this.workers,
        indicators: this.indicators,
        keyField: "seniorityId",
        nameField: "name",
      });
    },
    filteredRoles() {
      return this.collectFilteredEmployeeData({
        keyField: "roleId",
        indicators: this.roles,
      });
    },

    filteredSeniorities() {
      return this.collectFilteredEmployeeData({
        keyField: "seniorityId",
        indicators: this.seniorities,
      });
    },

    rolesRadialOptions() {
      return this.createRadialOptions(this.filteredRoles);
    },
    rolesRadialTimeData() {
      return this.filteredRoles.map((fc) => fc.filteredTimespent);
    },
    rolesRadialMoneyData() {
      return this.filteredRoles.map((fc) => fc.filteredMoneyspent);
    },
    senioritiesRadialOptions() {
      return this.createRadialOptions(this.filteredSeniorities);
    },
    senioritiesRadialTimeData() {
      return this.filteredSeniorities.map((fs) => fs.filteredTimespent);
    },
    senioritiesRadialMoneyData() {
      return this.filteredSeniorities.map((fs) => fs.filteredMoneyspent);
    },
    worklogs() {
      const projectIssues = this.issues(this.projectData.id);
      if (!projectIssues) return [];

      const issuesWithWorklogs = projectIssues.filter(
        (issue) => issue.worklogs?.length > 0
      );

      issuesWithWorklogs.forEach((issue) => {
        const { key, tigId, worklogs } = issue;
        worklogs?.forEach((worklog) => {
          worklog.key = key;
          worklog.tigId = tigId;
        });
      });

      return issuesWithWorklogs.flatMap((issue) => issue.worklogs || []);
    },
    ...mapGetters({
      workers: "employee/employees",
      indicators: "user_indicator/indicators",
    }),
    ...mapState({
      issues(_state, getters) {
        return getters["enterprise_core/issues"];
      },
    }),
  },
  methods: {
    calculateEmployeeTotals(employee, employeeWorklogs) {
      const monthlyTotals = this.calculateMonthlyTotals(
        employee,
        employeeWorklogs
      );
      const segmentTotals = this.calculateSegmentTotals(
        employee,
        employeeWorklogs
      );

      return {
        monthlyLogged: monthlyTotals.logged,
        monthlySpent: monthlyTotals.spent,
        segments: segmentTotals,
      };
    },

    filterBySelectedTig(employeeData) {
      return employeeData.map((md) => {
        const selectedSegment = md.segments.find(
          (segment) => segment.tigId === this.selectedTig.id
        );

        return {
          ...md,
          monthlyLogged: selectedSegment
            ? selectedSegment.monthlyLogged
            : Array(this.monthNumber).fill(0),
          monthlySpent: selectedSegment
            ? selectedSegment.monthlySpent
            : Array(this.monthNumber).fill(0),
          timespent: selectedSegment
            ? selectedSegment.monthlyLogged.reduce(
                (sum, loggedTime) => sum + loggedTime,
                0
              )
            : 0,
          moneyspent: selectedSegment
            ? selectedSegment.monthlySpent.reduce(
                (sum, spentMoney) => sum + spentMoney,
                0
              )
            : 0,
        };
      });
    },
    calculateAccumulatedSum(data, selectedData, isFiltered = false) {
      const sums = [];
      for (let index = 0; index < this.months.length; index++) {
        const currentSum =
          isFiltered && selectedData[this.selectionIndex]
            ? data
                .filter((fs) =>
                  selectedData[this.selectionIndex].includes(fs.id)
                )
                .map((med) => med.monthlySpent[index])
                .reduce((partialSum, a) => partialSum + a, 0)
            : data
                .map((med) => med.monthlySpent[index])
                .reduce((partialSum, a) => partialSum + a, 0);

        if (index === 0) {
          sums.push(currentSum);
        } else {
          sums.push(sums[index - 1] + currentSum);
        }
      }
      return sums;
    },
    selectEmployee(externalId) {
      let index = this.selectedEmployees[this.selectionIndex]
        ? this.selectedEmployees[this.selectionIndex].indexOf(externalId)
        : -1;

      const oldArray = this.selectedEmployees[this.selectionIndex];
      if (index !== -1) {
        oldArray.splice(index, 1);
      } else {
        oldArray.push(externalId);
      }
      this.selectedEmployees[this.selectionIndex] = oldArray;
    },
    doSelectAllEmployees() {
      this.selectedEmployees[this.selectionIndex] = this.employees.map(
        (employee) => employee.externalId
      );
    },
    unSelectAllEmployees() {
      this.selectedEmployees[this.selectionIndex] = [];
    },
    doSelectAllRoles() {
      this.selectedRoles[this.selectionIndex] = this.roles.map(
        (role) => role.id
      );
    },
    unSelectAllRoles() {
      this.selectedRoles[this.selectionIndex] = [];
    },
    doSelectAllSeniorities() {
      this.selectedSeniorities[this.selectionIndex] = this.seniorities.map(
        (sen) => sen.id
      );
    },
    unSelectAllSeniorities() {
      this.selectedSeniorities[this.selectionIndex] = [];
    },
    findEmployee(externalId) {
      return this.workers.find((w) => w.externalId === externalId);
    },
    filterWorklogsByEmployee(employeeId) {
      return this.worklogs.filter((worklog) => worklog.authorId === employeeId);
    },
    async setupSelectedRows() {
      const employeePromise = new Promise((resolve) => {
        this.selectedEmployees[0] = this.employeeData
          ? this.employeeData.map((employee) => employee.externalId)
          : [];
        resolve();
      });

      const rolePromise = new Promise((resolve) => {
        this.selectedRoles[0] = this.roles.map((role) => role.id);
        resolve();
      });

      const seniorityPromise = new Promise((resolve) => {
        this.selectedSeniorities[0] = this.seniorities.map((sen) => sen.id);
        resolve();
      });

      const detailsPromise = Promise.all(
        Object.keys(this.tigEmployeeDetails).map(async (key) => {
          let index = parseInt(/[^-]*$/.exec(key)[0]);
          this.selectedEmployees[index] = this.tigEmployeeDetails[key]
            ? this.tigEmployeeDetails[key].map(
                (employee) => employee.externalId
              )
            : [];
          this.selectedRoles[index] = this.roles.map((role) => role.id);
          this.selectedSeniorities[index] = this.seniorities.map(
            (sen) => sen.id
          );
        })
      );

      await Promise.all([
        employeePromise,
        rolePromise,
        seniorityPromise,
        detailsPromise,
      ]);
    },
    setupChartRestrictions() {
      const dataLength = this.months.length;

      const timeByEmployees = this.sumDataByIndex(
        this.monthlyBarChartDataByEmployees,
        dataLength
      );
      const moneyByEmployees = this.sumDataByIndex(
        this.monthlyBarChartDataByEmployeesMoney,
        dataLength
      );
      const timeByRoles = this.sumDataByIndex(
        this.monthlyBarChartDataByRoles,
        dataLength
      );
      const moneyByRoles = this.sumDataByIndex(
        this.monthlyBarChartDataByRolesMoney,
        dataLength
      );
      const timeBySeniorities = this.sumDataByIndex(
        this.monthlyBarChartDataBySeniorities,
        dataLength
      );
      const moneyBySeniorities = this.sumDataByIndex(
        this.monthlyBarChartDataBySenioritiesMoney,
        dataLength
      );

      this.chartRestriction = {
        seniority: {
          time: { min: 0, max: Math.max(...timeBySeniorities) },
          money: { min: 0, max: Math.max(...moneyBySeniorities) },
        },
        role: {
          time: { min: 0, max: Math.max(...timeByRoles) },
          money: { min: 0, max: Math.max(...moneyByRoles) },
        },
        employee: {
          time: { min: 0, max: Math.max(...timeByEmployees) },
          money: { min: 0, max: Math.max(...moneyByEmployees) },
        },
      };
    },

    sumDataByIndex(dataArray, length) {
      return dataArray.reduce(
        (acc, item) => acc.map((sum, i) => sum + item.data[i]),
        new Array(length).fill(0)
      );
    },
    calculateMonthlyTotals(employee, worklogs) {
      let monthlyLogged = [];
      let monthlySpent = [];
      let currentYear = dayjs(this.startDate).year();
      let currentMonth = dayjs(this.startDate).month();

      for (let i = 0; i < this.monthNumber; i++) {
        const monthlyIssues = worklogs.filter((issue) =>
          dayjs(issue.day).isSame(
            dayjs(`${currentYear}-${currentMonth + 1}-01`),
            "month"
          )
        );

        monthlyLogged.push(this.calculateTotalLogged(monthlyIssues));
        monthlySpent.push(
          this.calculateTotalSpent(monthlyIssues, employee.avgRate)
        );

        ({ currentYear, currentMonth } = this.incrementMonth(
          currentYear,
          currentMonth
        ));
      }

      return { logged: monthlyLogged, spent: monthlySpent };
    },
    calculateSegmentTotals(employee, worklogs) {
      if (!employee.segments) return [];

      return employee.segments.map((segment) => {
        const monthlyLogged = Array(this.monthNumber).fill(0);
        const monthlySpent = Array(this.monthNumber).fill(0);
        let currentYear = dayjs(this.startDate).year();
        let currentMonth = dayjs(this.startDate).month();

        for (let i = 0; i < this.monthNumber; i++) {
          const segmentIssues = worklogs.filter(
            (issue) =>
              issue.tigId === segment.tigId &&
              dayjs(issue.day).isSame(
                dayjs(`${currentYear}-${currentMonth + 1}-01`),
                "month"
              )
          );

          monthlyLogged[i] = this.calculateTotalLogged(segmentIssues);
          monthlySpent[i] = this.calculateTotalSpent(
            segmentIssues,
            employee.avgRate
          );

          ({ currentYear, currentMonth } = this.incrementMonth(
            currentYear,
            currentMonth
          ));
        }

        return { ...segment, monthlyLogged, monthlySpent };
      });
    },
    calculateTotalLogged(issues) {
      return issues.reduce((total, issue) => total + issue.seconds, 0);
    },
    calculateTotalSpent(issues, rate) {
      return issues.reduce(
        (total, issue) => total + (issue.seconds / 3600) * rate,
        0
      );
    },
    incrementMonth(year, month) {
      month++;
      if (month > 11) {
        month = 0;
        year++;
      }
      return { currentYear: year, currentMonth: month };
    },
    createMonthlyBarChartOptions(
      type,
      colorArray,
      yMin,
      yMax,
      formatterFunction
    ) {
      const self = this;
      return {
        chart: {
          type: "bar",
          height: 350,
          stacked: true,
        },
        dataLabels: {
          enabled: false,
        },
        plotOptions: {
          bar: {
            borderRadius: 8,
            borderRadiusApplication: "end",
            borderRadiusWhenStacked: "last",
            dataLabels: {
              dataLabels: {
                total: {
                  enabled: true,
                  offsetX: 0,
                  style: {
                    fontSize: "13px",
                    fontWeight: 900,
                  },
                },
              },
            },
          },
        },
        stroke: {
          width: 0,
          show: false,
        },
        xaxis: {
          categories: this.months,
          labels: {
            style: {
              colors: "var(--text)",
            },
          },
        },
        yaxis: {
          title: {
            text: type === "money" ? "Amount" : "Hours",
          },
          min: yMin,
          max: yMax,
          labels: {
            formatter: function (val) {
              return formatterFunction.call(self, val);
            },
            style: {
              colors: "var(--text)",
            },
          },
        },
        tooltip: {
          y: {
            formatter: function (val) {
              return formatterFunction.call(self, val);
            },
          },
        },
        fill: {
          opacity: 1,
        },
        legend: {
          show: false,
        },
        colors: colorArray,
      };
    },
    createRadialOptions(filteredData) {
      return {
        chart: {
          height: 300,
          type: "donut",
        },
        stroke: {
          show: false,
          width: 0,
        },
        plotOptions: {
          pie: {
            donut: {
              size: "50%",
            },
            expandOnClick: false,
          },
        },
        legend: {
          show: false,
        },
        colors: filteredData.map((item) => item.color),
        labels: filteredData.map((item) => item.name),
        tooltip: {
          y: {
            formatter: function (val) {
              return moneyify(val);
            },
          },
        },
      };
    },
    collectEmployeeData({
      employees,
      workers,
      indicators,
      keyField,
      nameField,
    }) {
      const collectedData = [];
      let colorIndex = 44;

      employees.forEach((employee) => {
        const worker = workers.find(
          (w) => w.externalId === employee.externalId
        );
        if (!worker?.[keyField]) return;

        const indicator = indicators.find((i) => i.id === worker[keyField]);
        if (!indicator) return;

        let existingEntry = collectedData.find(
          (data) => data.id === indicator.id
        );

        if (existingEntry) {
          existingEntry.timespent += employee.timespent;
          existingEntry.moneyspent += employee.moneyspent;
          existingEntry.employees.push(employee.externalId);
        } else {
          collectedData.push({
            id: indicator.id,
            name: indicator[nameField],
            timespent: employee.timespent,
            moneyspent: employee.moneyspent,
            color: `hsl(${colorIndex++ * 137.508}, 75%, ${
              Math.random() * 40 + 31
            }%)`,
            employees: [employee.externalId],
          });
        }
      });

      return collectedData.sort((a, b) =>
        a.id > b.id ? 1 : b.id > a.id ? -1 : 0
      );
    },
    collectFilteredEmployeeData({ keyField, indicators }) {
      const { monthlyEmployeeData, workers, monthNumber } = this;
      const filteredData = [];

      monthlyEmployeeData.forEach((employee) => {
        const worker = workers.find(
          (w) => w.externalId === employee.externalId
        );
        if (!worker) return;

        const indicator = indicators.find((i) => i.id === worker[keyField]);
        if (!indicator) return;

        let existingEntry = filteredData.find(
          (data) => data.id === indicator.id
        );

        if (existingEntry) {
          this.updateExistingFilteredEntry(
            existingEntry,
            employee,
            monthNumber
          );
        } else {
          filteredData.push(this.createNewFilteredEntry(indicator, employee));
        }
      });

      return filteredData.sort((a, b) =>
        a.id > b.id ? 1 : b.id > a.id ? -1 : 0
      );
    },

    updateExistingFilteredEntry(existingEntry, employee, monthNumber) {
      existingEntry.filteredEmployees.push(employee.externalId);
      for (let i = 0; i < monthNumber; i++) {
        existingEntry.monthlyLogged[i] += employee.monthlyLogged[i];
        existingEntry.monthlySpent[i] += employee.monthlySpent[i];
      }
      existingEntry.filteredTimespent = existingEntry.monthlyLogged.reduce(
        (sum, loggedTime) => sum + loggedTime,
        0
      );
      existingEntry.filteredMoneyspent = existingEntry.monthlySpent.reduce(
        (sum, spentMoney) => sum + spentMoney,
        0
      );
    },

    createNewFilteredEntry(indicator, employee) {
      return {
        ...indicator,
        filteredEmployees: [employee.externalId],
        monthlyLogged: [...employee.monthlyLogged],
        monthlySpent: [...employee.monthlySpent],
        filteredTimespent: [...employee.monthlyLogged].reduce(
          (sum, loggedTime) => sum + loggedTime,
          0
        ),
        filteredMoneyspent: [...employee.monthlySpent].reduce(
          (sum, spentMoney) => sum + spentMoney,
          0
        ),
      };
    },
  },
};
</script>

<style scoped lang="scss">
@import "~@/assets/scss/colors.scss";

.not-selected {
  opacity: 0.6;
}

.not-selectable {
  opacity: 0.6;
  cursor: not-allowed;

  * {
    cursor: not-allowed;
    pointer-events: none;
  }
}

.circle {
  width: 50px;
  height: 80px;
  margin: -4px;
  border-radius: 0 8px 8px 0;
  box-shadow: 0 5px 30px rgb(48 52 62 / 10%);
}

.checkboxes {
  .is-flex {
    label {
      margin-right: 0;
    }
  }
}

.bg-lightgray {
  font-weight: normal;
  font-size: large;
  background: $grey-lighter;
  //background: #F3F5F9;
}

.worker-table th,
.worker-table td:not(.has-dotted-bottom-border) {
  border: none;
  color: $custom-dark-blue;
}

.blank-row-small {
  height: 6px !important;
  line-height: 0;
}

.has-dotted-bottom-border {
  border-bottom: 1px dashed $grey-lighter;
}

.inactive-row > td:not(.checkbox-cell) {
  color: $custom-grayer-blue !important;
  opacity: 0.5;
}

.bg-light-blue {
  background: #d9e3fe;
  color: #2f2e6b !important;
}
</style>
