<template>
  <div class="bar-main-container">
    <div class="progress-bar-container">
      <div
        class="bar"
        :style="{ height: percentage + '%' }"
        :class="{
          'bar-full': value === maxValue,
          'bar-not-full': value < maxValue,
          'bar-exceeded-max': value > maxValue,
        }"
      ></div>
    </div>
    <div class="flex-row">
      <div
        class="progress-info"
        :class="{ 'info-exceeded-max': value > maxValue }"
      >
        <b-icon icon="triangle-exclamation" v-if="value > maxValue"></b-icon>
        <span>{{ infoComputed }}</span>
      </div>
      <span class="percentage">{{ realPercentage }}%</span>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "VerticalProgressBar",
  props: {
    value: {
      type: Number,
    },
    maxValue: {
      type: Number,
    },
    info: {
      type: Function,
    },
  },

  computed: {
    percentage() {
      return Math.min(Math.floor((this.value / this.maxValue) * 100), 100);
    },
    realPercentage() {
      return Math.floor((this.value / this.maxValue) * 100);
    },
    infoComputed() {
      return this.info(this.value, this.maxValue);
    },
  },
});
</script>

<style scoped lang="scss">
@import "~@/assets/scss/colors.scss";

.bar-main-container {
  height: 100%;
  display: flex;
  gap: 4px;
  color: var(--grey-dark);
}

.flex-row {
  display: flex;
  flex-direction: column;
}

.progress-info {
  flex-grow: 1;
  align-content: center;
  display: flex;
  align-items: center;
  gap: 4px;
}

.percentage {
  font-size: 12px;
}

.progress-bar-container {
  background-color: #d9d9d9;
  position: relative;
  transform: translateY(-50%);
  top: 50%;
  width: 10px;
  border-radius: 10px;
  height: 100%;
  float: left;
}

.bar {
  position: absolute;
  bottom: 0;
  width: 100%;
  box-sizing: border-box;
  border-radius: 10px;
  animation: grow 1.5s ease-out forwards;
  transform-origin: bottom;
}

.bar-full {
  background-color: var(--green);
}

.bar-not-full {
  background-color: $custom-green-light;
}

.bar-exceeded-max {
  background-color: var(--red);
}

.info-exceeded-max {
  color: var(--red);
  font-weight: 700;
}

@keyframes grow {
  from {
    transform: scaleY(0);
  }
}
</style>
